import * as React from "react";
import GenericTable from "../../components/Table/Table";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { User } from "../../api/api";

interface ManagementTableProps {
  userType: "admin" | "client" | "staff";
  rows: User[];
  onRowClick: (row: any) => void;
  onEditClick: (row: any) => void;
  onCalendarClick: (user: User) => void;
}

const ManagementTable: React.FC<ManagementTableProps> = ({
  userType,
  rows,
  onRowClick,
  onEditClick,
  onCalendarClick,
}) => {
  const columnsConfig = {
    client: [
      { id: "name", label: "Name", minWidth: 100 },
      { id: "user_email", label: "Client Email", minWidth: 170 },
      { id: "phone_number", label: "Phone Number", minWidth: 100 },
      { id: "actions", label: "Actions", minWidth: 100, align: "center" },
    ],
    staff: [
      { id: "name", label: "Name", minWidth: 100 },
      { id: "user_email", label: "Email", minWidth: 170 },
      { id: "phone_number", label: "Phone Number", minWidth: 100 },
      { id: "extras.profession", label: "Profession", minWidth: 100 },
      { id: "actions", label: "Actions", minWidth: 100, align: "center" },
    ],
    admin: [
      { id: "role", label: "Role", minWidth: 100 },
      { id: "name", label: "Name", minWidth: 100 },
      { id: "user_email", label: "Email", minWidth: 170 },
      { id: "phone_number", label: "Phone Number", minWidth: 100 },
      { id: "actions", label: "Actions", minWidth: 100, align: "center" },
    ],
  };

  const getActionsButton = (row: any) => (
    <>
      <IconButton color="primary" onClick={(e) => {
        e.stopPropagation();
        onEditClick(row);
      }}>
        <EditIcon />
      </IconButton>
      {userType === "staff" && (
        <IconButton color="secondary" onClick={(e) => {
          e.stopPropagation();
          onCalendarClick(row);
        }}>
          <CalendarMonthIcon />
        </IconButton>
      )}
    </>
  );

  return (
    <GenericTable
      columnsConfig={columnsConfig[userType]}
      tableType={userType}
      rows={rows}
      setRows={() => {}}
      getActionsButton={getActionsButton}
      getRows={async () => []}
      onRowClick={onRowClick}
    />
  );
};

export default ManagementTable; 