import * as React from "react";
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { 
  setUsers, 
  setFilteredUsers, 
  setSearchKeyword,
  setSelectedUser,
  setUserToEdit,
  setOpenCreateDialog,
  setOpenEditDialog,
  setCalendarOpen
} from '../store/slices/managementSlice';
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { User, UserCollections } from "../api/api";
import api from "../components/api";
import { AxiosResponse } from "axios";
import dayjs, { Dayjs } from "dayjs";

// 组件导入
import ManagementHeader from "../components/Management/ManagementHeader";
import ManagementSearch from "../components/Management/ManagementSearch";
import ManagementTable from "../components/Management/ManagementTable";
import ManagementCalendar from "../components/Management/ManagementCalendar";
import { DialogsContainer } from "../components/Management/DialogsContainer";

interface ManagementPageProps {
  userType: "admin" | "client" | "staff";
}

const DrawerWidth = 400;
const MainContent = styled("div", { shouldForwardProp: (prop) => prop !== "open" })<{ open?: boolean }>(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: open ? DrawerWidth : 0,
  })
);

const ManagementPage: React.FC<ManagementPageProps> = ({ userType }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const {
    users,
    filteredUsers,
    searchKeyword,
    selectedUser,
    userToEdit,
    openCreateDialog,
    openEditDialog,
    calendarOpen
  } = useAppSelector(state => state.management);

  // 保留必要的本地状态
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);
  const [highlightedDays, setHighlightedDays] = React.useState<Dayjs[]>([]);
  const [orderStaffs, setOrderStaffs] = React.useState<Map<string, any>>(new Map());

  // 数据获取
  const fetchUsers = async () => {
    const rows = await getRows();
    if (rows) {
      dispatch(setUsers(rows));
    }
  };

  React.useEffect(() => {
    fetchUsers();
  }, [userType]);

  // 基础处理函数
  const handleRowClick = (row: any) => {
    if (userType === "client") {
      navigate(`/order/${row.user_id}`, {
        state: {
          user_id: row.user_id,
          client_name: `${row.first_name} ${row.last_name}`,
        },
      });
    }
  };

  const getRows = async () => {
    if (userType === "client") {
      const response = await api.users.getUsersRoleUsersRoleTargetRoleGet("client");
      return concatName(response);
    } else if (userType === "staff") {
      const response = await api.users.getUsersRoleUsersRoleTargetRoleGet("staff");
      return concatName(response);
    } else if (userType === "admin") {
      const [adminResponse, managerResponse] = await Promise.all([
        api.users.getUsersRoleUsersRoleTargetRoleGet("manager"),
        api.users.getUsersRoleUsersRoleTargetRoleGet("admin"),
      ]);
      return [...concatName(managerResponse), ...concatName(adminResponse)];
    }
    return [];
  };

  const concatName = (response: AxiosResponse<UserCollections>) => {
    return response.data.contents.map((user) => ({
      ...user,
      name: `${user.first_name} ${user.last_name}`,
    }));
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <MainContent open={calendarOpen}>
        <ManagementHeader 
          userType={userType} 
          onCreateClick={() => dispatch(setOpenCreateDialog(true))} 
        />
        
        <ManagementSearch 
          users={users}
          searchKeyword={searchKeyword}
          onSearch={(value) => {
            dispatch(setSearchKeyword(value));
            dispatch(setFilteredUsers(
              value ? users.filter(user => 
                user.name.toLowerCase().includes(value.toLowerCase())
              ) : users
            ));
          }}
        />

        <ManagementTable 
          userType={userType}
          rows={filteredUsers}
          onRowClick={handleRowClick}
          onEditClick={(row) => {
            dispatch(setUserToEdit(row));
            dispatch(setOpenEditDialog(true));
          }}
          onCalendarClick={(user) => {
            dispatch(setSelectedUser(user));
            dispatch(setCalendarOpen(true));
          }}
        />

        <DialogsContainer 
          userType={userType}
          openCreateDialog={openCreateDialog}
          openEditDialog={openEditDialog}
          userToEdit={userToEdit}
          onCloseCreate={() => dispatch(setOpenCreateDialog(false))}
          onCloseEdit={() => {
            dispatch(setUserToEdit(null));
            dispatch(setOpenEditDialog(false));
          }}
          onSave={fetchUsers}
        />
      </MainContent>

      {selectedUser && (
        <ManagementCalendar 
          user={selectedUser}
          open={calendarOpen}
          selectedDate={selectedDate}
          highlightedDays={highlightedDays}
          orderStaffs={orderStaffs}
          onClose={() => {
            dispatch(setCalendarOpen(false));
            dispatch(setSelectedUser(null));
          }}
          onDateChange={setSelectedDate}
          onHighlightedDaysChange={setHighlightedDays}
          onOrderStaffsChange={setOrderStaffs}
        />
      )}
    </Box>
  );
};

export default ManagementPage;
