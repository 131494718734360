import * as React from "react";
import {
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Table,
  TableBody,
  TableHead,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import { OrderOrderItem } from "../../../api/api";
import { config } from "../../api";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "@mui/material";

interface CollapsibleTableItemProps {
  row: any;
  columns: any;
  detailColumns?: any;
  handleOpenAddOrderOrderItemDialog: (row: any) => void;
  getActionsButton: (row: any) => JSX.Element;
  handleOpenDeleteOrderOrderItemDialog: (
    row: any,
    orderOrderItem: OrderOrderItem,
  ) => void;
}

const CollapsibleTableItem: React.FC<CollapsibleTableItemProps> = ({
  row,
  columns,
  detailColumns,
  handleOpenAddOrderOrderItemDialog,
  handleOpenDeleteOrderOrderItemDialog,
  getActionsButton,
}) => {
  const [open, setOpen] = React.useState(false);

  const renderCellContent = (columnId: string, data: any) => {
    if (columnId === "actions") {
      return getActionsButton(data);
    }

    const getColumnValue = (obj: any, keys: string[]) => {
      return keys.reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : null),
        obj,
      );
    };

    if (columnId === "status") {
      // On récupère la valeur spécifique order.order_status.status
      return getColumnValue(data, ["order_status", "status"]);
    }

    if (columnId === "item_status") {
      return data.item_status || "Unknown";
    }

    if (columnId === "approval_date") {
      return data.approval_date || "Unknown";
    }

    // Pour toutes les autres colonnes, on utilise le columnId normal (ex: "order.property")
    const keys = columnId.split(".");
    const columnValue = getColumnValue(data, keys);

    return columnValue !== null && columnValue !== undefined
      ? columnValue.toString()
      : "";
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert("Link copied to clipboard");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      },
    );
  };

  const renderDetailCells = (orderOrderItem: OrderOrderItem) => {
    return detailColumns.map((column: any) => (
      <TableCell key={column.id} align={column.align || "left"}>
        {column.id === "actions" ? (
          <>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() =>
                handleOpenDeleteOrderOrderItemDialog(row, orderOrderItem)
              }
            >
              <DeleteIcon />
            </IconButton>
            {orderOrderItem.item_status === "pending" && (
              <Tooltip title="Copy approval link">
                <IconButton
                  aria-label="link"
                  size="small"
                  color="primary"
                  onClick={() =>
                    copyToClipboard(
                      `http://localhost:3000/requests?order_order_item_id=${orderOrderItem.order_order_item_id}`,
                    )
                  }
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : (
          renderCellContent(column.id, orderOrderItem)
        )}
      </TableCell>
    ));
  };

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1}>
        {columns.map((column: any) => (
          <TableCell key={column.id} align={column.align}>
            {column.id === "expand" ? (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            ) : (
              renderCellContent(column.id, row)
            )}
          </TableCell>
        ))}
      </TableRow>

      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columns.length}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" gutterBottom component="div">
                Order items
              </Typography>
              {(config.role === "admin" || config.role === "manager") && (
                <IconButton
                  aria-label="add"
                  size="large"
                  color="primary"
                  onClick={() => handleOpenAddOrderOrderItemDialog(row)}
                >
                  <AddCircleSharpIcon />
                </IconButton>
              )}
            </Box>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  {detailColumns.map((column: any) => (
                    <TableCell key={column.id} align={column.align || "left"}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {row.order_order_items?.map(
                  (orderOrderItem: OrderOrderItem, index: number) => (
                    <TableRow key={index}>
                      {renderDetailCells(orderOrderItem)}
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CollapsibleTableItem;
