import * as React from "react";
import BaseFormDialog from "./BaseFormDialog";
import FormField from "./FormField";
import { User, OrderStaffCreate } from "../../api/api";
import api from "../api";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

interface AddOrderStaffDialogProps {
  open: boolean;
  handleClose: () => void;
  addOrderStaff: (orderStaff: any) => void;
}

const AddOrderStaffDialog: React.FC<AddOrderStaffDialogProps> = ({
  open,
  handleClose,
  addOrderStaff,
}) => {
  const { order_id } = useParams<{ order_id: string }>();
  const [staffs, setStaffs] = React.useState<User[]>([]);
  const [selectedStaffs, setSelectedStaffs] = React.useState<string[]>([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [currentOrderStaffCreate, setCurrentOrderStaffCreate] = 
    React.useState<OrderStaffCreate | null>(null);

  React.useEffect(() => {
    const fetchStaffs = async () => {
      try {
        const response = await api.users.getUsersRoleUsersRoleTargetRoleGet("staff");
        setStaffs(response.data.contents);
      } catch (error) {
        console.error("Error fetching staffs:", error);
      }
    };
    fetchStaffs();
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!order_id) {
      console.error("Order ID is undefined.");
      return;
    }

    if (!selectedStaffs.length) {
      console.error("No staff selected.");
      return;
    }

    try {
      const orderResponse = await api.orders.getOrderOrdersOrderIdGet(order_id);
      
      for (const staffId of selectedStaffs) {
        const orderStaffCreate: OrderStaffCreate = {
          date: null,
          order_id: order_id,
          user_id: staffId,
        };

        try {
          const response = await api.orderStaffs.postOrderStaffOrderStaffsPost(
            orderStaffCreate
          );
          const staff = staffs.find(s => s.user_id === staffId);
          if (staff) {
            addOrderStaff({
              name: `${staff.first_name} ${staff.last_name}`,
              profession: staff.extras?.profession,
            });
          }
        } catch (error) {
          if (error instanceof AxiosError && error.response?.status === 403) {
            setCurrentOrderStaffCreate(orderStaffCreate);
            setConfirmDialogOpen(true);
            return;
          }
          console.error("Error adding staff:", error);
        }
      }

      setSelectedStaffs([]);
      handleClose();
    } catch (error) {
      console.error("Error adding staff:", error);
    }
  };

  const handleForceAdd = async () => {
    if (!currentOrderStaffCreate) return;

    try {
      const response = await api.orderStaffs.postOrderStaffOrderStaffsPost(
        currentOrderStaffCreate,
        true
      );
      const staff = staffs.find(s => s.user_id === currentOrderStaffCreate.user_id);
      if (staff) {
        addOrderStaff({
          name: `${staff.first_name} ${staff.last_name}`,
          profession: staff.extras?.profession,
        });
      }
      handleClose();
    } catch (error) {
      console.error("Error forcing staff addition:", error);
    }
    setConfirmDialogOpen(false);
  };

  return (
    <>
      <BaseFormDialog
        open={open}
        title="Add Order Staffs"
        description="Please select the staff members to add to this order"
        onClose={handleClose}
        onSubmit={handleSubmit}
        submitButtonText="Add"
      >
        <FormField
          type="select"
          name="staffs"
          label="Staff Members"
          value={selectedStaffs}
          onChange={setSelectedStaffs}
          options={staffs.map(staff => ({
            value: staff.user_id,
            label: `${staff.extras?.profession ?? 'Unknown'} - ${staff.first_name} ${staff.last_name}`
          }))}
          multiple
          required
        />
      </BaseFormDialog>

      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogTitle>Staff Unavailable</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Staff is unavailable. Do you want to force the assignment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>No</Button>
          <Button
            onClick={handleForceAdd}
            sx={{
              backgroundColor: "#2196f3",
              color: "white",
              "&:hover": { backgroundColor: "#64b5f6" },
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddOrderStaffDialog;
