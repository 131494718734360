import * as React from "react";
import BaseFormDialog from "./BaseFormDialog";
import FormField from "./FormField";
import { OrderItemCreate } from "../../api/api";
import api from "../api";

interface AddItemFormDialogProps {
  open: boolean;
  handleClose: () => void;
}

const AddItemFormDialog: React.FC<AddItemFormDialogProps> = ({
  open,
  handleClose,
}) => {
  const [formData, setFormData] = React.useState<OrderItemCreate>({
    description: "",
    price: 0,
  });

  const handleSubmit = async () => {
    await api.orderItems.postOrderItemOrderItemsPost(formData);
  };

  return (
    <BaseFormDialog
      open={open}
      title="Add Item"
      description="Please enter the necessary information to add an item"
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitButtonText="Add"
    >
      <FormField
        type="text"
        name="description"
        label="Description"
        value={formData.description}
        onChange={(value) => setFormData({ ...formData, description: value })}
        required
      />
      <FormField
        type="number"
        name="price"
        label="Price"
        value={formData.price}
        onChange={(value) => setFormData({ ...formData, price: Number(value) })}
        required
      />
    </BaseFormDialog>
  );
};

export default AddItemFormDialog;
