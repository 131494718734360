import * as React from "react";
import BaseFormDialog from "./BaseFormDialog";
import FormField from "./FormField";
import { QuestionCreate } from "../../api/api";
import api from "../api";

interface AddQuestionFormDialogProps {
  open: boolean;
  handleClose: () => void;
  addQuestion: (question: any) => void;
}

const AddQuestionFormDialog: React.FC<AddQuestionFormDialogProps> = ({
  open,
  handleClose,
  addQuestion,
}) => {
  const [types, setTypes] = React.useState<string[]>([]);
  const [questionCreate, setQuestionCreate] = React.useState<QuestionCreate>({
    text: "",
    type: "",
    rank: 0,
  });

  React.useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await api.questions.getQuestionTypesQuestionsTypesGet();
        setTypes(response.data);
      } catch (error) {
        console.error("Error fetching question types:", error);
      }
    };
    fetchTypes();
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await api.questions.postQuestionQuestionsPost(questionCreate);
      addQuestion(response.data);
      handleClose();
    } catch (error) {
      console.error("Error submitting question:", error);
    }
  };

  return (
    <BaseFormDialog
      open={open}
      title="Add Question"
      description="Please enter the necessary information to add a question"
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitButtonText="Add"
    >
      <FormField
        type="text"
        name="text"
        label="Text"
        value={questionCreate.text}
        onChange={(value) => setQuestionCreate(prev => ({ ...prev, text: value }))}
        required
      />

      <FormField
        type="autocomplete"
        name="type"
        label="Type"
        value={questionCreate.type}
        onChange={(value) => setQuestionCreate(prev => ({ ...prev, type: value }))}
        options={types.map(type => ({ value: type, label: type }))}
        required
      />

      <FormField
        type="number"
        name="rank"
        label="Rank"
        value={questionCreate.rank}
        onChange={(value) => setQuestionCreate(prev => ({ ...prev, rank: Number(value) }))}
        required
      />
    </BaseFormDialog>
  );
};

export default AddQuestionFormDialog;
