import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Grid,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import api, { config } from "../components/api";
import { OrderStaff, OrderOrderItem, User } from "../api";
import AddOrderStaffDialog from "../components/FormDialog/AddOrderStaffDialog";
import DeleteFormDialog from "../components/FormDialog/DeleteFormDialog";
import AddOrderOrderItemFormDialog from "../components/FormDialog/AddOrderOrderItemFormDialog";
import EditFormDialog from "../components/FormDialog/EditFormDialog";
import EditOrderFormDialog from "../components/FormDialog/EditOrderFormDialog";
import GenericTable from "../components/Table/Table";
import Stepper from "../components/Stepper/Stepper";
import dayjs, { Dayjs } from "dayjs";

const OrderInfo: React.FC = () => {
  const { order_id } = useParams<{ order_id?: string }>();
  const [orderStaffs, setOrderStaffs] = useState<any[]>([]);
  const [orderItems, setOrderItems] = useState<any[]>([]);
  const [orderDetails, setOrderDetails] = useState<any>(null);
  const [customerDetails, setCustomerDetails] = useState<any[]>([]);
  const [currentUserId, setCurrentUserId] = useState<string | null>(null);
  const [openDeleteOrderStaffDialog, setOpenDeleteOrderStaffDialog] =
    useState(false);
  const [openAddOrderStaffDialog, setOpenAddOrderStaffDialog] = useState(false);
  const [openAddOrderOrderItemDialog, setOpenAddOrderOrderItemDialog] =
    useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openEditOrderDialog, setOpenEditOrderDialog] = useState(false);
  const [orderStaffToDelete, setOrderStaffToDelete] =
    useState<OrderStaff | null>(null);
  const [orderToAddOrderItem, setOrderToAddOrderItem] = useState<any>(null);
  const [orderOrderItemToDelete, setOrderOrderItemToDelete] =
    useState<any>(null);
  const [openDeleteOrderOrderItemDialog, setOpenDeleteOrderOrderItemDialog] =
    useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState<Dayjs[]>([]);

  const staffColumns = [
    { id: "name", label: "Name", minWidth: 100 },
    { id: "profession", label: "Profession", minWidth: 100 },
    { id: "actions", label: "Actions", minWidth: 100, align: "center" },
  ];

  const orderItemColumns = [
    { id: "description", label: "Description", minWidth: 100 },
    { id: "price", label: "Price", minWidth: 100 },
    { id: "actions", label: "Actions", minWidth: 100, align: "center" },
  ];

  const fetchOrderStaffs = async () => {
    if (order_id) {
      try {
        const response =
          await api.orderStaffs.getOrdersOrderIdOrderStaffsOrdersOrderIdOrderStaffsGet(
            order_id,
          );
        const staffs = response.data.contents.map((order_staff: any) => ({
          order_staff_id: order_staff.order_staff_id,
          name: `${order_staff.user.first_name} ${order_staff.user.last_name}`,
          profession: order_staff.user.profession,
        }));
        setOrderStaffs(staffs);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (order_id) {
      const fetchOrderItems = async () => {
        try {
          const response =
            await api.orderOrderItems.getOrdersOrderIdOrderOrderItemsOrdersOrderIdOrderOrderItemsGet(
              order_id,
            );
          const items = response.data.contents.map((item: any) => ({
            order_order_item_id: item.order_order_item_id,
            description: item.order_item.description,
            price: item.order_item.price,
          }));
          setOrderItems(items);
        } catch (err) {
          console.error(err);
        }
      };

      const fetchOrderDetails = async () => {
        try {
          const response = await api.orders.getOrderOrdersOrderIdGet(order_id);
          const order = response.data;
          setOrderDetails(order);

          const userPromises = order.user_ids.map((userId: string) =>
            api.users.getUserUserIdUserUserIdGet(userId),
          );

          const userResponses = await Promise.all(userPromises);
          const users = userResponses.map((userResponse) => userResponse.data);
          setCustomerDetails(users);
        } catch (err) {
          console.error(err);
        }
      };

      fetchOrderStaffs();
      fetchOrderItems();
      fetchOrderDetails();
    }
  }, [order_id]);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/order/`);
  };

  const handleOpenDeleteOrderStaffDialog = (orderStaff: any) => {
    setOrderStaffToDelete(orderStaff);
    setOpenDeleteOrderStaffDialog(true);
  };

  const handleCloseDeleteOrderStaffDialog = () => {
    setOrderStaffToDelete(null);
    setOpenDeleteOrderStaffDialog(false);
  };

  const handleOpenAddOrderStaffDialog = async () => {
    if (!order_id) {
      console.error("Order ID is undefined.");
      return;
    }
  
    try {
      const response = await api.orders.getOrderOrdersOrderIdGet(order_id);
      const orderDetails = response.data;
  
      if (!orderDetails.date) {
        alert("The order date is not confirmed yet, staff cannot be added.");
        return;
      }
  
      setOpenAddOrderStaffDialog(true);
    } catch (error) {
      console.error("Error fetching order details:", error);
      alert("Failed to fetch order details. Please try again later.");
    }
  };

  const handleCloseAddOrderStaffDialog = () =>
    setOpenAddOrderStaffDialog(false);

  const handleOpenAddOrderOrderItemDialog = () => {
    setOrderToAddOrderItem(order_id);
    setOpenAddOrderOrderItemDialog(true);
  };

  const handleCloseAddOrderOrderItemDialog = () =>
    setOpenAddOrderOrderItemDialog(false);

  const handleOpenEditDialog = (customer: any) => {
    setCurrentUserId(customer.user_id);
    setOpenEditDialog(true);
    setCustomerDetails((prevDetails) =>
      prevDetails.map((user) =>
        user.user_id === customer.user_id ? customer : user,
      ),
    );
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setCurrentUserId(null);
  };

  const handleSaveEditUser = async (editedUser: any) => {
    setCustomerDetails((prevDetails) =>
      prevDetails.map((user) =>
        user.user_id === editedUser.user_id ? editedUser : user,
      ),
    );
  };

  const handleSaveEditOrder = async (editedOrder: any) => {
    setOrderDetails(editedOrder);
  };

  const handleSaveEditStatus = async (editedOrderStatus: any) => {
    setOrderDetails((prevOrderDetails: any) => {
      if (!prevOrderDetails) return prevOrderDetails;

      return {
        ...prevOrderDetails,
        order_status: {
          ...prevOrderDetails.order_status,
          status: editedOrderStatus.status.status,
        },
      };
    });
  };

  const handleOpenDeleteOrderOrderItemDialog = (orderItem: any) => {
    setOrderOrderItemToDelete(orderItem);
    setOpenDeleteOrderOrderItemDialog(true);
  };

  const handleCloseDeleteOrderOrderItemDialog = () => {
    setOrderOrderItemToDelete(null);
    setOpenDeleteOrderOrderItemDialog(false);
  };

  const handleDeleteOrderStaff = (deletedOrderStaffId: string) => {
    setOrderStaffs((prevOrderStaffs) => {
      const updatedOrderStaffs = prevOrderStaffs.filter(
        (order_staff) => order_staff.order_staff_id !== deletedOrderStaffId,
      );
      return updatedOrderStaffs;
    });
    fetchOrderStaffs();
  };

  const handleDeleteOrderOrderItem = (deletedOrderItemId: string) => {
    setOrderItems((prevOrderItems) => {
      const updatedOrderItems = prevOrderItems.filter(
        (order_item) => order_item.order_order_item_id !== deletedOrderItemId,
      );
      return updatedOrderItems;
    });
    handleCloseDeleteOrderOrderItemDialog();
  };

  const handleAddOrderStaff = (newOrderStaff: any) => {
    setOrderStaffs((prevOrderStaffs) => [newOrderStaff, ...prevOrderStaffs]);
    fetchOrderStaffs();
  };

  const handleAddOrderOrderItem = (newOrder: any) => {
    const order_order_items = newOrder.order_order_items;
    const order_items = order_order_items.map((order_item: any) => ({
      ...order_item,
      description: order_item.order_item.description,
      price: order_item.order_item.price,
    }));
    setOrderItems((prevOrderItems) => [...prevOrderItems, ...order_items]);
  };

  const handleOpenEditOrderDialog = () => {
    if (orderDetails) setOpenEditOrderDialog(true);
  };

  const handleCloseEditOrderDialog = () => {
    setOpenEditOrderDialog(false);
  };

  const handleRefresh = () => {
    if (order_id) {
      const fetchOrderDetails = async () => {
        try {
          const response = await api.orders.getOrderOrdersOrderIdGet(order_id);
          const order = response.data;
          setOrderDetails(order);
        } catch (err) {
          console.error(err);
        }
      };
      fetchOrderDetails();
    }
  };

  const getActionsButton = (row: any) => (
    <IconButton
      aria-label="delete"
      size="large"
      color="primary"
      onClick={() => handleOpenDeleteOrderStaffDialog(row)}
    >
      <DeleteIcon />
    </IconButton>
  );

  const getOrderItemActionsButton = (row: any) => (
    <IconButton
      aria-label="delete"
      size="large"
      color="primary"
      onClick={() => handleOpenDeleteOrderOrderItemDialog(row)}
    >
      <DeleteIcon />
    </IconButton>
  );

  const formatDate = (dateString: string) => {
    if (!dateString) return "Invalid date";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Invalid date";
    return new Intl.DateTimeFormat("zh-CN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(date);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box display="flex" justifyContent="center" mb={2} alignItems="center">
          <IconButton onClick={handleClick}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" sx={{ textAlign: "center", flexGrow: 1 }}>
            Order Info
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card variant="outlined" sx={{ mb: 2, width: "100%" }}>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Typography variant="h6" sx={{ textAlign: "left" }}>
                    Order Details
                  </Typography>
                  <IconButton
                    aria-label="edit"
                    size="large"
                    color="primary"
                    onClick={handleOpenEditOrderDialog}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
                <Typography variant="body1">
                  <strong>Type:</strong> {orderDetails?.type}
                </Typography>

                <Typography variant="body1">
                  <strong>Status:</strong> {orderDetails?.order_status?.status}
                </Typography>

                <Typography variant="body1">
                  <strong>Date:</strong>{" "}
                  {orderDetails?.date
                    ? formatDate(orderDetails.date)
                    : "Invalid date"}
                </Typography>

                <Typography variant="body1">
                  <strong>Note:</strong> {orderDetails?.public_note}
                </Typography>

                <Typography variant="body1">
                  <strong>Private Note:</strong> {orderDetails?.private_note}
                </Typography>

                <Typography variant="body1">
                  <strong>Hotel:</strong> {orderDetails?.extras?.hotel}
                </Typography>

                <Typography variant="body1">
                  <strong>Staff Count:</strong>{" "}
                  {orderDetails?.extras?.required_staff_count}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card variant="outlined" sx={{ mb: 2, width: "100%" }}>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Typography variant="h6">Customer Details</Typography>
                </Box>
                {customerDetails.map((customer, index) => (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                    key={index}
                  >
                    <Box>
                      <Typography variant="body1">
                        <strong>Name:</strong> {customer.first_name}{" "}
                        {customer.last_name}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Email:</strong> {customer.user_email}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Phone Number:</strong> {customer.phone_number}
                      </Typography>
                    </Box>
                    <IconButton
                      aria-label="edit"
                      size="large"
                      color="primary"
                      onClick={() => handleOpenEditDialog(customer)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card variant="outlined" sx={{ mb: 2, width: "100%" }}>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Typography variant="h6" sx={{ textAlign: "left" }}>
                    Staffs Assigned
                  </Typography>
                  <IconButton
                    aria-label="add"
                    size="large"
                    color="primary"
                    onClick={handleOpenAddOrderStaffDialog}
                  >
                    <AddBoxIcon />
                  </IconButton>
                </Box>
                <GenericTable
                  columnsConfig={staffColumns}
                  rows={orderStaffs}
                  tableType={"order_item"}
                  getRows={async () => orderStaffs}
                  setRows={setOrderStaffs}
                  getActionsButton={getActionsButton}
                  showPagination={false}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card variant="outlined" sx={{ mb: 2, width: "100%" }}>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Typography variant="h6" sx={{ textAlign: "left" }}>
                    Order Items
                  </Typography>
                  <IconButton
                    aria-label="add"
                    size="large"
                    color="primary"
                    onClick={handleOpenAddOrderOrderItemDialog}
                  >
                    <AddBoxIcon />
                  </IconButton>
                </Box>
                <GenericTable
                  columnsConfig={orderItemColumns}
                  rows={orderItems}
                  getRows={async () => orderItems}
                  tableType={"order_item"}
                  setRows={setOrderItems}
                  getActionsButton={getOrderItemActionsButton}
                  showPagination={false}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            {orderDetails && (
              <Stepper
                order={orderDetails}
                handleEditedStatus={handleSaveEditStatus}
                statusCategory={"order"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {orderDetails && (
              <Stepper
                order={orderDetails}
                handleEditedStatus={handleSaveEditStatus}
                statusCategory={"post_production"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {orderDetails && (
              <Stepper
                order={orderDetails}
                handleEditedStatus={handleSaveEditStatus}
                statusCategory={"logistics"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {orderDetails && (
              <Stepper
                order={orderDetails}
                handleEditedStatus={handleSaveEditStatus}
                statusCategory={"wedding_production"}
              />
            )}
          </Grid>
        </Grid>
      </Box>

      <AddOrderStaffDialog
        open={openAddOrderStaffDialog}
        handleClose={handleCloseAddOrderStaffDialog}
        addOrderStaff={handleAddOrderStaff}
      />
      <DeleteFormDialog
        open={openDeleteOrderStaffDialog}
        handleClose={handleCloseDeleteOrderStaffDialog}
        itemType="order_staff"
        itemId={orderStaffToDelete?.order_staff_id as string}
        onDelete={handleDeleteOrderStaff}
      />
      {orderOrderItemToDelete && (
        <DeleteFormDialog
          open={openDeleteOrderOrderItemDialog}
          handleClose={handleCloseDeleteOrderOrderItemDialog}
          itemType="order_order_item"
          itemId={orderOrderItemToDelete.order_order_item_id}
          onDelete={handleDeleteOrderOrderItem}
        />
      )}
      <AddOrderOrderItemFormDialog
        open={openAddOrderOrderItemDialog}
        handleClose={handleCloseAddOrderOrderItemDialog}
        order={{ ...orderDetails, order_order_items: [] }}
        addOrderOrderItems={handleAddOrderOrderItem}
      />
      {openEditDialog && (
        <EditFormDialog
          open={openEditDialog}
          handleClose={handleCloseEditDialog}
          user={customerDetails.find((user) => user.user_id === currentUserId)}
          onSave={handleSaveEditUser}
        />
      )}
      {orderDetails && (
        <EditOrderFormDialog
          open={openEditOrderDialog}
          handleClose={handleCloseEditOrderDialog}
          row={orderDetails}
          onSave={handleSaveEditOrder}
          onRefresh={handleRefresh}
          onDateChange={(newDate: Dayjs) => {
            setHighlightedDays((prev) => [...prev, newDate]);
          }}
        />
      )}
    </>
  );
};

export default OrderInfo;
