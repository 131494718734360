import axios from "axios";
import { jwtDecode } from "jwt-decode";

import {
  AnswersApiFactory,
  Configuration,
  OrderItemsApiFactory,
  OrderOrderItemsApiFactory,
  OrdersApiFactory,
  QuestionsApiFactory,
  RootApiFactory,
  UsersApiFactory,
  FilesApiFactory,
  OrderStaffsApiFactory,
  OrderStatusesApiFactory,
  NotificationApiFactory,
  StatusesApiFactory,
  RequestsApiFactory,
} from "../api";

export const config = new Configuration({});
const DEFAULT_API_BASE_PATH = 'http://localhost:8000';


interface DecodedJwt {
  role: string;
  user_id: string;
  exp: number;
}

if (typeof window !== "undefined") {
  const access_token = window.localStorage.getItem("access_token");
  if (access_token) {
    const decoded_jwt = jwtDecode<DecodedJwt>(access_token);
    config.accessToken = access_token;
    config.role = decoded_jwt.role;
  }
  config.basePath =
    window.localStorage.getItem("base_path") || 
    process.env.REACT_APP_API_BASE_PATH ||
    DEFAULT_API_BASE_PATH;
}

export function setApiBasePath(s: string) {
  window.localStorage.base_path = s;
  config.basePath = s;
}

const api = {
  root: RootApiFactory(config),
  users: UsersApiFactory(config),
  orders: OrdersApiFactory(config),
  orderItems: OrderItemsApiFactory(config),
  orderOrderItems: OrderOrderItemsApiFactory(config),
  questions: QuestionsApiFactory(config),
  answers: AnswersApiFactory(config),
  files: FilesApiFactory(config),
  orderStaffs: OrderStaffsApiFactory(config),
  orderStatus: OrderStatusesApiFactory(config),
  status: StatusesApiFactory(config),
  notificationRules: NotificationApiFactory(config),
  requests: RequestsApiFactory(config),
};

axios.interceptors.response.use(
  (c) => c,
  (e) => {
    if (
      window.location.pathname.startsWith("/order_info/") &&
      e.response.status === 403
    ) {
      return Promise.reject(e);
    } else if (
      e.response?.status === 401 &&
      window.location.pathname.startsWith("/change-password")
    ) {
      return Promise.reject(e);
    } else if (window.location.pathname !== "/login")
      (window as unknown as any).location = "/login";
  },
);

export default api;
