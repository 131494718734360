import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddQuestionFormDialog from "../components/FormDialog/AddQuestionFormDialog";
import DragTable from "../components/Table/DragTable";
import EditQuestionFormDialog from "../components/FormDialog/EditQuestionFormDialog";
import DeleteFormDialog from "../components/FormDialog/DeleteFormDialog";
import api from "../components/api";
import { Question } from "../api/api";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { AxiosResponse } from "axios";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const QuestionPage: React.FC = () => {
  const [types, setTypes] = React.useState<string[]>([]);
  const [questions, setQuestions] = React.useState<any[]>([]);
  const [selectedType, setSelectedType] = React.useState<string>("");
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [isReordering, setIsReordering] = React.useState(false);
  const [openEditQuestionDialog, setOpenEditQuestionDialog] =
    React.useState(false);
  const [openDeleteQuestionDialog, setOpenDeleteQuestionDialog] =
    React.useState(false);
  const [questionToEdit, setQuestionToEdit] = React.useState<any | null>(null);
  const [questionToDelete, setQuestionToDelete] = React.useState<any | null>(
    null,
  );

  const handleOpenEditQuestionDialog = (row: any) => {
    setQuestionToEdit(row);
    setOpenEditQuestionDialog(true);
  };

  const handleCloseEditQuestionDialog = () => {
    setQuestionToEdit(null);
    setOpenEditQuestionDialog(false);
  };

  const handleOpenDeleteQuestionDialog = (row: any) => {
    setQuestionToDelete(row);
    setOpenDeleteQuestionDialog(true);
  };

  const handleCloseDeleteQuestionDialog = () => {
    setQuestionToDelete(null);
    setOpenDeleteQuestionDialog(false);
  };

  const handleSaveEditQuestion = (updatedQuestion: Question) => {
    if (handleEditQuestion) handleEditQuestion(updatedQuestion);
    handleCloseEditQuestionDialog();
  };

  const handleDeleteQuestionClick = () => {
    if (questionToDelete && handleDeleteQuestion) {
      handleDeleteQuestion(questionToDelete.question_id);
      handleCloseDeleteQuestionDialog();
    }
  };

  const handleCreateQuestionClick = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
  };

  const handleAddQuestion = (newQuestion: any) => {
    setQuestions((prevQuestions) => {
      const { type: newType, rank: newRank } = newQuestion;

      let updatedQuestions = prevQuestions.map((question) => {
        if (question.type === newType && question.rank >= newRank) {
          return { ...question, rank: question.rank + 1 };
        }
        return question;
      });

      updatedQuestions = [newQuestion, ...updatedQuestions];

      updatedQuestions.sort((a, b) => {
        if (a.type === b.type) {
          return a.rank - b.rank;
        }
        return 0;
      });

      return updatedQuestions;
    });
  };

  const handleEditQuestion = (updatedQuestion: any) => {
    setQuestions((prevQuestions) => {
      const { question_id, type: newType, rank: newRank } = updatedQuestion;

      const originalQuestion = prevQuestions.find(
        (question) => question.question_id === question_id,
      );

      if (!originalQuestion) return prevQuestions;

      const { type: oldType, rank: oldRank } = originalQuestion;

      let updatedQuestions = prevQuestions.map((question) =>
        question.question_id === question_id ? updatedQuestion : question,
      );

      if (oldType === newType) {
        if (oldRank < newRank) {
          updatedQuestions = updatedQuestions.map((question) => {
            if (
              question.type === newType &&
              question.rank > oldRank &&
              question.rank <= newRank &&
              question.question_id !== question_id
            ) {
              return { ...question, rank: question.rank - 1 };
            }
            return question;
          });
        } else if (oldRank > newRank) {
          updatedQuestions = updatedQuestions.map((question) => {
            if (
              question.type === newType &&
              question.rank >= newRank &&
              question.rank < oldRank &&
              question.question_id !== question_id
            ) {
              return { ...question, rank: question.rank + 1 };
            }
            return question;
          });
        }
      } else {
        updatedQuestions = updatedQuestions.map((question) => {
          if (
            question.type === oldType &&
            question.rank > oldRank &&
            question.question_id !== question_id
          ) {
            return { ...question, rank: question.rank - 1 };
          }
          return question;
        });

        updatedQuestions = updatedQuestions.map((question) => {
          if (
            question.type === newType &&
            question.rank >= newRank &&
            question.question_id !== question_id
          ) {
            return { ...question, rank: question.rank + 1 };
          }
          return question;
        });
      }

      updatedQuestions.sort((a, b) => {
        if (a.type === b.type) {
          return a.rank - b.rank;
        }
        return 0;
      });

      return updatedQuestions;
    });
  };

  const handleDeleteQuestion = (deletedQuestionId: string) => {
    setQuestions((prevQuestions) => {
      const questionToDelete = prevQuestions.find(
        (question) => question.question_id === deletedQuestionId,
      );

      if (!questionToDelete) return prevQuestions;

      const { type: questionType, rank: questionRank } = questionToDelete;

      const updatedQuestions = prevQuestions.filter(
        (question) => question.question_id !== deletedQuestionId,
      );

      const adjustedQuestions = updatedQuestions.map((question) => {
        if (question.type === questionType && question.rank > questionRank) {
          return { ...question, rank: question.rank - 1 };
        }
        return question;
      });

      return adjustedQuestions;
    });
  };

  const handleSelectChange = async (event: SelectChangeEvent<string>) => {
    const selectedType = event.target.value;
    setSelectedType(selectedType);

    try {
      const response =
        await api.questions.getQuestionsQuestionsTypeTargetTypeGet(
          selectedType,
        );
      const sortedQuestions = response.data.contents.sort(
        (a, b) => a.rank - b.rank,
      );
      setQuestions(sortedQuestions);
    } catch (error) {
      console.error("Erreur lors de la récupération des questions :", error);
    }
  };

  const fetchTypes = async () => {
    try {
      const response: AxiosResponse<string[]> =
        await api.questions.getQuestionTypesQuestionsTypesGet();
      setTypes(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des types :", error);
    }
  };

  React.useEffect(() => {
    fetchTypes();
  }, []);

  const columnConfig = [
    { id: "text", label: "Text", minWidth: 100 },
    { id: "type", label: "Type", minWidth: 100 },
    { id: "rank", label: "Rank", minWidth: 100 },
    { id: "actions", label: "Actions", minWidth: 100, align: "center" },
  ];

  const getRows = async () => {
    try {
      const response = await api.questions.getQuestionsQuestionsGet();
      return response.data.contents;
    } catch (error) {
      console.error("Erreur lors de la récupération des questions :", error);
      return [];
    }
  };

  const handleReorderClick = () => {
    if (selectedType) {
      setIsReordering((prev) => !prev);
    }
  };

  const getActionsButton = (row: any) => {
    return (
      <>
        <IconButton
          color="primary"
          onClick={() => handleOpenEditQuestionDialog?.(row)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => handleOpenDeleteQuestionDialog?.(row)}
        >
          <DeleteIcon />
        </IconButton>
      </>
    );
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "1rem" }}
      >
        <Typography variant="h5">Questions</Typography>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReorderClick}
          >
            {isReordering ? "Save" : "Reorder"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateQuestionClick}
          >
            Add Question
          </Button>
        </Box>
      </Box>
      <FormControl fullWidth variant="standard" sx={{ mb: 3 }}>
        <InputLabel id="type-label">Type</InputLabel>
        <Select
          labelId="type-label"
          id="type-select"
          value={selectedType}
          onChange={handleSelectChange}
          label="Type"
        >
          {types.map((type, index) => (
            <MenuItem key={index} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <DragTable
        columnsConfig={columnConfig}
        rows={questions}
        getRows={getRows}
        setRows={setQuestions}
        handleEditQuestion={handleEditQuestion}
        isReordering={isReordering}
        getActionsButton={getActionsButton}
      />
      <AddQuestionFormDialog
        open={openCreateDialog}
        handleClose={handleCloseCreateDialog}
        addQuestion={handleAddQuestion}
      />
      {questionToEdit && (
        <EditQuestionFormDialog
          open={openEditQuestionDialog}
          handleClose={handleCloseEditQuestionDialog}
          row={questionToEdit}
          onSave={handleSaveEditQuestion}
        />
      )}
      {questionToDelete && (
        <DeleteFormDialog
          open={openDeleteQuestionDialog}
          handleClose={handleCloseDeleteQuestionDialog}
          itemType="question"
          itemId={questionToDelete.question_id}
          onDelete={handleDeleteQuestionClick}
        />
      )}
    </Box>
  );
};

export default QuestionPage;
