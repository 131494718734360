import * as React from "react";
import BaseFormDialog from "./BaseFormDialog";
import FormField from "./FormField";
import { User, UserPatch, UserProfession } from "../../api/api";
import api from "../api";
import Box from "@mui/material/Box";
import dayjs from "dayjs";

interface EditFormDialogProps {
  open: boolean;
  handleClose: () => void;
  user: User;
  onSave: (user: User) => void;
}

const EditFormDialog: React.FC<EditFormDialogProps> = ({
  open,
  handleClose,
  user,
  onSave,
}) => {
  const [userPatch, setUserPatch] = React.useState<UserPatch>({
    first_name: user.first_name,
    last_name: user.last_name,
    role: user.role,
    extras: {
      address: user.extras?.address,
      pregnancy_due_date: user.extras?.pregnancy_due_date,
      client_birthday: user.extras?.client_birthday,
      baby_birth_date: user.extras?.baby_birth_date,
      profession: user.extras?.profession,
      source: user.extras?.source || "",
      comment: user.extras?.comment || "",
    },
    user_email: user.user_email,
    phone_number: user.phone_number,
    wechat_account: user.wechat_account || "",
  });

  const handleSubmit = async () => {
    try {
      const response = await api.users.patchUsersUserIdUsersUserIdPatch(
        user.user_id,
        userPatch,
      );
      const data = {
        ...response.data,
        name: response.data.first_name + " " + response.data.last_name,
      };
      onSave(data);
      handleClose();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const sourceOptions = [
    { value: "xiaohongshu", label: "XiaoHongshu" },
    { value: "instagram", label: "Instagram" },
    { value: "wechat", label: "Wechat" },
    { value: "facebook", label: "Facebook" },
    { value: "referrals", label: "Referrals" },
    { value: "other", label: "Other" },
  ];

  const professionOptions = [
    { value: "Photographer", label: "Photographer" },
    { value: "Videographer", label: "Videographer" },
    { value: "Makeup", label: "Makeup" },
    { value: "Staff", label: "Staff" },
    { value: "Editor", label: "Editor" },
  ];

  const roleOptions = [
    { value: "Manager", label: "Manager" },
    { value: "Admin", label: "Admin" },
  ];

  return (
    <BaseFormDialog
      open={open}
      title={`Edit ${user.role === "client" ? "Client" : "User"}`}
      description="Please update the necessary information"
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitButtonText="Update"
    >
      <FormField
        type="text"
        name="first_name"
        label="First Name"
        value={userPatch.first_name}
        onChange={(value) => setUserPatch(prev => ({ ...prev, first_name: value }))}
        required
      />

      <FormField
        type="text"
        name="last_name"
        label="Last Name"
        value={userPatch.last_name}
        onChange={(value) => setUserPatch(prev => ({ ...prev, last_name: value }))}
        required
      />

      <FormField
        type="text"
        name="user_email"
        label="Email"
        value={userPatch.user_email}
        onChange={(value) => setUserPatch(prev => ({ ...prev, user_email: value }))}
        required
      />

      <FormField
        type="text"
        name="wechat_account"
        label="WeChat Account"
        value={userPatch.wechat_account}
        onChange={(value) => setUserPatch(prev => ({ ...prev, wechat_account: value }))}
        required
      />

      <FormField
        type="text"
        name="phone_number"
        label="Phone Number"
        value={userPatch.phone_number || ""}
        onChange={(value) => setUserPatch(prev => ({ ...prev, phone_number: value }))}
        required
      />

      {user.role === "client" && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <FormField
            type="text"
            name="address"
            label="Address"
            value={userPatch.extras?.address || ""}
            onChange={(value) => setUserPatch(prev => ({
              ...prev,
              extras: { ...prev.extras, address: value }
            }))}
          />

          <FormField
            type="date"
            name="pregnancy_due_date"
            label="Pregnancy due date"
            value={userPatch.extras?.pregnancy_due_date ? dayjs(userPatch.extras.pregnancy_due_date) : null}
            onChange={(value) => setUserPatch(prev => ({
              ...prev,
              extras: { ...prev.extras, pregnancy_due_date: value?.toISOString() || null }
            }))}
          />

          <FormField
            type="date"
            name="client_birthday"
            label="Client birthday"
            value={userPatch.extras?.client_birthday ? dayjs(userPatch.extras.client_birthday) : null}
            onChange={(value) => setUserPatch(prev => ({
              ...prev,
              extras: { ...prev.extras, client_birthday: value?.toISOString() || null }
            }))}
          />

          <FormField
            type="date"
            name="baby_birth_date"
            label="Baby Birthdate"
            value={userPatch.extras?.baby_birth_date ? dayjs(userPatch.extras.baby_birth_date) : null}
            onChange={(value) => setUserPatch(prev => ({
              ...prev,
              extras: { ...prev.extras, baby_birth_date: value?.toISOString() || null }
            }))}
          />

          <FormField
            type="select"
            name="source"
            label="Source"
            value={userPatch.extras?.source || ""}
            onChange={(value) => setUserPatch(prev => ({
              ...prev,
              extras: { ...prev.extras, source: value }
            }))}
            options={sourceOptions}
          />

          {(userPatch.extras?.source === "referrals" || userPatch.extras?.source === "other") && (
            <FormField
              type="text"
              name="comment"
              label="Comment"
              value={userPatch.extras?.comment || ""}
              onChange={(value) => setUserPatch(prev => ({
                ...prev,
                extras: { ...prev.extras, comment: value }
              }))}
            />
          )}
        </Box>
      )}

      {(user.role === "admin" || user.role === "manager") && (
        <FormField
          type="select"
          name="role"
          label="Role"
          value={userPatch.role || ""}
          onChange={(value) => setUserPatch(prev => ({ ...prev, role: value }))}
          options={roleOptions}
          required
        />
      )}

      {user.role === "staff" && (
        <FormField
          type="select"
          name="profession"
          label="Profession"
          value={userPatch.extras?.profession || ""}
          onChange={(value) => setUserPatch(prev => ({
            ...prev,
            extras: { ...prev.extras, profession: value as UserProfession }
          }))}
          options={professionOptions}
          required
        />
      )}
    </BaseFormDialog>
  );
};

export default EditFormDialog;
