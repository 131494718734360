import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { User } from "../../api/api";

interface ManagementSearchProps {
  users: User[];
  searchKeyword: string;
  onSearch: (value: string) => void;
}

const ManagementSearch: React.FC<ManagementSearchProps> = ({ users, searchKeyword, onSearch }) => {
  return (
    <Autocomplete
      options={users.map((user) => user.name)}
      renderInput={(params) => (
        <TextField {...params} label="Search by Name" />
      )}
      value={searchKeyword}
      onInputChange={(_, value) => onSearch(value)}
      sx={{ marginBottom: "1rem" }}
    />
  );
};

export default ManagementSearch; 