import * as React from "react";
import BaseFormDialog from "./BaseFormDialog";
import FormField from "./FormField";
import { OrderType, OrderCreate, User, Status, OrderOrderItemCreate } from "../../api/api";
import api from "../api";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { FormControl, FormGroup, FormControlLabel, Checkbox } from "@mui/material";

interface AddOrderFormDialogProps {
  open: boolean;
  handleClose: () => void;
  addOrder: (order: any) => void;
  onRefresh?: (newOrderDates?: dayjs.Dayjs[]) => void;
}

const AddOrderFormDialog: React.FC<AddOrderFormDialogProps> = ({
  open,
  handleClose,
  addOrder,
  onRefresh,
}) => {
  // 状态管理
  const [formData, setFormData] = React.useState<{
    mainOrder: OrderCreate;
    preWeddingOrder: OrderCreate;
    preWeddingShooting: boolean;
    hotel?: string; 
    staffCount?: number;
    preWeddingHotel?: string; 
    preWeddingStaffCount?: number;
  }>({
    mainOrder: {
      date: dayjs().toISOString(),
      user_ids: [],
      type: OrderType.Wedding,
      extras: {
        hotel: "",
        required_staff_count: null,
      },
    },
    preWeddingOrder: {
      date: null,
      user_ids: [],
      type: OrderType.PreWedding,
      extras: {
        hotel: "",
        required_staff_count: null,
      },
    },
    preWeddingShooting: false,
    hotel: "", 
    staffCount: undefined,
    preWeddingHotel: "", 
    preWeddingStaffCount: undefined,
  });

  const [users, setUsers] = React.useState<User[]>([]);
  const [statuses, setStatuses] = React.useState<Status[]>([]);
  const [selectedStatusId, setSelectedStatusId] = React.useState<string>("");
  const [selectedPreWeddingStatusId, setSelectedPreWeddingStatusId] = React.useState<string>("");
  const [orderItems, setOrderItems] = React.useState<Array<{ value: string; label: string }>>([]);
  const [selectedOrderItems, setSelectedOrderItems] = React.useState<string[]>([]);
  const [selectedPreWeddingItems, setSelectedPreWeddingItems] = React.useState<string[]>([]);
  const [selectedClients, setSelectedClients] = React.useState<string[]>([]);

  // 数据加载
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersResponse, statusesResponse, itemsResponse] = await Promise.all([
          api.users.getUsersRoleUsersRoleTargetRoleGet("client"),
          api.status.getStatusStatusCategoryStatusStatusCategoryTargetCategoryGet("order"),
          api.orderItems.getOrderItemsOrderItemsGet()
        ]);

        setUsers(usersResponse.data.contents);
        setStatuses(statusesResponse.data.contents);
        setOrderItems(itemsResponse.data.contents.map(item => ({
          value: item.order_item_id,
          label: item.description
        })));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // 表单提交处理
  const handleSubmit = async () => {
    const { mainOrder, preWeddingOrder, preWeddingShooting, hotel, staffCount } = formData;
    const newOrderDates: dayjs.Dayjs[] = [];

    try {
      mainOrder.extras = {
        hotel: formData.hotel || "",
        required_staff_count: formData.staffCount || null,
      };
      mainOrder.user_ids = [...mainOrder.user_ids, ...selectedClients];

      // 创建主订单
      const mainOrderResponse = await api.orders.postOrderOrdersPost(mainOrder);
      newOrderDates.push(dayjs(mainOrderResponse.data.date));

      // 创建主订单状态
      await api.orderStatus.postOrderStatusOrderStatusesPost({
        complete_date: new Date().toISOString(),
        order_id: mainOrderResponse.data.order_id,
        status_id: selectedStatusId,
        description: null,
      });

      for (const orderItemId of selectedOrderItems) {
        const orderOrderItemCreate: OrderOrderItemCreate = {
          order_id: mainOrderResponse.data.order_id,
          order_item_id: orderItemId,
        };
        await api.orderOrderItems.postOrderOrderItemOrderOrderItemsPost(orderOrderItemCreate);
      }

      const updatedMainOrderResponse = await api.orders.getOrderOrdersOrderIdGet(mainOrderResponse.data.order_id);

      // 如果需要，创建婚前拍摄订单
      if (preWeddingShooting) {
        preWeddingOrder.extras = {
          hotel: formData.preWeddingHotel || "",
          required_staff_count: formData.preWeddingStaffCount || null,
        };
        preWeddingOrder.user_ids = mainOrder.user_ids;

        const preWeddingResponse = await api.orders.postOrderOrdersPost(preWeddingOrder);
        newOrderDates.push(dayjs(preWeddingResponse.data.date));

        await api.orderStatus.postOrderStatusOrderStatusesPost({
          complete_date: new Date().toISOString(),
          order_id: preWeddingResponse.data.order_id,
          status_id: selectedPreWeddingStatusId,
          description: null,
        });

        for (const orderItemId of selectedPreWeddingItems) {
          const orderOrderItemCreate: OrderOrderItemCreate = {
            order_id: preWeddingResponse.data.order_id,
            order_item_id: orderItemId,
          };
          await api.orderOrderItems.postOrderOrderItemOrderOrderItemsPost(orderOrderItemCreate);
        }

        const updatedPreWeddingOrderResponse = await api.orders.getOrderOrdersOrderIdGet(preWeddingResponse.data.order_id);
        addOrder(updatedPreWeddingOrderResponse.data);
      }

      onRefresh?.(newOrderDates);
      addOrder(mainOrderResponse.data);
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  return (
    <BaseFormDialog
      open={open}
      title="Add Order"
      description="Please enter the necessary information to add an order"
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitButtonText="Add"
      maxWidth="md"
    >

      <FormField
        type="select"
        name="client"
        label="Client"
        value={selectedClients}
        onChange={setSelectedClients}
        options={users.map(user => ({
          value: user.user_id,
          label: `${user.first_name} ${user.last_name}`
        }))}
        required
        multiple
      />

      <FormField
        type="select"
        name="orderType"
        label="Order Type"
        value={formData.mainOrder.type}
        onChange={(value) => setFormData(prev => ({
          ...prev,
          mainOrder: { ...prev.mainOrder, type: value }
        }))}
        options={Object.values(OrderType).map(type => ({
          value: type,
          label: type
        }))}
        required
      />

      <FormField
        type="select"
        name="status"
        label="Order Status"
        value={selectedStatusId}
        onChange={setSelectedStatusId}
        options={statuses.map(status => ({
          value: status.status_id,
          label: status.status
        }))}
        required
      />

      <FormField
        type="select"
        name="orderItems"
        label="Order Items"
        value={selectedOrderItems}
        onChange={setSelectedOrderItems}
        options={orderItems}
        multiple
        required
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}> 
        <DatePicker
          name="orderDate"
          label="Order Date"
          value={dayjs(formData.mainOrder.date)}
          format="YYYY/MM/DD"
          slotProps={{ textField: { fullWidth: true } }}
          onChange={(date) => setFormData(prev => ({
            ...prev,
            mainOrder: { ...prev.mainOrder, date: date?.toISOString() || null }
          }))}
        /> 
      </LocalizationProvider>

      <FormField
        type="text"
        name="hotel"
        label="Hotel"
        value={formData.hotel || ""}
        onChange={(value) => setFormData(prev => ({
          ...prev,
          hotel: value
        }))}
      />

      <FormField
        type="number"
        name="required_staff_count"
        label="Staff Count"
        value={formData.staffCount || null}
        onChange={(value) => setFormData(prev => ({
          ...prev,
          staffCount: value
        }))}
      />

      {formData.mainOrder.type === OrderType.Wedding && (
        <Box mt={2}>
          <FormControl fullWidth>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.preWeddingShooting}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      preWeddingShooting: e.target.checked
                    }))}
                  />
                }
                label="Pre-Wedding Shooting"
              />
            </FormGroup>
          </FormControl>

          {formData.preWeddingShooting && (
            <>
              <FormField
                type="select"
                name="preWeddingStatus"
                label="Pre-Wedding Status"
                value={selectedPreWeddingStatusId}
                onChange={setSelectedPreWeddingStatusId}
                options={statuses.map(status => ({
                  value: status.status_id,
                  label: status.status
                }))}
                required
              />

              <FormField
                type="select"
                name="preWeddingItems"
                label="Pre-Wedding Items"
                value={selectedPreWeddingItems}
                onChange={setSelectedPreWeddingItems}
                options={orderItems}
                multiple
                required
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}> 
                <DatePicker
                  name="preWeddingDate"
                  label="Pre-Wedding Date"
                  value={dayjs(formData.preWeddingOrder.date)}
                  format="YYYY/MM/DD"
                  slotProps={{ textField: { fullWidth: true } }}
                  onChange={(date) => setFormData(prev => ({
                    ...prev,
                    preWeddingOrder: { ...prev.preWeddingOrder, date: date?.toISOString() || null }
                  }))}
                /> 
              </LocalizationProvider>

              <FormField
                type="text"
                name="preWeddingHotel"
                label="Pre-Wedding Hotel"
                value={formData.preWeddingHotel || ""}
                onChange={(value) => setFormData(prev => ({
                  ...prev,
                  preWeddingHotel: value
                }))}
              />

              <FormField
                type="number"
                name="preWeddingStaffCount"
                label="Pre-Wedding Staff Count"
                value={formData.preWeddingStaffCount || null}
                onChange={(value) => setFormData(prev => ({
                  ...prev,
                  preWeddingStaffCount: value
                }))}
              />
            </>
          )}
        </Box>
      )}
    </BaseFormDialog>
  );
};

export default AddOrderFormDialog;
