/* tslint:disable */
/* eslint-disable */
/**
 * MONO
 *      Wedding Planner API      This API documentation is fully compatible with OpenAPI specification.      For more information, please visit https://ottozhang.com
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from "./base";

/**
 *
 * @export
 * @interface Answer
 */
export interface Answer {
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  text: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Answer
   */
  attachments?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  order_id: string;
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  question_id: string;
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof Answer
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  answer_id: string;
  /**
   *
   * @type {object}
   * @memberof Answer
   */
  question: object;
}

/**
 *
 * @export
 * @interface AnswerCollection
 */
export interface AnswerCollection {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof AnswerCollection
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof AnswerCollection
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof AnswerCollection
   */
  page_token: string;
  /**
   *
   * @type {string}
   * @memberof AnswerCollection
   */
  next_page_token: string;
  /**
   *
   * @type {number}
   * @memberof AnswerCollection
   */
  page_size: number;
  /**
   *
   * @type {number}
   * @memberof AnswerCollection
   */
  total_size: number;
  /**
   *
   * @type {number}
   * @memberof AnswerCollection
   */
  total_pages: number;
  /**
   *
   * @type {Array<Answer>}
   * @memberof AnswerCollection
   */
  contents: Array<Answer>;
}

/**
 *
 * @export
 * @interface AnswerCreate
 */
export interface AnswerCreate {
  /**
   *
   * @type {string}
   * @memberof AnswerCreate
   */
  text: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AnswerCreate
   */
  attachments?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof AnswerCreate
   */
  order_id: string;
  /**
   *
   * @type {string}
   * @memberof AnswerCreate
   */
  question_id: string;
}
/**
 *
 * @export
 * @interface AnswerPatch
 */
export interface AnswerPatch {
  /**
   *
   * @type {string}
   * @memberof AnswerPatch
   */
  text?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof AnswerPatch
   */
  attachments?: Array<string> | null;
}
/**
 *
 * @export
 * @interface AnswerRequest
 */
export interface AnswerRequest {
  /**
   *
   * @type {Array<AnswerCreate>}
   * @memberof AnswerRequest
   */
  answers: Array<AnswerCreate>;
}
/**
 *
 * @export
 * @interface DeleteAnswerResponse
 */
export interface DeleteAnswerResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteAnswerResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DeleteOrderItemResponse
 */
export interface DeleteOrderItemResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteOrderItemResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DeleteOrderOrderItemResponse
 */
export interface DeleteOrderOrderItemResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteOrderOrderItemResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DeleteOrderStaffResponse
 */
export interface DeleteOrderStaffResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteOrderStaffResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DeleteOrderStatusResponse
 */
export interface DeleteOrderStatusResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteOrderStatusResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DeleteQuestionResponse
 */
export interface DeleteQuestionResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteQuestionResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface DeleteResponse
 */
export interface DeleteResponse {
  /**
   *
   * @type {boolean}
   * @memberof DeleteResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface FileUploadResponse
 */
export interface FileUploadResponse {
  /**
   *
   * @type {boolean}
   * @memberof FileUploadResponse
   */
  success: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof FileUploadResponse
   */
  file_urls: Array<string>;
}
/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const Kind = {
  User: "user",
  Collection: "collection",
  Question: "question",
  Answer: "answer",
  Order: "order",
  OrderItem: "order_item",
  OrderOrderItem: "order_order_item",
  OrderStaff: "order_staff",
  OrderStatus: "order_status",
  NotificationRule: "notification_rule",
  Status: "status",
  NoteRequest: "note_request",
} as const;

export type Kind = (typeof Kind)[keyof typeof Kind];

/**
 *
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
  /**
   *
   * @type {string}
   * @memberof LoginResponse
   */
  access_token: string;
  /**
   *
   * @type {string}
   * @memberof LoginResponse
   */
  token_type?: LoginResponseTokenTypeEnum;
}

export const LoginResponseTokenTypeEnum = {
  Bearer: "bearer",
} as const;

export type LoginResponseTokenTypeEnum =
  (typeof LoginResponseTokenTypeEnum)[keyof typeof LoginResponseTokenTypeEnum];

/**
 *
 * @export
 * @interface NoteRequest
 */
export interface NoteRequest {
  /**
   *
   * @type {string}
   * @memberof NoteRequest
   */
  inserted_at: string;
  /**
   *
   * @type {string}
   * @memberof NoteRequest
   */
  order_id: string;
  /**
   *
   * @type {string}
   * @memberof NoteRequest
   */
  previous_note: string;
  /**
   *
   * @type {string}
   * @memberof NoteRequest
   */
  note: string;
  /**
   *
   * @type {string}
   * @memberof NoteRequest
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof NoteRequest
   */
  created_by: string;
  /**
   *
   * @type {string}
   * @memberof NoteRequest
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof NoteRequest
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof NoteRequest
   */
  note_request_id: string;
}

/**
 *
 * @export
 * @interface NoteRequestCollection
 */
export interface NoteRequestCollection {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof NoteRequestCollection
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof NoteRequestCollection
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof NoteRequestCollection
   */
  page_token: string;
  /**
   *
   * @type {string}
   * @memberof NoteRequestCollection
   */
  next_page_token: string;
  /**
   *
   * @type {number}
   * @memberof NoteRequestCollection
   */
  page_size: number;
  /**
   *
   * @type {number}
   * @memberof NoteRequestCollection
   */
  total_size: number;
  /**
   *
   * @type {number}
   * @memberof NoteRequestCollection
   */
  total_pages: number;
  /**
   *
   * @type {Array<NoteRequest>}
   * @memberof NoteRequestCollection
   */
  contents: Array<NoteRequest>;
}

/**
 *
 * @export
 * @interface NoteRequestCreate
 */
export interface NoteRequestCreate {
  /**
   *
   * @type {string}
   * @memberof NoteRequest
   */
  order_id: string;
  /**
   *
   * @type {string}
   * @memberof NoteRequest
   */
  previous_note: string;
  /**
   *
   * @type {string}
   * @memberof NoteRequest
   */
  note?: string | null;
  /**
   *
   * @type {string}
   * @memberof NoteRequest
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof NoteRequest
   */
  created_by: string;
}

/**
 *
 * @export
 * @interface NotificationRule
 */
export interface NotificationRule {
  /**
   *
   * @type {UserRole}
   * @memberof NotificationRule
   */
  user_role: UserRole;
  /**
   *
   * @type {Array<string>}
   * @memberof NotificationRule
   */
  user_professions: Array<string>;
  /**
   *
   * @type {string}
   * @memberof NotificationRule
   */
  status_id: string;
  /**
   *
   * @type {number}
   * @memberof NotificationRule
   */
  inform_day: number;
  /**
   *
   * @type {string}
   * @memberof NotificationRule
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof NotificationRule
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof NotificationRule
   */
  notification_rule_id: string;
}

/**
 *
 * @export
 * @interface NotificationRuleCollection
 */
export interface NotificationRuleCollection {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof NotificationRuleCollection
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof NotificationRuleCollection
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof NotificationRuleCollection
   */
  page_token: string;
  /**
   *
   * @type {string}
   * @memberof NotificationRuleCollection
   */
  next_page_token: string;
  /**
   *
   * @type {number}
   * @memberof NotificationRuleCollection
   */
  page_size: number;
  /**
   *
   * @type {number}
   * @memberof NotificationRuleCollection
   */
  total_size: number;
  /**
   *
   * @type {number}
   * @memberof NotificationRuleCollection
   */
  total_pages: number;
  /**
   *
   * @type {Array<NotificationRule>}
   * @memberof NotificationRuleCollection
   */
  contents: Array<NotificationRule>;
}

/**
 *
 * @export
 * @interface NotificationRuleCreate
 */
export interface NotificationRuleCreate {
  /**
   *
   * @type {UserRole}
   * @memberof NotificationRuleCreate
   */
  user_role: UserRole;
  /**
   *
   * @type {Array<string>}
   * @memberof NotificationRuleCreate
   */
  user_professions: Array<string>;
  /**
   *
   * @type {string}
   * @memberof NotificationRuleCreate
   */
  status_id: string;
  /**
   *
   * @type {number}
   * @memberof NotificationRuleCreate
   */
  inform_day: number;
}

/**
 *
 * @export
 * @interface NotificationRulePatch
 */
export interface NotificationRulePatch {
  /**
   *
   * @type {UserRole}
   * @memberof NotificationRulePatch
   */
  user_role?: UserRole | null;
  /**
   *
   * @type {Array<string>}
   * @memberof NotificationRulePatch
   */
  user_professions?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof NotificationRulePatch
   */
  status_id?: string | null;
  /**
   *
   * @type {number}
   * @memberof NotificationRulePatch
   */
  inform_day?: number | null;
}

/**
 *
 * @export
 * @interface OrderExtras
 */
export interface OrderExtras {
  /**
   *
   * @type {string}
   * @memberof OrderExtras
   */
  hotel?: string | null;
  /**
   *
   * @type {number}
   * @memberof OrderExtras
   */
  required_staff_count?: number | null;
}

/**
 *
 * @export
 * @interface Order
 */
export interface Order {
  /**
   *
   * @type {string}
   * @memberof Order
   */
  date: string;
  /**
   *
   * @type {OrderType}
   * @memberof Order
   */
  type?: OrderType;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof Order
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  order_id: string;
  /**
   *
   * @type {object}
   * @memberof Order
   */
  user: object;
  /**
   *
   * @type {object}
   * @memberof Order
   */
  users: object;
  /**
   *
   * @type {object}
   * @memberof Order
   */
  order_status?: object | null;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  public_note?: string;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  private_note?: string;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  user_ids: string[];
  /**
   *
   * @type {OrderExtras}
   * @memberof Order
   */
  extras?: OrderExtras | null;
}

/**
 *
 * @export
 * @interface OrderCollection
 */
export interface OrderCollection {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof OrderCollection
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof OrderCollection
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof OrderCollection
   */
  page_token: string;
  /**
   *
   * @type {string}
   * @memberof OrderCollection
   */
  next_page_token: string;
  /**
   *
   * @type {number}
   * @memberof OrderCollection
   */
  page_size: number;
  /**
   *
   * @type {number}
   * @memberof OrderCollection
   */
  total_size: number;
  /**
   *
   * @type {number}
   * @memberof OrderCollection
   */
  total_pages: number;
  /**
   *
   * @type {Array<Order>}
   * @memberof OrderCollection
   */
  contents: Array<Order>;
}

/**
 *
 * @export
 * @interface OrderCreate
 */
export interface OrderCreate {
  /**
   *
   * @type {string}
   * @memberof OrderCreate
   */
  date: string | null;
  /**
   *
   * @type {OrderType}
   * @memberof OrderCreate
   */
  type?: OrderType;
  /**
   *
   * @type {string}
   * @memberof OrderCreate
   */
  user_ids: string[];
  /**
   *
   * @type {OrderExtras}
   * @memberof OrderCreate
   */
  extras?: OrderExtras | null;
}

/**
 *
 * @export
 * @interface OrderItem
 */
export interface OrderItem {
  /**
   *
   * @type {number}
   * @memberof OrderItem
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof OrderItem
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof OrderItem
   */
  order_item_id: string;
}

/**
 *
 * @export
 * @interface OrderItemCollection
 */
export interface OrderItemCollection {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof OrderItemCollection
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof OrderItemCollection
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof OrderItemCollection
   */
  page_token: string;
  /**
   *
   * @type {string}
   * @memberof OrderItemCollection
   */
  next_page_token: string;
  /**
   *
   * @type {number}
   * @memberof OrderItemCollection
   */
  page_size: number;
  /**
   *
   * @type {number}
   * @memberof OrderItemCollection
   */
  total_size: number;
  /**
   *
   * @type {number}
   * @memberof OrderItemCollection
   */
  total_pages: number;
  /**
   *
   * @type {Array<OrderItem>}
   * @memberof OrderItemCollection
   */
  contents: Array<OrderItem>;
}

/**
 *
 * @export
 * @interface OrderItemCreate
 */
export interface OrderItemCreate {
  /**
   *
   * @type {number}
   * @memberof OrderItemCreate
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof OrderItemCreate
   */
  description: string;
}
/**
 *
 * @export
 * @interface OrderItemPatch
 */
export interface OrderItemPatch {
  /**
   *
   * @type {number}
   * @memberof OrderItemPatch
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof OrderItemPatch
   */
  description?: string | null;
}
/**
 *
 * @export
 * @interface OrderOrderItem
 */
export interface OrderOrderItem {
  /**
   *
   * @type {string}
   * @memberof OrderOrderItem
   */
  order_id: string;
  /**
   *
   * @type {string}
   * @memberof OrderOrderItem
   */
  order_item_id: string;
  /**
   *
   * @type {string}
   * @memberof OrderOrderItem
   */
  item_status: string;
  /**
   *
   * @type {string}
   * @memberof OrderOrderItem
   */
  approval_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderOrderItem
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof OrderOrderItem
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof OrderOrderItem
   */
  order_order_item_id: string;
  /**
   *
   * @type {object}
   * @memberof OrderOrderItem
   */
  order_item: OrderItem;
}

/**
 *
 * @export
 * @interface OrderOrderItemCollection
 */
export interface OrderOrderItemCollection {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof OrderOrderItemCollection
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof OrderOrderItemCollection
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof OrderOrderItemCollection
   */
  page_token: string;
  /**
   *
   * @type {string}
   * @memberof OrderOrderItemCollection
   */
  next_page_token: string;
  /**
   *
   * @type {number}
   * @memberof OrderOrderItemCollection
   */
  page_size: number;
  /**
   *
   * @type {number}
   * @memberof OrderOrderItemCollection
   */
  total_size: number;
  /**
   *
   * @type {number}
   * @memberof OrderOrderItemCollection
   */
  total_pages: number;
  /**
   *
   * @type {Array<OrderOrderItem>}
   * @memberof OrderOrderItemCollection
   */
  contents: Array<OrderOrderItem>;
}

/**
 *
 * @export
 * @interface OrderOrderItemCreate
 */
export interface OrderOrderItemCreate {
  /**
   *
   * @type {string}
   * @memberof OrderOrderItemCreate
   */
  order_id: string;
  /**
   *
   * @type {string}
   * @memberof OrderOrderItemCreate
   */
  order_item_id: string;
}
/**
 *
 * @export
 * @interface OrderPatch
 */
export interface OrderPatch {
  /**
   *
   * @type {string}
   * @memberof OrderPatch
   */
  date?: string | null;
  /**
   *
   * @type {OrderType}
   * @memberof OrderPatch
   */
  type?: OrderType;
  /**
   *
   * @type {string}
   * @memberof OrderPatch
   */
  public_note?: string;
  /**
   *
   * @type {string}
   * @memberof OrderPatch
   */
  private_note?: string;
  /**
   *
   * @type {OrderExtras}
   * @memberof OrderPatch
   */
  extras?: OrderExtras | null;
}

/**
 *
 * @export
 * @interface OrderStaff
 */
export interface OrderStaff {
  /**
   *
   * @type {string}
   * @memberof OrderStaff
   */
  date?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderStaff
   */
  order_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderStaff
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof OrderStaff
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof OrderStaff
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof OrderStaff
   */
  order_staff_id: string;
  /**
   *
   * @type {object}
   * @memberof OrderStaff
   */
  order: object;
  /**
   *
   * @type {object}
   * @memberof OrderStaff
   */
  user: object;
}

/**
 *
 * @export
 * @interface OrderStaffCollection
 */
export interface OrderStaffCollection {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof OrderStaffCollection
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof OrderStaffCollection
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof OrderStaffCollection
   */
  page_token: string;
  /**
   *
   * @type {string}
   * @memberof OrderStaffCollection
   */
  next_page_token: string;
  /**
   *
   * @type {number}
   * @memberof OrderStaffCollection
   */
  page_size: number;
  /**
   *
   * @type {number}
   * @memberof OrderStaffCollection
   */
  total_size: number;
  /**
   *
   * @type {number}
   * @memberof OrderStaffCollection
   */
  total_pages: number;
  /**
   *
   * @type {Array<OrderStaff>}
   * @memberof OrderStaffCollection
   */
  contents: Array<OrderStaff>;
}

/**
 *
 * @export
 * @interface OrderStaffCreate
 */
export interface OrderStaffCreate {
  /**
   *
   * @type {string}
   * @memberof OrderStaffCreate
   */
  date?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderStaffCreate
   */
  order_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderStaffCreate
   */
  user_id: string;
}
/**
 *
 * @export
 * @interface OrderStaffPatch
 */
export interface OrderStaffPatch {
  /**
   *
   * @type {string}
   * @memberof OrderStaffPatch
   */
  date: string;
}
/**
 *
 * @export
 * @interface OrderStatus
 */
export interface OrderStatus {
  /**
   *
   * @type {string}
   * @memberof OrderStatus
   */
  complete_date: string;
  /**
   *
   * @type {string}
   * @memberof OrderStatus
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderStatus
   */
  order_id: string;
  /**
   *
   * @type {string}
   * @memberof OrderStatus
   */
  status_id: string;
  /**
   *
   * @type {string}
   * @memberof OrderStatus
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof OrderStatus
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof OrderStatus
   */
  order_status_id: string;
  /**
   *
   * @type {object}
   * @memberof OrderStatus
   */
  status: object;
}

/**
 *
 * @export
 * @interface OrderStatusCollection
 */
export interface OrderStatusCollection {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof OrderStatusCollection
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof OrderStatusCollection
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof OrderStatusCollection
   */
  page_token: string;
  /**
   *
   * @type {string}
   * @memberof OrderStatusCollection
   */
  next_page_token: string;
  /**
   *
   * @type {number}
   * @memberof OrderStatusCollection
   */
  page_size: number;
  /**
   *
   * @type {number}
   * @memberof OrderStatusCollection
   */
  total_size: number;
  /**
   *
   * @type {number}
   * @memberof OrderStatusCollection
   */
  total_pages: number;
  /**
   *
   * @type {Array<OrderStatus>}
   * @memberof OrderStatusCollection
   */
  contents: Array<OrderStatus>;
}

/**
 *
 * @export
 * @interface OrderStatusCreate
 */
export interface OrderStatusCreate {
  /**
   *
   * @type {string}
   * @memberof OrderStatusCreate
   */
  complete_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderStatusCreate
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrderStatusCreate
   */
  order_id: string;
  /**
   *
   * @type {string}
   * @memberof OrderStatusCreate
   */
  status_id: string;
}
/**
 *
 * @export
 * @interface OrderStatusPatch
 */
export interface OrderStatusPatch {
  /**
   *
   * @type {string}
   * @memberof OrderStatusPatch
   */
  complete_date: string;
  /**
   *
   * @type {string}
   * @memberof OrderStatusPatch
   */
  description?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const OrderType = {
  Baby: "baby",
  Maternity: "maternity",
  Family: "family",
  PreWedding: "pre_wedding",
  Wedding: "wedding",
  Travel: "travel",
  SelfPortrait: "self_portrait",
  Other: "other",
} as const;

export type OrderType = (typeof OrderType)[keyof typeof OrderType];

/**
 *
 * @export
 * @interface Question
 */
export interface Question {
  /**
   *
   * @type {string}
   * @memberof Question
   */
  text: string;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  rank: number;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof Question
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  question_id: string;
}

/**
 *
 * @export
 * @interface QuestionCollection
 */
export interface QuestionCollection {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof QuestionCollection
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof QuestionCollection
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof QuestionCollection
   */
  page_token: string;
  /**
   *
   * @type {string}
   * @memberof QuestionCollection
   */
  next_page_token: string;
  /**
   *
   * @type {number}
   * @memberof QuestionCollection
   */
  page_size: number;
  /**
   *
   * @type {number}
   * @memberof QuestionCollection
   */
  total_size: number;
  /**
   *
   * @type {number}
   * @memberof QuestionCollection
   */
  total_pages: number;
  /**
   *
   * @type {Array<Question>}
   * @memberof QuestionCollection
   */
  contents: Array<Question>;
}

/**
 *
 * @export
 * @interface QuestionCreate
 */
export interface QuestionCreate {
  /**
   *
   * @type {string}
   * @memberof QuestionCreate
   */
  text: string;
  /**
   *
   * @type {number}
   * @memberof QuestionCreate
   */
  rank?: number | null;
  /**
   *
   * @type {string}
   * @memberof QuestionCreate
   */
  type?: string | null;
}
/**
 *
 * @export
 * @interface QuestionCreateCollection
 */
export interface QuestionCreateCollection {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof QuestionCreateCollection
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof QuestionCreateCollection
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof QuestionCreateCollection
   */
  page_token: string;
  /**
   *
   * @type {string}
   * @memberof QuestionCreateCollection
   */
  next_page_token: string;
  /**
   *
   * @type {number}
   * @memberof QuestionCreateCollection
   */
  page_size: number;
  /**
   *
   * @type {number}
   * @memberof QuestionCreateCollection
   */
  total_size: number;
  /**
   *
   * @type {number}
   * @memberof QuestionCreateCollection
   */
  total_pages: number;
  /**
   *
   * @type {Array<QuestionCreate>}
   * @memberof QuestionCreateCollection
   */
  contents: Array<QuestionCreate>;
}

/**
 *
 * @export
 * @interface QuestionPatch
 */
export interface QuestionPatch {
  /**
   *
   * @type {string}
   * @memberof QuestionPatch
   */
  text?: string | null;
  /**
   *
   * @type {number}
   * @memberof QuestionPatch
   */
  rank?: number | null;
  /**
   *
   * @type {string}
   * @memberof QuestionPatch
   */
  type?: string | null;
}
/**
 *
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  old_password: string;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  new_password: string;
}
/**
 *
 * @export
 * @interface ResetPasswordResponse
 */
export interface ResetPasswordResponse {
  /**
   *
   * @type {boolean}
   * @memberof ResetPasswordResponse
   */
  success?: boolean;
}
/**
 *
 * @export
 * @interface Status
 */
export interface Status {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof Status
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof Status
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof Status
   */
  status_id: string;
  /**
   *
   * @type {number}
   * @memberof Status
   */
  status_rank: number;
  /**
   *
   * @type {string}
   * @memberof Status
   */
  status_category: string;
  /**
   *
   * @type {StatusField}
   * @memberof Status
   */
  status: StatusField;
  /**
   *
   * @type {number}
   * @memberof Status
   */
  deadline?: number | null;
  /**
   *
   * @type {Array<string>}
   * @memberof Status
   */
  receiver: Array<string>;
}

/**
 *
 * @export
 * @interface StatusCollection
 */
export interface StatusCollection {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof StatusCollection
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof StatusCollection
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof StatusCollection
   */
  page_token: string;
  /**
   *
   * @type {string}
   * @memberof StatusCollection
   */
  next_page_token: string;
  /**
   *
   * @type {number}
   * @memberof StatusCollection
   */
  page_size: number;
  /**
   *
   * @type {number}
   * @memberof StatusCollection
   */
  total_size: number;
  /**
   *
   * @type {number}
   * @memberof StatusCollection
   */
  total_pages: number;
  /**
   *
   * @type {Array<Status>}
   * @memberof StatusCollection
   */
  contents: Array<Status>;
}

/**
 *
 * @export
 * @enum {string}
 */

export const StatusField = {
  Inquiry: "inquiry",
  ContractSent: "contract_sent",
  ContractSigned: "contract_signed",
  DepositPaid: "deposit_paid",
  DepositPending: "deposit_pending",
  OrderConfirmed: "order_confirmed",
  Scheduled: "scheduled",
  Preparation: "preparation",
  Shooting: "shooting",
  ShootingFinished: "shooting_finished",
  PostProduction: "post_production",
  PostProductionFinished: "post_production_finished",
  Delivered: "delivered",
  OrderCompleted: "order_completed",
  RawPhotosSent: "raw_photos_sent",
  PhotoSelectionInProgress: "photo_selection_in_progress",
  PhotoSelectionReceivedClient: "photo_selection_received_client",
  StaffConfirmation: "staff_confirmation",
  PhotoSelectionConfirmed: "photo_selection_confirmed",
  PhotoSelectionSent: "photo_selection_sent",
  PhotoSelectionReceivedEditor: "photo_selection_received_editor",
  ColorGradingInProgress: "color_grading_in_progress",
  ColorGradingConfirmed: "color_grading_confirmed",
  AllEditsConfirmed: "all_edits_confirmed",
  SentToAlbumFactory: "sent_to_album_factory",
  AlbumLayoutReceived: "album_layout_received",
  AlbumLayoutConfirmed: "album_layout_confirmed",
  AlbumProductionInProgress: "album_production_in_progress",
  AlbumReadyForShipping: "album_ready_for_shipping",
  AlbumShipped: "album_shipped",
  LogisticsReceived: "logistics_received",
  PackagingInProgress: "packaging_in_progress",
  ShippedBackToFrance: "shipped_back_to_france",
  PackageArrived: "package_arrived",
  ClientNotified: "client_notified",
  ClientCollected: "client_collected",
  PosterSentForClientConfirmation: "poster_sent_for_client_confirmation",
  PosterConfirmationInProgress: "poster_confirmation_in_progress",
  PosterConfirmed: "poster_confirmed",
  PosterSentToPrinter: "poster_sent_to_printer",
  PosterPrintingCompleted: "poster_printing_completed",
  ClientNotifiedForPickup: "client_notified_for_pickup",
  MvSentForClientConfirmation: "mv_sent_for_client_confirmation",
  DigitalAlbumSent: "digital_album_sent",
  RawVideoCompleted: "raw_video_completed",
  VideoEditingInProgress: "video_editing_in_progress",
  PostProductionCompleted: "post_production_completed",
  FinalReviewInProgress: "final_review_in_progress",
  MediaSentToClient: "media_sent_to_client",
  PhotoPrintingInProgress: "photo_printing_in_progress",
  PhotoPrintingReceived: "photo_printing_received",
  PhotoPrintingSentToPrinter: "photo_printing_sent_to_printer",
  PrintedPhotosReceived: "printed_photos_received",
  PhotosReceivedAtStudio: "photos_received_at_studio",
  ClientCollectedPhotos: "client_collected_photos",
  OrderCompletedPhotos: "order_completed_photos",
} as const;

export type StatusField = (typeof StatusField)[keyof typeof StatusField];

/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  last_name?: string;
  /**
   *
   * @type {UserRole}
   * @memberof User
   */
  role?: UserRole;
  /**
   *
   * @type {UserExtras}
   * @memberof User
   */
  extras?: UserExtras | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  user_email: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  wechat_account: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof User
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof User
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  date_joined?: string | null;
}

/**
 *
 * @export
 * @interface UserCollections
 */
export interface UserCollections {
  [key: string]: any;

  /**
   *
   * @type {string}
   * @memberof UserCollections
   */
  self_link: string;
  /**
   *
   * @type {Kind}
   * @memberof UserCollections
   */
  kind: Kind;
  /**
   *
   * @type {string}
   * @memberof UserCollections
   */
  page_token: string;
  /**
   *
   * @type {string}
   * @memberof UserCollections
   */
  next_page_token: string;
  /**
   *
   * @type {number}
   * @memberof UserCollections
   */
  page_size: number;
  /**
   *
   * @type {number}
   * @memberof UserCollections
   */
  total_size: number;
  /**
   *
   * @type {number}
   * @memberof UserCollections
   */
  total_pages: number;
  /**
   *
   * @type {Array<User>}
   * @memberof UserCollections
   */
  contents: Array<User>;
}

/**
 *
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  last_name?: string;
  /**
   *
   * @type {UserRole}
   * @memberof UserCreate
   */
  role?: UserRole;
  /**
   *
   * @type {UserExtras}
   * @memberof UserCreate
   */
  extras?: UserExtras | null;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  user_email: string;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  wechat_account: string;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  password: string;
}

/**
 *
 * @export
 * @interface UserExtras
 */
export interface UserExtras {
  /**
   *
   * @type {string}
   * @memberof UserExtras
   */
  address?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserExtras
   */
  pregnancy_due_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserExtras
   */
  client_birthday?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserExtras
   */
  baby_birth_date?: string | null;
  /**
   *
   * @type {UserProfession}
   * @memberof UserExtras
   */
  profession?: UserProfession | null;
  /**
   *
   * @type {string}
   * @memberof UserExtras
   */
  source?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserExtras
   */
  comment?: string | null;
}

/**
 *
 * @export
 * @interface UserPatch
 */
export interface UserPatch {
  /**
   *
   * @type {string}
   * @memberof UserPatch
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserPatch
   */
  last_name?: string | null;
  /**
   *
   * @type {UserRole}
   * @memberof UserPatch
   */
  role?: UserRole | null;
  /**
   *
   * @type {UserExtras}
   * @memberof UserPatch
   */
  extras?: UserExtras | null;
  /**
   *
   * @type {string}
   * @memberof UserPatch
   */
  user_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserPatch
   */
  wechat_account?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserPatch
   */
  phone_number?: string | null;
}

/**
 *
 * @export
 * @enum {string}
 */

export const UserProfession = {
  Photographer: "photographer",
  Videographer: "videographer",
  MakeupArtist: "makeup_artist",
  PostProductionEditor: "post-production editor",
  WeddingMakeupArtist: "wedding_makeup_artist",
  WeddingPhotographerP1: "wedding_photographer",
  WeddingVideographerV1: "wedding_videographer",
  WeddingDayEditorM: "wedding_day_editor_m",
  WeddingDayFastPhotoDeveloperI: "Wedding_day_fast_photo_developer_i",
  PostColorist: "post-colorist",
  PostEditor: "post-editor",
  PhotoEditor: "photo_editor",
  VideEditor: "vide_editor",
  WeddingDayEditor: "wedding_day_editor",
  WeddingPhotoPrinter: "wedding_photo_printer",
} as const;

export type UserProfession =
  (typeof UserProfession)[keyof typeof UserProfession];

/**
 *
 * @export
 * @enum {string}
 */

export const UserRole = {
  Admin: "admin",
  Manager: "manager",
  Staff: "staff",
  Client: "client",
} as const;

export type UserRole = (typeof UserRole)[keyof typeof UserRole];

/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<ValidationErrorLocInner>}
   * @memberof ValidationError
   */
  loc: Array<ValidationErrorLocInner>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}
/**
 *
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {}

/**
 * AnswersApi - axios parameter creator
 * @export
 */
export const AnswersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete Answer
     * @param {string} answerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAnswerAnswersAnswerIdDelete: async (
      answerId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'answerId' is not null or undefined
      assertParamExists(
        "deleteAnswerAnswersAnswerIdDelete",
        "answerId",
        answerId,
      );
      const localVarPath = `/answers/{answer_id}/`.replace(
        `{${"answer_id"}}`,
        encodeURIComponent(String(answerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Orders Order Id Answers
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrdersOrderIdAnswersOrdersOrderIdAnswersDelete: async (
      orderId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists(
        "deleteOrdersOrderIdAnswersOrdersOrderIdAnswersDelete",
        "orderId",
        orderId,
      );
      const localVarPath = `/orders/{order_id}/answers/`.replace(
        `{${"order_id"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Answer
     * @param {string} answerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnswerAnswersAnswerIdGet: async (
      answerId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'answerId' is not null or undefined
      assertParamExists("getAnswerAnswersAnswerIdGet", "answerId", answerId);
      const localVarPath = `/answers/{answer_id}/`.replace(
        `{${"answer_id"}}`,
        encodeURIComponent(String(answerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Answer Order Id
     * @param {string} orderId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnswerOrderIdOrdersOrderIdAnswersGet: async (
      orderId: string,
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists(
        "getAnswerOrderIdOrdersOrderIdAnswersGet",
        "orderId",
        orderId,
      );
      const localVarPath = `/orders/{order_id}/answers/`.replace(
        `{${"order_id"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Answers
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnswersAnswersGet: async (
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/answers/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch Answer
     * @param {string} answerId
     * @param {AnswerPatch} answerPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchAnswerAnswersAnswerIdPatch: async (
      answerId: string,
      answerPatch: AnswerPatch,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'answerId' is not null or undefined
      assertParamExists(
        "patchAnswerAnswersAnswerIdPatch",
        "answerId",
        answerId,
      );
      // verify required parameter 'answerPatch' is not null or undefined
      assertParamExists(
        "patchAnswerAnswersAnswerIdPatch",
        "answerPatch",
        answerPatch,
      );
      const localVarPath = `/answers/{answer_id}/`.replace(
        `{${"answer_id"}}`,
        encodeURIComponent(String(answerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        answerPatch,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post Answer
     * @param {AnswerCreate} answerCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAnswerAnswersPost: async (
      answerCreate: AnswerCreate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'answerCreate' is not null or undefined
      assertParamExists("postAnswerAnswersPost", "answerCreate", answerCreate);
      const localVarPath = `/answers/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        answerCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post Answers
     * @param {AnswerRequest} answerRequest
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAnswersAnswersBulkPost: async (
      answerRequest: AnswerRequest,
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'answerRequest' is not null or undefined
      assertParamExists(
        "postAnswersAnswersBulkPost",
        "answerRequest",
        answerRequest,
      );
      const localVarPath = `/answers/bulk/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        answerRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AnswersApi - functional programming interface
 * @export
 */
export const AnswersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AnswersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Answer
     * @param {string} answerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAnswerAnswersAnswerIdDelete(
      answerId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteAnswerResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAnswerAnswersAnswerIdDelete(
          answerId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AnswersApi.deleteAnswerAnswersAnswerIdDelete"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Orders Order Id Answers
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOrdersOrderIdAnswersOrdersOrderIdAnswersDelete(
      orderId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteAnswerResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteOrdersOrderIdAnswersOrdersOrderIdAnswersDelete(
          orderId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "AnswersApi.deleteOrdersOrderIdAnswersOrdersOrderIdAnswersDelete"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Answer
     * @param {string} answerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnswerAnswersAnswerIdGet(
      answerId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Answer>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAnswerAnswersAnswerIdGet(
          answerId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AnswersApi.getAnswerAnswersAnswerIdGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Answer Order Id
     * @param {string} orderId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnswerOrderIdOrdersOrderIdAnswersGet(
      orderId: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AnswerCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAnswerOrderIdOrdersOrderIdAnswersGet(
          orderId,
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "AnswersApi.getAnswerOrderIdOrdersOrderIdAnswersGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Answers
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnswersAnswersGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AnswerCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAnswersAnswersGet(
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AnswersApi.getAnswersAnswersGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Patch Answer
     * @param {string} answerId
     * @param {AnswerPatch} answerPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchAnswerAnswersAnswerIdPatch(
      answerId: string,
      answerPatch: AnswerPatch,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Answer>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchAnswerAnswersAnswerIdPatch(
          answerId,
          answerPatch,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AnswersApi.patchAnswerAnswersAnswerIdPatch"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Post Answer
     * @param {AnswerCreate} answerCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAnswerAnswersPost(
      answerCreate: AnswerCreate,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Answer>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postAnswerAnswersPost(
          answerCreate,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AnswersApi.postAnswerAnswersPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Post Answers
     * @param {AnswerRequest} answerRequest
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAnswersAnswersBulkPost(
      answerRequest: AnswerRequest,
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AnswerCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postAnswersAnswersBulkPost(
          answerRequest,
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["AnswersApi.postAnswersAnswersBulkPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * AnswersApi - factory interface
 * @export
 */
export const AnswersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AnswersApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Answer
     * @param {string} answerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAnswerAnswersAnswerIdDelete(
      answerId: string,
      options?: any,
    ): AxiosPromise<DeleteAnswerResponse> {
      return localVarFp
        .deleteAnswerAnswersAnswerIdDelete(answerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Orders Order Id Answers
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrdersOrderIdAnswersOrdersOrderIdAnswersDelete(
      orderId: string,
      options?: any,
    ): AxiosPromise<DeleteAnswerResponse> {
      return localVarFp
        .deleteOrdersOrderIdAnswersOrdersOrderIdAnswersDelete(orderId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Answer
     * @param {string} answerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnswerAnswersAnswerIdGet(
      answerId: string,
      options?: any,
    ): AxiosPromise<Answer> {
      return localVarFp
        .getAnswerAnswersAnswerIdGet(answerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Answer Order Id
     * @param {string} orderId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnswerOrderIdOrdersOrderIdAnswersGet(
      orderId: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<AnswerCollection> {
      return localVarFp
        .getAnswerOrderIdOrdersOrderIdAnswersGet(
          orderId,
          pageToken,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Answers
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnswersAnswersGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<AnswerCollection> {
      return localVarFp
        .getAnswersAnswersGet(pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Patch Answer
     * @param {string} answerId
     * @param {AnswerPatch} answerPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchAnswerAnswersAnswerIdPatch(
      answerId: string,
      answerPatch: AnswerPatch,
      options?: any,
    ): AxiosPromise<Answer> {
      return localVarFp
        .patchAnswerAnswersAnswerIdPatch(answerId, answerPatch, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Post Answer
     * @param {AnswerCreate} answerCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAnswerAnswersPost(
      answerCreate: AnswerCreate,
      options?: any,
    ): AxiosPromise<Answer> {
      return localVarFp
        .postAnswerAnswersPost(answerCreate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Post Answers
     * @param {AnswerRequest} answerRequest
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAnswersAnswersBulkPost(
      answerRequest: AnswerRequest,
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<AnswerCollection> {
      return localVarFp
        .postAnswersAnswersBulkPost(answerRequest, pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AnswersApi - object-oriented interface
 * @export
 * @class AnswersApi
 * @extends {BaseAPI}
 */
export class AnswersApi extends BaseAPI {
  /**
   *
   * @summary Delete Answer
   * @param {string} answerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnswersApi
   */
  public deleteAnswerAnswersAnswerIdDelete(
    answerId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return AnswersApiFp(this.configuration)
      .deleteAnswerAnswersAnswerIdDelete(answerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Orders Order Id Answers
   * @param {string} orderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnswersApi
   */
  public deleteOrdersOrderIdAnswersOrdersOrderIdAnswersDelete(
    orderId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return AnswersApiFp(this.configuration)
      .deleteOrdersOrderIdAnswersOrdersOrderIdAnswersDelete(orderId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Answer
   * @param {string} answerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnswersApi
   */
  public getAnswerAnswersAnswerIdGet(
    answerId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return AnswersApiFp(this.configuration)
      .getAnswerAnswersAnswerIdGet(answerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Answer Order Id
   * @param {string} orderId
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnswersApi
   */
  public getAnswerOrderIdOrdersOrderIdAnswersGet(
    orderId: string,
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return AnswersApiFp(this.configuration)
      .getAnswerOrderIdOrdersOrderIdAnswersGet(
        orderId,
        pageToken,
        pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Answers
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnswersApi
   */
  public getAnswersAnswersGet(
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return AnswersApiFp(this.configuration)
      .getAnswersAnswersGet(pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch Answer
   * @param {string} answerId
   * @param {AnswerPatch} answerPatch
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnswersApi
   */
  public patchAnswerAnswersAnswerIdPatch(
    answerId: string,
    answerPatch: AnswerPatch,
    options?: RawAxiosRequestConfig,
  ) {
    return AnswersApiFp(this.configuration)
      .patchAnswerAnswersAnswerIdPatch(answerId, answerPatch, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Post Answer
   * @param {AnswerCreate} answerCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnswersApi
   */
  public postAnswerAnswersPost(
    answerCreate: AnswerCreate,
    options?: RawAxiosRequestConfig,
  ) {
    return AnswersApiFp(this.configuration)
      .postAnswerAnswersPost(answerCreate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Post Answers
   * @param {AnswerRequest} answerRequest
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnswersApi
   */
  public postAnswersAnswersBulkPost(
    answerRequest: AnswerRequest,
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return AnswersApiFp(this.configuration)
      .postAnswersAnswersBulkPost(answerRequest, pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get File
     * @param {string} dir
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileFilesDirFilenameGet: async (
      dir: string,
      filename: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dir' is not null or undefined
      assertParamExists("getFileFilesDirFilenameGet", "dir", dir);
      // verify required parameter 'filename' is not null or undefined
      assertParamExists("getFileFilesDirFilenameGet", "filename", filename);
      const localVarPath = `/files/{dir}/{filename}`
        .replace(`{${"dir"}}`, encodeURIComponent(String(dir)))
        .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload
     * @param {string} dir
     * @param {Array<File>} files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFilesDirPost: async (
      dir: string,
      files: Array<File>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dir' is not null or undefined
      assertParamExists("uploadFilesDirPost", "dir", dir);
      // verify required parameter 'files' is not null or undefined
      assertParamExists("uploadFilesDirPost", "files", files);
      const localVarPath = `/files/{dir}/`.replace(
        `{${"dir"}}`,
        encodeURIComponent(String(dir)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (files) {
        files.forEach((element) => {
          localVarFormParams.append("files", element as any);
        });
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get File
     * @param {string} dir
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFileFilesDirFilenameGet(
      dir: string,
      filename: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFileFilesDirFilenameGet(
          dir,
          filename,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["FilesApi.getFileFilesDirFilenameGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Upload
     * @param {string} dir
     * @param {Array<File>} files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadFilesDirPost(
      dir: string,
      files: Array<File>,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FileUploadResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadFilesDirPost(dir, files, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["FilesApi.uploadFilesDirPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FilesApiFp(configuration);
  return {
    /**
     *
     * @summary Get File
     * @param {string} dir
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileFilesDirFilenameGet(
      dir: string,
      filename: string,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .getFileFilesDirFilenameGet(dir, filename, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload
     * @param {string} dir
     * @param {Array<File>} files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFilesDirPost(
      dir: string,
      files: Array<File>,
      options?: any,
    ): AxiosPromise<FileUploadResponse> {
      return localVarFp
        .uploadFilesDirPost(dir, files, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
  /**
   *
   * @summary Get File
   * @param {string} dir
   * @param {string} filename
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public getFileFilesDirFilenameGet(
    dir: string,
    filename: string,
    options?: RawAxiosRequestConfig,
  ) {
    return FilesApiFp(this.configuration)
      .getFileFilesDirFilenameGet(dir, filename, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload
   * @param {string} dir
   * @param {Array<File>} files
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public uploadFilesDirPost(
    dir: string,
    files: Array<File>,
    options?: RawAxiosRequestConfig,
  ) {
    return FilesApiFp(this.configuration)
      .uploadFilesDirPost(dir, files, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete Notification Rules Rule Id
     * @param {string} ruleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNotificationRulesRuleIdNotificationRulesRuleIdDelete: async (
      ruleId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ruleId' is not null or undefined
      assertParamExists(
        "deleteNotificationRulesRuleIdNotificationRulesRuleIdDelete",
        "ruleId",
        ruleId,
      );
      const localVarPath = `/notification_rules/{rule_id}`.replace(
        `{${"rule_id"}}`,
        encodeURIComponent(String(ruleId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Notification Rules
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationRulesNotificationRulesGet: async (
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/notification_rules`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Notification Rules Rule Id
     * @param {string} ruleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationRulesRuleIdNotificationRulesRuleIdGet: async (
      ruleId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ruleId' is not null or undefined
      assertParamExists(
        "getNotificationRulesRuleIdNotificationRulesRuleIdGet",
        "ruleId",
        ruleId,
      );
      const localVarPath = `/notification_rules/{rule_id}`.replace(
        `{${"rule_id"}}`,
        encodeURIComponent(String(ruleId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch Notification Rules Rule Id
     * @param {string} ruleId
     * @param {NotificationRulePatch} notificationRulePatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchNotificationRulesRuleIdNotificationRulesRuleIdPatch: async (
      ruleId: string,
      notificationRulePatch: NotificationRulePatch,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'ruleId' is not null or undefined
      assertParamExists(
        "patchNotificationRulesRuleIdNotificationRulesRuleIdPatch",
        "ruleId",
        ruleId,
      );
      // verify required parameter 'notificationRulePatch' is not null or undefined
      assertParamExists(
        "patchNotificationRulesRuleIdNotificationRulesRuleIdPatch",
        "notificationRulePatch",
        notificationRulePatch,
      );
      const localVarPath = `/notification_rules/{rule_id}`.replace(
        `{${"rule_id"}}`,
        encodeURIComponent(String(ruleId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notificationRulePatch,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post Notification Rules
     * @param {NotificationRuleCreate} notificationRuleCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postNotificationRulesNotificationRulesPost: async (
      notificationRuleCreate: NotificationRuleCreate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'notificationRuleCreate' is not null or undefined
      assertParamExists(
        "postNotificationRulesNotificationRulesPost",
        "notificationRuleCreate",
        notificationRuleCreate,
      );
      const localVarPath = `/notification_rules`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notificationRuleCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    NotificationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Notification Rules Rule Id
     * @param {string} ruleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteNotificationRulesRuleIdNotificationRulesRuleIdDelete(
      ruleId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteNotificationRulesRuleIdNotificationRulesRuleIdDelete(
          ruleId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "NotificationApi.deleteNotificationRulesRuleIdNotificationRulesRuleIdDelete"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Notification Rules
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNotificationRulesNotificationRulesGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NotificationRuleCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNotificationRulesNotificationRulesGet(
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "NotificationApi.getNotificationRulesNotificationRulesGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Notification Rules Rule Id
     * @param {string} ruleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNotificationRulesRuleIdNotificationRulesRuleIdGet(
      ruleId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NotificationRule>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNotificationRulesRuleIdNotificationRulesRuleIdGet(
          ruleId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "NotificationApi.getNotificationRulesRuleIdNotificationRulesRuleIdGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Patch Notification Rules Rule Id
     * @param {string} ruleId
     * @param {NotificationRulePatch} notificationRulePatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchNotificationRulesRuleIdNotificationRulesRuleIdPatch(
      ruleId: string,
      notificationRulePatch: NotificationRulePatch,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NotificationRule>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchNotificationRulesRuleIdNotificationRulesRuleIdPatch(
          ruleId,
          notificationRulePatch,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "NotificationApi.patchNotificationRulesRuleIdNotificationRulesRuleIdPatch"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Post Notification Rules
     * @param {NotificationRuleCreate} notificationRuleCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNotificationRulesNotificationRulesPost(
      notificationRuleCreate: NotificationRuleCreate,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NotificationRule>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postNotificationRulesNotificationRulesPost(
          notificationRuleCreate,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "NotificationApi.postNotificationRulesNotificationRulesPost"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NotificationApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Notification Rules Rule Id
     * @param {string} ruleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNotificationRulesRuleIdNotificationRulesRuleIdDelete(
      ruleId: string,
      options?: any,
    ): AxiosPromise<DeleteResponse> {
      return localVarFp
        .deleteNotificationRulesRuleIdNotificationRulesRuleIdDelete(
          ruleId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Notification Rules
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationRulesNotificationRulesGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<NotificationRuleCollection> {
      return localVarFp
        .getNotificationRulesNotificationRulesGet(pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Notification Rules Rule Id
     * @param {string} ruleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationRulesRuleIdNotificationRulesRuleIdGet(
      ruleId: string,
      options?: any,
    ): AxiosPromise<NotificationRule> {
      return localVarFp
        .getNotificationRulesRuleIdNotificationRulesRuleIdGet(ruleId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Patch Notification Rules Rule Id
     * @param {string} ruleId
     * @param {NotificationRulePatch} notificationRulePatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchNotificationRulesRuleIdNotificationRulesRuleIdPatch(
      ruleId: string,
      notificationRulePatch: NotificationRulePatch,
      options?: any,
    ): AxiosPromise<NotificationRule> {
      return localVarFp
        .patchNotificationRulesRuleIdNotificationRulesRuleIdPatch(
          ruleId,
          notificationRulePatch,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Post Notification Rules
     * @param {NotificationRuleCreate} notificationRuleCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postNotificationRulesNotificationRulesPost(
      notificationRuleCreate: NotificationRuleCreate,
      options?: any,
    ): AxiosPromise<NotificationRule> {
      return localVarFp
        .postNotificationRulesNotificationRulesPost(
          notificationRuleCreate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
  /**
   *
   * @summary Delete Notification Rules Rule Id
   * @param {string} ruleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public deleteNotificationRulesRuleIdNotificationRulesRuleIdDelete(
    ruleId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return NotificationApiFp(this.configuration)
      .deleteNotificationRulesRuleIdNotificationRulesRuleIdDelete(
        ruleId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Notification Rules
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public getNotificationRulesNotificationRulesGet(
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return NotificationApiFp(this.configuration)
      .getNotificationRulesNotificationRulesGet(pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Notification Rules Rule Id
   * @param {string} ruleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public getNotificationRulesRuleIdNotificationRulesRuleIdGet(
    ruleId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return NotificationApiFp(this.configuration)
      .getNotificationRulesRuleIdNotificationRulesRuleIdGet(ruleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch Notification Rules Rule Id
   * @param {string} ruleId
   * @param {NotificationRulePatch} notificationRulePatch
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public patchNotificationRulesRuleIdNotificationRulesRuleIdPatch(
    ruleId: string,
    notificationRulePatch: NotificationRulePatch,
    options?: RawAxiosRequestConfig,
  ) {
    return NotificationApiFp(this.configuration)
      .patchNotificationRulesRuleIdNotificationRulesRuleIdPatch(
        ruleId,
        notificationRulePatch,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Post Notification Rules
   * @param {NotificationRuleCreate} notificationRuleCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public postNotificationRulesNotificationRulesPost(
    notificationRuleCreate: NotificationRuleCreate,
    options?: RawAxiosRequestConfig,
  ) {
    return NotificationApiFp(this.configuration)
      .postNotificationRulesNotificationRulesPost(
        notificationRuleCreate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrderItemsApi - axios parameter creator
 * @export
 */
export const OrderItemsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete Order Item
     * @param {string} orderItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrderItemOrderItemsOrderItemIdDelete: async (
      orderItemId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderItemId' is not null or undefined
      assertParamExists(
        "deleteOrderItemOrderItemsOrderItemIdDelete",
        "orderItemId",
        orderItemId,
      );
      const localVarPath = `/order_items/{order_item_id}/`.replace(
        `{${"order_item_id"}}`,
        encodeURIComponent(String(orderItemId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Order Item
     * @param {string} orderItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderItemOrderItemsOrderItemIdGet: async (
      orderItemId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderItemId' is not null or undefined
      assertParamExists(
        "getOrderItemOrderItemsOrderItemIdGet",
        "orderItemId",
        orderItemId,
      );
      const localVarPath = `/order_items/{order_item_id}/`.replace(
        `{${"order_item_id"}}`,
        encodeURIComponent(String(orderItemId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Order Items
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderItemsOrderItemsGet: async (
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/order_items/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch Order Item Order Item Id
     * @param {string} orderItemId
     * @param {OrderItemPatch} orderItemPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrderItemOrderItemIdOrderItemsOrderItemIdPatch: async (
      orderItemId: string,
      orderItemPatch: OrderItemPatch,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderItemId' is not null or undefined
      assertParamExists(
        "patchOrderItemOrderItemIdOrderItemsOrderItemIdPatch",
        "orderItemId",
        orderItemId,
      );
      // verify required parameter 'orderItemPatch' is not null or undefined
      assertParamExists(
        "patchOrderItemOrderItemIdOrderItemsOrderItemIdPatch",
        "orderItemPatch",
        orderItemPatch,
      );
      const localVarPath = `/order_items/{order_item_id}/`.replace(
        `{${"order_item_id"}}`,
        encodeURIComponent(String(orderItemId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderItemPatch,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post Order Item
     * @param {OrderItemCreate} orderItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrderItemOrderItemsPost: async (
      orderItemCreate: OrderItemCreate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderItemCreate' is not null or undefined
      assertParamExists(
        "postOrderItemOrderItemsPost",
        "orderItemCreate",
        orderItemCreate,
      );
      const localVarPath = `/order_items/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderItemCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrderItemsApi - functional programming interface
 * @export
 */
export const OrderItemsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    OrderItemsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Order Item
     * @param {string} orderItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOrderItemOrderItemsOrderItemIdDelete(
      orderItemId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteOrderItemResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteOrderItemOrderItemsOrderItemIdDelete(
          orderItemId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderItemsApi.deleteOrderItemOrderItemsOrderItemIdDelete"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Order Item
     * @param {string} orderItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrderItemOrderItemsOrderItemIdGet(
      orderItemId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrderItemOrderItemsOrderItemIdGet(
          orderItemId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderItemsApi.getOrderItemOrderItemsOrderItemIdGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Order Items
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrderItemsOrderItemsGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderItemCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrderItemsOrderItemsGet(
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderItemsApi.getOrderItemsOrderItemsGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Patch Order Item Order Item Id
     * @param {string} orderItemId
     * @param {OrderItemPatch} orderItemPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrderItemOrderItemIdOrderItemsOrderItemIdPatch(
      orderItemId: string,
      orderItemPatch: OrderItemPatch,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrderItemOrderItemIdOrderItemsOrderItemIdPatch(
          orderItemId,
          orderItemPatch,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderItemsApi.patchOrderItemOrderItemIdOrderItemsOrderItemIdPatch"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Post Order Item
     * @param {OrderItemCreate} orderItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrderItemOrderItemsPost(
      orderItemCreate: OrderItemCreate,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrderItemOrderItemsPost(
          orderItemCreate,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderItemsApi.postOrderItemOrderItemsPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * OrderItemsApi - factory interface
 * @export
 */
export const OrderItemsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OrderItemsApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Order Item
     * @param {string} orderItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrderItemOrderItemsOrderItemIdDelete(
      orderItemId: string,
      options?: any,
    ): AxiosPromise<DeleteOrderItemResponse> {
      return localVarFp
        .deleteOrderItemOrderItemsOrderItemIdDelete(orderItemId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Order Item
     * @param {string} orderItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderItemOrderItemsOrderItemIdGet(
      orderItemId: string,
      options?: any,
    ): AxiosPromise<OrderItem> {
      return localVarFp
        .getOrderItemOrderItemsOrderItemIdGet(orderItemId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Order Items
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderItemsOrderItemsGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<OrderItemCollection> {
      return localVarFp
        .getOrderItemsOrderItemsGet(pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Patch Order Item Order Item Id
     * @param {string} orderItemId
     * @param {OrderItemPatch} orderItemPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrderItemOrderItemIdOrderItemsOrderItemIdPatch(
      orderItemId: string,
      orderItemPatch: OrderItemPatch,
      options?: any,
    ): AxiosPromise<OrderItem> {
      return localVarFp
        .patchOrderItemOrderItemIdOrderItemsOrderItemIdPatch(
          orderItemId,
          orderItemPatch,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Post Order Item
     * @param {OrderItemCreate} orderItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrderItemOrderItemsPost(
      orderItemCreate: OrderItemCreate,
      options?: any,
    ): AxiosPromise<OrderItem> {
      return localVarFp
        .postOrderItemOrderItemsPost(orderItemCreate, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrderItemsApi - object-oriented interface
 * @export
 * @class OrderItemsApi
 * @extends {BaseAPI}
 */
export class OrderItemsApi extends BaseAPI {
  /**
   *
   * @summary Delete Order Item
   * @param {string} orderItemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderItemsApi
   */
  public deleteOrderItemOrderItemsOrderItemIdDelete(
    orderItemId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderItemsApiFp(this.configuration)
      .deleteOrderItemOrderItemsOrderItemIdDelete(orderItemId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Order Item
   * @param {string} orderItemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderItemsApi
   */
  public getOrderItemOrderItemsOrderItemIdGet(
    orderItemId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderItemsApiFp(this.configuration)
      .getOrderItemOrderItemsOrderItemIdGet(orderItemId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Order Items
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderItemsApi
   */
  public getOrderItemsOrderItemsGet(
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderItemsApiFp(this.configuration)
      .getOrderItemsOrderItemsGet(pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch Order Item Order Item Id
   * @param {string} orderItemId
   * @param {OrderItemPatch} orderItemPatch
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderItemsApi
   */
  public patchOrderItemOrderItemIdOrderItemsOrderItemIdPatch(
    orderItemId: string,
    orderItemPatch: OrderItemPatch,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderItemsApiFp(this.configuration)
      .patchOrderItemOrderItemIdOrderItemsOrderItemIdPatch(
        orderItemId,
        orderItemPatch,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Post Order Item
   * @param {OrderItemCreate} orderItemCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderItemsApi
   */
  public postOrderItemOrderItemsPost(
    orderItemCreate: OrderItemCreate,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderItemsApiFp(this.configuration)
      .postOrderItemOrderItemsPost(orderItemCreate, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrderOrderItemsApi - axios parameter creator
 * @export
 */
export const OrderOrderItemsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete Order Order Item
     * @param {string} orderOrderItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrderOrderItemOrderOrderItemsOrderOrderItemIdDelete: async (
      orderOrderItemId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderOrderItemId' is not null or undefined
      assertParamExists(
        "deleteOrderOrderItemOrderOrderItemsOrderOrderItemIdDelete",
        "orderOrderItemId",
        orderOrderItemId,
      );
      const localVarPath = `/order_order_items/{order_order_item_id}/`.replace(
        `{${"order_order_item_id"}}`,
        encodeURIComponent(String(orderOrderItemId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Order Order Item
     * @param {string} orderOrderItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderOrderItemOrderOrderItemsOrderOrderItemIdGet: async (
      orderOrderItemId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderOrderItemId' is not null or undefined
      assertParamExists(
        "getOrderOrderItemOrderOrderItemsOrderOrderItemIdGet",
        "orderOrderItemId",
        orderOrderItemId,
      );
      const localVarPath = `/order_order_items/{order_order_item_id}/`.replace(
        `{${"order_order_item_id"}}`,
        encodeURIComponent(String(orderOrderItemId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Order Order Items
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderOrderItemsOrderOrderItemsGet: async (
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/order_order_items/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Orders Order Id Order Order Items
     * @param {string} orderId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrdersOrderIdOrderOrderItemsOrdersOrderIdOrderOrderItemsGet: async (
      orderId: string,
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists(
        "getOrdersOrderIdOrderOrderItemsOrdersOrderIdOrderOrderItemsGet",
        "orderId",
        orderId,
      );
      const localVarPath = `/orders/{order_id}/order_order_items/`.replace(
        `{${"order_id"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post Order Order Item
     * @param {OrderOrderItemCreate} orderOrderItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrderOrderItemOrderOrderItemsPost: async (
      orderOrderItemCreate: OrderOrderItemCreate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderOrderItemCreate' is not null or undefined
      assertParamExists(
        "postOrderOrderItemOrderOrderItemsPost",
        "orderOrderItemCreate",
        orderOrderItemCreate,
      );
      const localVarPath = `/order_order_items/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderOrderItemCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Approve or Reject Order Order Item
     * @param {string} orderOrderItemId
     * @param {OrderOrderItemAction} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveOrRejectItemRequest: async (
      orderOrderItemId: string,
      action: "approve" | "reject" | "pending",
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderOrderItemId' is not null or undefined
      assertParamExists(
        "approveOrRejectItemRequest",
        "orderOrderItemId",
        orderOrderItemId,
      );
      // verify required parameter 'action' is not null or undefined
      assertParamExists("approveOrRejectItemRequest", "action", action);

      const localVarPath =
        `/order_order_items/{order_order_item_id}/action/`.replace(
          `{${"order_order_item_id"}}`,
          encodeURIComponent(String(orderOrderItemId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarQueryParameter["action"] = action;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrderOrderItemsApi - functional programming interface
 * @export
 */
export const OrderOrderItemsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    OrderOrderItemsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Order Order Item
     * @param {string} orderOrderItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOrderOrderItemOrderOrderItemsOrderOrderItemIdDelete(
      orderOrderItemId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteOrderOrderItemResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteOrderOrderItemOrderOrderItemsOrderOrderItemIdDelete(
          orderOrderItemId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderOrderItemsApi.deleteOrderOrderItemOrderOrderItemsOrderOrderItemIdDelete"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Order Order Item
     * @param {string} orderOrderItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrderOrderItemOrderOrderItemsOrderOrderItemIdGet(
      orderOrderItemId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderOrderItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrderOrderItemOrderOrderItemsOrderOrderItemIdGet(
          orderOrderItemId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderOrderItemsApi.getOrderOrderItemOrderOrderItemsOrderOrderItemIdGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Order Order Items
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrderOrderItemsOrderOrderItemsGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderOrderItemCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrderOrderItemsOrderOrderItemsGet(
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderOrderItemsApi.getOrderOrderItemsOrderOrderItemsGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Orders Order Id Order Order Items
     * @param {string} orderId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrdersOrderIdOrderOrderItemsOrdersOrderIdOrderOrderItemsGet(
      orderId: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderOrderItemCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrdersOrderIdOrderOrderItemsOrdersOrderIdOrderOrderItemsGet(
          orderId,
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderOrderItemsApi.getOrdersOrderIdOrderOrderItemsOrdersOrderIdOrderOrderItemsGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Post Order Order Item
     * @param {OrderOrderItemCreate} orderOrderItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrderOrderItemOrderOrderItemsPost(
      orderOrderItemCreate: OrderOrderItemCreate,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderOrderItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrderOrderItemOrderOrderItemsPost(
          orderOrderItemCreate,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderOrderItemsApi.postOrderOrderItemOrderOrderItemsPost"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Approve or Reject Order Order Item
     * @param {string} orderOrderItemId
     * @param {OrderOrderItemAction} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveOrRejectItemRequest(
      orderOrderItemId: string,
      action: "approve" | "reject" | "pending",
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderOrderItem>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.approveOrRejectItemRequest(
          orderOrderItemId,
          action,
          options,
        );
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, basePath);
    },
  };
};

/**
 * OrderOrderItemsApi - factory interface
 * @export
 */
export const OrderOrderItemsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OrderOrderItemsApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Order Order Item
     * @param {string} orderOrderItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrderOrderItemOrderOrderItemsOrderOrderItemIdDelete(
      orderOrderItemId: string,
      options?: any,
    ): AxiosPromise<DeleteOrderOrderItemResponse> {
      return localVarFp
        .deleteOrderOrderItemOrderOrderItemsOrderOrderItemIdDelete(
          orderOrderItemId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Order Order Item
     * @param {string} orderOrderItemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderOrderItemOrderOrderItemsOrderOrderItemIdGet(
      orderOrderItemId: string,
      options?: any,
    ): AxiosPromise<OrderOrderItem> {
      return localVarFp
        .getOrderOrderItemOrderOrderItemsOrderOrderItemIdGet(
          orderOrderItemId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Order Order Items
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderOrderItemsOrderOrderItemsGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<OrderOrderItemCollection> {
      return localVarFp
        .getOrderOrderItemsOrderOrderItemsGet(pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Orders Order Id Order Order Items
     * @param {string} orderId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrdersOrderIdOrderOrderItemsOrdersOrderIdOrderOrderItemsGet(
      orderId: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<OrderOrderItemCollection> {
      return localVarFp
        .getOrdersOrderIdOrderOrderItemsOrdersOrderIdOrderOrderItemsGet(
          orderId,
          pageToken,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Post Order Order Item
     * @param {OrderOrderItemCreate} orderOrderItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrderOrderItemOrderOrderItemsPost(
      orderOrderItemCreate: OrderOrderItemCreate,
      options?: any,
    ): AxiosPromise<OrderOrderItem> {
      return localVarFp
        .postOrderOrderItemOrderOrderItemsPost(orderOrderItemCreate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Approve or Reject Order Order Item
     * @param {string} orderOrderItemId
     * @param {OrderOrderItemAction} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveOrRejectItemRequest(
      orderOrderItemId: string,
      action: "approve" | "reject" | "pending",
      options?: any,
    ): AxiosPromise<OrderOrderItem> {
      return localVarFp
        .approveOrRejectItemRequest(orderOrderItemId, action, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrderOrderItemsApi - object-oriented interface
 * @export
 * @class OrderOrderItemsApi
 * @extends {BaseAPI}
 */
export class OrderOrderItemsApi extends BaseAPI {
  /**
   *
   * @summary Delete Order Order Item
   * @param {string} orderOrderItemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOrderItemsApi
   */
  public deleteOrderOrderItemOrderOrderItemsOrderOrderItemIdDelete(
    orderOrderItemId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderOrderItemsApiFp(this.configuration)
      .deleteOrderOrderItemOrderOrderItemsOrderOrderItemIdDelete(
        orderOrderItemId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Order Order Item
   * @param {string} orderOrderItemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOrderItemsApi
   */
  public getOrderOrderItemOrderOrderItemsOrderOrderItemIdGet(
    orderOrderItemId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderOrderItemsApiFp(this.configuration)
      .getOrderOrderItemOrderOrderItemsOrderOrderItemIdGet(
        orderOrderItemId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Order Order Items
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOrderItemsApi
   */
  public getOrderOrderItemsOrderOrderItemsGet(
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderOrderItemsApiFp(this.configuration)
      .getOrderOrderItemsOrderOrderItemsGet(pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Orders Order Id Order Order Items
   * @param {string} orderId
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOrderItemsApi
   */
  public getOrdersOrderIdOrderOrderItemsOrdersOrderIdOrderOrderItemsGet(
    orderId: string,
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderOrderItemsApiFp(this.configuration)
      .getOrdersOrderIdOrderOrderItemsOrdersOrderIdOrderOrderItemsGet(
        orderId,
        pageToken,
        pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Post Order Order Item
   * @param {OrderOrderItemCreate} orderOrderItemCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOrderItemsApi
   */
  public postOrderOrderItemOrderOrderItemsPost(
    orderOrderItemCreate: OrderOrderItemCreate,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderOrderItemsApiFp(this.configuration)
      .postOrderOrderItemOrderOrderItemsPost(orderOrderItemCreate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Approve or Reject Order Order Item
   * @param {string} orderOrderItemId
   * @param {OrderOrderItemAction} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOrderItemsApi
   */
  public approveOrRejectItemRequest(
    orderOrderItemId: string,
    action: "approve" | "reject" | "pending",
    options?: RawAxiosRequestConfig,
  ) {
    return OrderOrderItemsApiFp(this.configuration)
      .approveOrRejectItemRequest(orderOrderItemId, action, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrderStaffsApi - axios parameter creator
 * @export
 */
export const OrderStaffsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete Order Staff
     * @param {string} orderStaffId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrderStaffOrderStaffsOrderStaffIdDelete: async (
      orderStaffId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderStaffId' is not null or undefined
      assertParamExists(
        "deleteOrderStaffOrderStaffsOrderStaffIdDelete",
        "orderStaffId",
        orderStaffId,
      );
      const localVarPath = `/order_staffs/{order_staff_id}/`.replace(
        `{${"order_staff_id"}}`,
        encodeURIComponent(String(orderStaffId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Order Staff
     * @param {string} orderStaffId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderStaffOrderStaffsOrderStaffIdGet: async (
      orderStaffId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderStaffId' is not null or undefined
      assertParamExists(
        "getOrderStaffOrderStaffsOrderStaffIdGet",
        "orderStaffId",
        orderStaffId,
      );
      const localVarPath = `/order_staffs/{order_staff_id}/`.replace(
        `{${"order_staff_id"}}`,
        encodeURIComponent(String(orderStaffId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Order Staffs
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderStaffsOrderStaffsGet: async (
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/order_staffs/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Orders Order Id Order Staffs
     * @param {string} orderId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrdersOrderIdOrderStaffsOrdersOrderIdOrderStaffsGet: async (
      orderId: string,
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists(
        "getOrdersOrderIdOrderStaffsOrdersOrderIdOrderStaffsGet",
        "orderId",
        orderId,
      );
      const localVarPath = `/orders/{order_id}/order_staffs/`.replace(
        `{${"order_id"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Users Order Staffs
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersOrderStaffsUsersOrderStaffsGet: async (
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/users/order_staffs/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Users User Id Order Staffs
     * @param {string} userId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersUserIdOrderStaffsUsersUserIdOrderStaffsGet: async (
      userId: string,
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists(
        "getUsersUserIdOrderStaffsUsersUserIdOrderStaffsGet",
        "userId",
        userId,
      );
      const localVarPath = `/users/{user_id}/order_staffs/`.replace(
        `{${"user_id"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch Order Staff
     * @param {string} orderStaffId
     * @param {OrderStaffPatch} orderStaffPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrderStaffOrderStaffsOrderStaffIdPatch: async (
      orderStaffId: string,
      orderStaffPatch: OrderStaffPatch,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderStaffId' is not null or undefined
      assertParamExists(
        "patchOrderStaffOrderStaffsOrderStaffIdPatch",
        "orderStaffId",
        orderStaffId,
      );
      // verify required parameter 'orderStaffPatch' is not null or undefined
      assertParamExists(
        "patchOrderStaffOrderStaffsOrderStaffIdPatch",
        "orderStaffPatch",
        orderStaffPatch,
      );
      const localVarPath = `/order_staffs/{order_staff_id}/`.replace(
        `{${"order_staff_id"}}`,
        encodeURIComponent(String(orderStaffId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderStaffPatch,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post Order Staff
     * @param {OrderStaffCreate} orderStaffCreate
     * @param {boolean} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrderStaffOrderStaffsPost: async (
      orderStaffCreate: OrderStaffCreate,
      force?: boolean,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderStaffCreate' is not null or undefined
      assertParamExists(
        "postOrderStaffOrderStaffsPost",
        "orderStaffCreate",
        orderStaffCreate,
      );
      const localVarPath = `/order_staffs/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (force !== undefined) {
        localVarQueryParameter["force"] = force;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderStaffCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrderStaffsApi - functional programming interface
 * @export
 */
export const OrderStaffsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    OrderStaffsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Order Staff
     * @param {string} orderStaffId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOrderStaffOrderStaffsOrderStaffIdDelete(
      orderStaffId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteOrderStaffResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteOrderStaffOrderStaffsOrderStaffIdDelete(
          orderStaffId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderStaffsApi.deleteOrderStaffOrderStaffsOrderStaffIdDelete"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Order Staff
     * @param {string} orderStaffId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrderStaffOrderStaffsOrderStaffIdGet(
      orderStaffId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderStaff>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrderStaffOrderStaffsOrderStaffIdGet(
          orderStaffId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderStaffsApi.getOrderStaffOrderStaffsOrderStaffIdGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Order Staffs
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrderStaffsOrderStaffsGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderStaffCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrderStaffsOrderStaffsGet(
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderStaffsApi.getOrderStaffsOrderStaffsGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Orders Order Id Order Staffs
     * @param {string} orderId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrdersOrderIdOrderStaffsOrdersOrderIdOrderStaffsGet(
      orderId: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderStaffCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrdersOrderIdOrderStaffsOrdersOrderIdOrderStaffsGet(
          orderId,
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderStaffsApi.getOrdersOrderIdOrderStaffsOrdersOrderIdOrderStaffsGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Users Order Staffs
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsersOrderStaffsUsersOrderStaffsGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderStaffCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUsersOrderStaffsUsersOrderStaffsGet(
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderStaffsApi.getUsersOrderStaffsUsersOrderStaffsGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Users User Id Order Staffs
     * @param {string} userId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsersUserIdOrderStaffsUsersUserIdOrderStaffsGet(
      userId: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderStaffCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUsersUserIdOrderStaffsUsersUserIdOrderStaffsGet(
          userId,
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderStaffsApi.getUsersUserIdOrderStaffsUsersUserIdOrderStaffsGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Patch Order Staff
     * @param {string} orderStaffId
     * @param {OrderStaffPatch} orderStaffPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrderStaffOrderStaffsOrderStaffIdPatch(
      orderStaffId: string,
      orderStaffPatch: OrderStaffPatch,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderStaff>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrderStaffOrderStaffsOrderStaffIdPatch(
          orderStaffId,
          orderStaffPatch,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderStaffsApi.patchOrderStaffOrderStaffsOrderStaffIdPatch"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Post Order Staff
     * @param {OrderStaffCreate} orderStaffCreate
     * @param {boolean} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrderStaffOrderStaffsPost(
      orderStaffCreate: OrderStaffCreate,
      force?: boolean,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderStaff>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrderStaffOrderStaffsPost(
          orderStaffCreate,
          force,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrderStaffsApi.postOrderStaffOrderStaffsPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * OrderStaffsApi - factory interface
 * @export
 */
export const OrderStaffsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OrderStaffsApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Order Staff
     * @param {string} orderStaffId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrderStaffOrderStaffsOrderStaffIdDelete(
      orderStaffId: string,
      options?: any,
    ): AxiosPromise<DeleteOrderStaffResponse> {
      return localVarFp
        .deleteOrderStaffOrderStaffsOrderStaffIdDelete(orderStaffId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Order Staff
     * @param {string} orderStaffId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderStaffOrderStaffsOrderStaffIdGet(
      orderStaffId: string,
      options?: any,
    ): AxiosPromise<OrderStaff> {
      return localVarFp
        .getOrderStaffOrderStaffsOrderStaffIdGet(orderStaffId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Order Staffs
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderStaffsOrderStaffsGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<OrderStaffCollection> {
      return localVarFp
        .getOrderStaffsOrderStaffsGet(pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Orders Order Id Order Staffs
     * @param {string} orderId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrdersOrderIdOrderStaffsOrdersOrderIdOrderStaffsGet(
      orderId: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<OrderStaffCollection> {
      return localVarFp
        .getOrdersOrderIdOrderStaffsOrdersOrderIdOrderStaffsGet(
          orderId,
          pageToken,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Users Order Staffs
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersOrderStaffsUsersOrderStaffsGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<OrderStaffCollection> {
      return localVarFp
        .getUsersOrderStaffsUsersOrderStaffsGet(pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Users User Id Order Staffs
     * @param {string} userId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersUserIdOrderStaffsUsersUserIdOrderStaffsGet(
      userId: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<OrderStaffCollection> {
      return localVarFp
        .getUsersUserIdOrderStaffsUsersUserIdOrderStaffsGet(
          userId,
          pageToken,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Patch Order Staff
     * @param {string} orderStaffId
     * @param {OrderStaffPatch} orderStaffPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrderStaffOrderStaffsOrderStaffIdPatch(
      orderStaffId: string,
      orderStaffPatch: OrderStaffPatch,
      options?: any,
    ): AxiosPromise<OrderStaff> {
      return localVarFp
        .patchOrderStaffOrderStaffsOrderStaffIdPatch(
          orderStaffId,
          orderStaffPatch,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Post Order Staff
     * @param {OrderStaffCreate} orderStaffCreate
     * @param {boolean} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrderStaffOrderStaffsPost(
      orderStaffCreate: OrderStaffCreate,
      force?: boolean,
      options?: any,
    ): AxiosPromise<OrderStaff> {
      return localVarFp
        .postOrderStaffOrderStaffsPost(orderStaffCreate, force, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrderStaffsApi - object-oriented interface
 * @export
 * @class OrderStaffsApi
 * @extends {BaseAPI}
 */
export class OrderStaffsApi extends BaseAPI {
  /**
   *
   * @summary Delete Order Staff
   * @param {string} orderStaffId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderStaffsApi
   */
  public deleteOrderStaffOrderStaffsOrderStaffIdDelete(
    orderStaffId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderStaffsApiFp(this.configuration)
      .deleteOrderStaffOrderStaffsOrderStaffIdDelete(orderStaffId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Order Staff
   * @param {string} orderStaffId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderStaffsApi
   */
  public getOrderStaffOrderStaffsOrderStaffIdGet(
    orderStaffId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderStaffsApiFp(this.configuration)
      .getOrderStaffOrderStaffsOrderStaffIdGet(orderStaffId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Order Staffs
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderStaffsApi
   */
  public getOrderStaffsOrderStaffsGet(
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderStaffsApiFp(this.configuration)
      .getOrderStaffsOrderStaffsGet(pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Orders Order Id Order Staffs
   * @param {string} orderId
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderStaffsApi
   */
  public getOrdersOrderIdOrderStaffsOrdersOrderIdOrderStaffsGet(
    orderId: string,
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderStaffsApiFp(this.configuration)
      .getOrdersOrderIdOrderStaffsOrdersOrderIdOrderStaffsGet(
        orderId,
        pageToken,
        pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Users Order Staffs
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderStaffsApi
   */
  public getUsersOrderStaffsUsersOrderStaffsGet(
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderStaffsApiFp(this.configuration)
      .getUsersOrderStaffsUsersOrderStaffsGet(pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Users User Id Order Staffs
   * @param {string} userId
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderStaffsApi
   */
  public getUsersUserIdOrderStaffsUsersUserIdOrderStaffsGet(
    userId: string,
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderStaffsApiFp(this.configuration)
      .getUsersUserIdOrderStaffsUsersUserIdOrderStaffsGet(
        userId,
        pageToken,
        pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch Order Staff
   * @param {string} orderStaffId
   * @param {OrderStaffPatch} orderStaffPatch
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderStaffsApi
   */
  public patchOrderStaffOrderStaffsOrderStaffIdPatch(
    orderStaffId: string,
    orderStaffPatch: OrderStaffPatch,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderStaffsApiFp(this.configuration)
      .patchOrderStaffOrderStaffsOrderStaffIdPatch(
        orderStaffId,
        orderStaffPatch,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Post Order Staff
   * @param {OrderStaffCreate} orderStaffCreate
   * @param {boolean} [force]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderStaffsApi
   */
  public postOrderStaffOrderStaffsPost(
    orderStaffCreate: OrderStaffCreate,
    force?: boolean,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderStaffsApiFp(this.configuration)
      .postOrderStaffOrderStaffsPost(orderStaffCreate, force, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrderStatusesApi - axios parameter creator
 * @export
 */
export const OrderStatusesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete Order Status
     * @param {string} orderStatusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrderStatusOrderStatusesOrderStatusIdDelete: async (
      orderStatusId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderStatusId' is not null or undefined
      assertParamExists(
        "deleteOrderStatusOrderStatusesOrderStatusIdDelete",
        "orderStatusId",
        orderStatusId,
      );
      const localVarPath = `/order_statuses/{order_status_id}/`.replace(
        `{${"order_status_id"}}`,
        encodeURIComponent(String(orderStatusId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Order Status
     * @param {string} orderStatusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderStatusOrderStatusesOrderStatusIdGet: async (
      orderStatusId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderStatusId' is not null or undefined
      assertParamExists(
        "getOrderStatusOrderStatusesOrderStatusIdGet",
        "orderStatusId",
        orderStatusId,
      );
      const localVarPath = `/order_statuses/{order_status_id}/`.replace(
        `{${"order_status_id"}}`,
        encodeURIComponent(String(orderStatusId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Order Statuses
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderStatusesOrderStatusesGet: async (
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/order_statuses/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Orders Order Id Order Statuses
     * @param {string} orderId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrdersOrderIdOrderStatusesOrdersOrderIdOrderStatusesGet: async (
      orderId: string,
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists(
        "getOrdersOrderIdOrderStatusesOrdersOrderIdOrderStatusesGet",
        "orderId",
        orderId,
      );
      const localVarPath = `/orders/{order_id}/order_statuses/`.replace(
        `{${"order_id"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch Order Status
     * @param {string} orderStatusId
     * @param {OrderStatusPatch} orderStatusPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrderStatusOrderStatusesOrderStatusIdPatch: async (
      orderStatusId: string,
      orderStatusPatch: OrderStatusPatch,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderStatusId' is not null or undefined
      assertParamExists(
        "patchOrderStatusOrderStatusesOrderStatusIdPatch",
        "orderStatusId",
        orderStatusId,
      );
      // verify required parameter 'orderStatusPatch' is not null or undefined
      assertParamExists(
        "patchOrderStatusOrderStatusesOrderStatusIdPatch",
        "orderStatusPatch",
        orderStatusPatch,
      );
      const localVarPath = `/order_statuses/{order_status_id}/`.replace(
        `{${"order_status_id"}}`,
        encodeURIComponent(String(orderStatusId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderStatusPatch,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post Order Status
     * @param {OrderStatusCreate} orderStatusCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrderStatusOrderStatusesPost: async (
      orderStatusCreate: OrderStatusCreate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderStatusCreate' is not null or undefined
      assertParamExists(
        "postOrderStatusOrderStatusesPost",
        "orderStatusCreate",
        orderStatusCreate,
      );
      const localVarPath = `/order_statuses/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderStatusCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrderStatusesApi - functional programming interface
 * @export
 */
export const OrderStatusesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    OrderStatusesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Order Status
     * @param {string} orderStatusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOrderStatusOrderStatusesOrderStatusIdDelete(
      orderStatusId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteOrderStatusResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteOrderStatusOrderStatusesOrderStatusIdDelete(
          orderStatusId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderStatusesApi.deleteOrderStatusOrderStatusesOrderStatusIdDelete"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Order Status
     * @param {string} orderStatusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrderStatusOrderStatusesOrderStatusIdGet(
      orderStatusId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderStatus>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrderStatusOrderStatusesOrderStatusIdGet(
          orderStatusId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderStatusesApi.getOrderStatusOrderStatusesOrderStatusIdGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Order Statuses
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrderStatusesOrderStatusesGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderStatusCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrderStatusesOrderStatusesGet(
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderStatusesApi.getOrderStatusesOrderStatusesGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Orders Order Id Order Statuses
     * @param {string} orderId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrdersOrderIdOrderStatusesOrdersOrderIdOrderStatusesGet(
      orderId: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderStatusCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrdersOrderIdOrderStatusesOrdersOrderIdOrderStatusesGet(
          orderId,
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderStatusesApi.getOrdersOrderIdOrderStatusesOrdersOrderIdOrderStatusesGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Patch Order Status
     * @param {string} orderStatusId
     * @param {OrderStatusPatch} orderStatusPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrderStatusOrderStatusesOrderStatusIdPatch(
      orderStatusId: string,
      orderStatusPatch: OrderStatusPatch,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderStatus>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrderStatusOrderStatusesOrderStatusIdPatch(
          orderStatusId,
          orderStatusPatch,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderStatusesApi.patchOrderStatusOrderStatusesOrderStatusIdPatch"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Post Order Status
     * @param {OrderStatusCreate} orderStatusCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrderStatusOrderStatusesPost(
      orderStatusCreate: OrderStatusCreate,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderStatus>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrderStatusOrderStatusesPost(
          orderStatusCreate,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrderStatusesApi.postOrderStatusOrderStatusesPost"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * OrderStatusesApi - factory interface
 * @export
 */
export const OrderStatusesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OrderStatusesApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Order Status
     * @param {string} orderStatusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrderStatusOrderStatusesOrderStatusIdDelete(
      orderStatusId: string,
      options?: any,
    ): AxiosPromise<DeleteOrderStatusResponse> {
      return localVarFp
        .deleteOrderStatusOrderStatusesOrderStatusIdDelete(
          orderStatusId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Order Status
     * @param {string} orderStatusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderStatusOrderStatusesOrderStatusIdGet(
      orderStatusId: string,
      options?: any,
    ): AxiosPromise<OrderStatus> {
      return localVarFp
        .getOrderStatusOrderStatusesOrderStatusIdGet(orderStatusId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Order Statuses
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderStatusesOrderStatusesGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<OrderStatusCollection> {
      return localVarFp
        .getOrderStatusesOrderStatusesGet(pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Orders Order Id Order Statuses
     * @param {string} orderId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrdersOrderIdOrderStatusesOrdersOrderIdOrderStatusesGet(
      orderId: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<OrderStatusCollection> {
      return localVarFp
        .getOrdersOrderIdOrderStatusesOrdersOrderIdOrderStatusesGet(
          orderId,
          pageToken,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Patch Order Status
     * @param {string} orderStatusId
     * @param {OrderStatusPatch} orderStatusPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrderStatusOrderStatusesOrderStatusIdPatch(
      orderStatusId: string,
      orderStatusPatch: OrderStatusPatch,
      options?: any,
    ): AxiosPromise<OrderStatus> {
      return localVarFp
        .patchOrderStatusOrderStatusesOrderStatusIdPatch(
          orderStatusId,
          orderStatusPatch,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Post Order Status
     * @param {OrderStatusCreate} orderStatusCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrderStatusOrderStatusesPost(
      orderStatusCreate: OrderStatusCreate,
      options?: any,
    ): AxiosPromise<OrderStatus> {
      return localVarFp
        .postOrderStatusOrderStatusesPost(orderStatusCreate, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrderStatusesApi - object-oriented interface
 * @export
 * @class OrderStatusesApi
 * @extends {BaseAPI}
 */
export class OrderStatusesApi extends BaseAPI {
  /**
   *
   * @summary Delete Order Status
   * @param {string} orderStatusId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderStatusesApi
   */
  public deleteOrderStatusOrderStatusesOrderStatusIdDelete(
    orderStatusId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderStatusesApiFp(this.configuration)
      .deleteOrderStatusOrderStatusesOrderStatusIdDelete(orderStatusId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Order Status
   * @param {string} orderStatusId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderStatusesApi
   */
  public getOrderStatusOrderStatusesOrderStatusIdGet(
    orderStatusId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderStatusesApiFp(this.configuration)
      .getOrderStatusOrderStatusesOrderStatusIdGet(orderStatusId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Order Statuses
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderStatusesApi
   */
  public getOrderStatusesOrderStatusesGet(
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderStatusesApiFp(this.configuration)
      .getOrderStatusesOrderStatusesGet(pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Orders Order Id Order Statuses
   * @param {string} orderId
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderStatusesApi
   */
  public getOrdersOrderIdOrderStatusesOrdersOrderIdOrderStatusesGet(
    orderId: string,
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderStatusesApiFp(this.configuration)
      .getOrdersOrderIdOrderStatusesOrdersOrderIdOrderStatusesGet(
        orderId,
        pageToken,
        pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch Order Status
   * @param {string} orderStatusId
   * @param {OrderStatusPatch} orderStatusPatch
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderStatusesApi
   */
  public patchOrderStatusOrderStatusesOrderStatusIdPatch(
    orderStatusId: string,
    orderStatusPatch: OrderStatusPatch,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderStatusesApiFp(this.configuration)
      .patchOrderStatusOrderStatusesOrderStatusIdPatch(
        orderStatusId,
        orderStatusPatch,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Post Order Status
   * @param {OrderStatusCreate} orderStatusCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderStatusesApi
   */
  public postOrderStatusOrderStatusesPost(
    orderStatusCreate: OrderStatusCreate,
    options?: RawAxiosRequestConfig,
  ) {
    return OrderStatusesApiFp(this.configuration)
      .postOrderStatusOrderStatusesPost(orderStatusCreate, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete Order
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrderOrdersOrderIdDelete: async (
      orderId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("deleteOrderOrdersOrderIdDelete", "orderId", orderId);
      const localVarPath = `/orders/{order_id}/`.replace(
        `{${"order_id"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Order
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderOrdersOrderIdGet: async (
      orderId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists("getOrderOrdersOrderIdGet", "orderId", orderId);
      const localVarPath = `/orders/{order_id}/`.replace(
        `{${"order_id"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Orders
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {string} [orderDate] The date to filter orders by (format: YYYY-MM-DD)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrdersOrdersGet: async (
      orderDate?: string,
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/orders/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      if (orderDate !== undefined) {
        localVarQueryParameter["order_date"] = orderDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Orders by Date
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {string} [orderDate] The date to filter orders by (format: YYYY-MM-DD)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrdersDateDateOrdersGet: async (
      orderDate: string,
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderDate' is not null or undefined
      assertParamExists("getOrdersDateDateOrdersGet", "orderDate", orderDate);
      const localVarPath = `/orders/date/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      if (orderDate !== undefined) {
        localVarQueryParameter["order_date"] = orderDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get User User Id Orders
     * @param {string} userId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserUserIdOrdersUsersUserIdOrdersGet: async (
      userId: string,
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists(
        "getUserUserIdOrdersUsersUserIdOrdersGet",
        "userId",
        userId,
      );
      const localVarPath = `/users/{user_id}/orders/`.replace(
        `{${"user_id"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch Order Order Id
     * @param {string} orderId
     * @param {OrderPatch} orderPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrderOrderIdOrdersOrderIdPatch: async (
      orderId: string,
      orderPatch: OrderPatch,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists(
        "patchOrderOrderIdOrdersOrderIdPatch",
        "orderId",
        orderId,
      );
      // verify required parameter 'orderPatch' is not null or undefined
      assertParamExists(
        "patchOrderOrderIdOrdersOrderIdPatch",
        "orderPatch",
        orderPatch,
      );
      const localVarPath = `/orders/{order_id}/`.replace(
        `{${"order_id"}}`,
        encodeURIComponent(String(orderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderPatch,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post Order
     * @param {OrderCreate} orderCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrderOrdersPost: async (
      orderCreate: OrderCreate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderCreate' is not null or undefined
      assertParamExists("postOrderOrdersPost", "orderCreate", orderCreate);
      const localVarPath = `/orders/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Order
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOrderOrdersOrderIdDelete(
      orderId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteOrderOrdersOrderIdDelete(
          orderId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrdersApi.deleteOrderOrdersOrderIdDelete"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Order
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrderOrdersOrderIdGet(
      orderId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrderOrdersOrderIdGet(
          orderId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrdersApi.getOrderOrdersOrderIdGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Orders
     * @param {string} orderDate Date in format 'YYYY-MM-DD'
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrdersOrdersGet(
      orderDate?: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrdersOrdersGet(
          orderDate,
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrdersApi.getOrdersOrdersGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Orders by Date
     * @param {string} orderDate Date in format 'YYYY-MM-DD'
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrdersDateDateOrdersGet(
      orderDate: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOrdersDateDateOrdersGet(
          orderDate,
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrdersApi.getOrdersDateDateOrdersGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get User User Id Orders
     * @param {string} userId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserUserIdOrdersUsersUserIdOrdersGet(
      userId: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OrderCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserUserIdOrdersUsersUserIdOrdersGet(
          userId,
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "OrdersApi.getUserUserIdOrdersUsersUserIdOrdersGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Patch Order Order Id
     * @param {string} orderId
     * @param {OrderPatch} orderPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchOrderOrderIdOrdersOrderIdPatch(
      orderId: string,
      orderPatch: OrderPatch,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchOrderOrderIdOrdersOrderIdPatch(
          orderId,
          orderPatch,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrdersApi.patchOrderOrderIdOrdersOrderIdPatch"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Post Order
     * @param {OrderCreate} orderCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOrderOrdersPost(
      orderCreate: OrderCreate,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postOrderOrdersPost(
          orderCreate,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["OrdersApi.postOrderOrdersPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OrdersApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Order
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrderOrdersOrderIdDelete(
      orderId: string,
      options?: any,
    ): AxiosPromise<DeleteResponse> {
      return localVarFp
        .deleteOrderOrdersOrderIdDelete(orderId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Order
     * @param {string} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderOrdersOrderIdGet(
      orderId: string,
      options?: any,
    ): AxiosPromise<Order> {
      return localVarFp
        .getOrderOrdersOrderIdGet(orderId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Orders
     * @param {string} orderDate
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrdersOrdersGet(
      orderDate?: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<OrderCollection> {
      return localVarFp
        .getOrdersOrdersGet(orderDate, pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Orders by Date
     * @param {string} orderDate
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrdersDateDateOrdersGet(
      orderDate: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<OrderCollection> {
      return localVarFp
        .getOrdersDateDateOrdersGet(orderDate, pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get User User Id Orders
     * @param {string} userId
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserUserIdOrdersUsersUserIdOrdersGet(
      userId: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<OrderCollection> {
      return localVarFp
        .getUserUserIdOrdersUsersUserIdOrdersGet(
          userId,
          pageToken,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Patch Order Order Id
     * @param {string} orderId
     * @param {OrderPatch} orderPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchOrderOrderIdOrdersOrderIdPatch(
      orderId: string,
      orderPatch: OrderPatch,
      options?: any,
    ): AxiosPromise<Order> {
      return localVarFp
        .patchOrderOrderIdOrdersOrderIdPatch(orderId, orderPatch, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Post Order
     * @param {OrderCreate} orderCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOrderOrdersPost(
      orderCreate: OrderCreate,
      options?: any,
    ): AxiosPromise<Order> {
      return localVarFp
        .postOrderOrdersPost(orderCreate, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
  /**
   *
   * @summary Delete Order
   * @param {string} orderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public deleteOrderOrdersOrderIdDelete(
    orderId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .deleteOrderOrdersOrderIdDelete(orderId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Order
   * @param {string} orderId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public getOrderOrdersOrderIdGet(
    orderId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .getOrderOrdersOrderIdGet(orderId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Orders
   * @param {string} orderDate
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public getOrdersOrdersGet(
    orderDate?: string,
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .getOrdersOrdersGet(orderDate, pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Orders by Date
   * @param {string} orderDate
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public getOrdersDateDateOrdersGet(
    orderDate: string,
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .getOrdersDateDateOrdersGet(orderDate, pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get User User Id Orders
   * @param {string} userId
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public getUserUserIdOrdersUsersUserIdOrdersGet(
    userId: string,
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .getUserUserIdOrdersUsersUserIdOrdersGet(
        userId,
        pageToken,
        pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch Order Order Id
   * @param {string} orderId
   * @param {OrderPatch} orderPatch
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public patchOrderOrderIdOrdersOrderIdPatch(
    orderId: string,
    orderPatch: OrderPatch,
    options?: RawAxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .patchOrderOrderIdOrdersOrderIdPatch(orderId, orderPatch, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Post Order
   * @param {OrderCreate} orderCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersApi
   */
  public postOrderOrdersPost(
    orderCreate: OrderCreate,
    options?: RawAxiosRequestConfig,
  ) {
    return OrdersApiFp(this.configuration)
      .postOrderOrdersPost(orderCreate, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * QuestionsApi - axios parameter creator
 * @export
 */
export const QuestionsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete Question
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQuestionQuestionsQuestionIdDelete: async (
      questionId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists(
        "deleteQuestionQuestionsQuestionIdDelete",
        "questionId",
        questionId,
      );
      const localVarPath = `/questions/{question_id}/`.replace(
        `{${"question_id"}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Question
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionQuestionsQuestionIdGet: async (
      questionId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists(
        "getQuestionQuestionsQuestionIdGet",
        "questionId",
        questionId,
      );
      const localVarPath = `/questions/{question_id}/`.replace(
        `{${"question_id"}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Question Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionTypesQuestionsTypesGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/questions/types/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Questions
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionsQuestionsGet: async (
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/questions/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Questions
     * @param {string} targetType
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionsQuestionsTypeTargetTypeGet: async (
      targetType: string,
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'targetType' is not null or undefined
      assertParamExists(
        "getQuestionsQuestionsTypeTargetTypeGet",
        "targetType",
        targetType,
      );
      const localVarPath = `/questions/type/{target_type}/`.replace(
        `{${"target_type"}}`,
        encodeURIComponent(String(targetType)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch Question
     * @param {string} questionId
     * @param {QuestionPatch} questionPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchQuestionQuestionsQuestionIdPatch: async (
      questionId: string,
      questionPatch: QuestionPatch,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists(
        "patchQuestionQuestionsQuestionIdPatch",
        "questionId",
        questionId,
      );
      // verify required parameter 'questionPatch' is not null or undefined
      assertParamExists(
        "patchQuestionQuestionsQuestionIdPatch",
        "questionPatch",
        questionPatch,
      );
      const localVarPath = `/questions/{question_id}/`.replace(
        `{${"question_id"}}`,
        encodeURIComponent(String(questionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        questionPatch,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post Question
     * @param {QuestionCreate} questionCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postQuestionQuestionsPost: async (
      questionCreate: QuestionCreate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionCreate' is not null or undefined
      assertParamExists(
        "postQuestionQuestionsPost",
        "questionCreate",
        questionCreate,
      );
      const localVarPath = `/questions/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        questionCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post Questions List
     * @param {QuestionCreateCollection} questionCreateCollection
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postQuestionsListQuestionsListPost: async (
      questionCreateCollection: QuestionCreateCollection,
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionCreateCollection' is not null or undefined
      assertParamExists(
        "postQuestionsListQuestionsListPost",
        "questionCreateCollection",
        questionCreateCollection,
      );
      const localVarPath = `/questions/list/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        questionCreateCollection,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * QuestionsApi - functional programming interface
 * @export
 */
export const QuestionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    QuestionsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Question
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteQuestionQuestionsQuestionIdDelete(
      questionId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteQuestionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteQuestionQuestionsQuestionIdDelete(
          questionId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "QuestionsApi.deleteQuestionQuestionsQuestionIdDelete"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Question
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuestionQuestionsQuestionIdGet(
      questionId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Question>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getQuestionQuestionsQuestionIdGet(
          questionId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["QuestionsApi.getQuestionQuestionsQuestionIdGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Question Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuestionTypesQuestionsTypesGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getQuestionTypesQuestionsTypesGet(
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["QuestionsApi.getQuestionTypesQuestionsTypesGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Questions
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuestionsQuestionsGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<QuestionCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getQuestionsQuestionsGet(
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["QuestionsApi.getQuestionsQuestionsGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Questions
     * @param {string} targetType
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuestionsQuestionsTypeTargetTypeGet(
      targetType: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<QuestionCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getQuestionsQuestionsTypeTargetTypeGet(
          targetType,
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "QuestionsApi.getQuestionsQuestionsTypeTargetTypeGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Patch Question
     * @param {string} questionId
     * @param {QuestionPatch} questionPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchQuestionQuestionsQuestionIdPatch(
      questionId: string,
      questionPatch: QuestionPatch,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Question>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchQuestionQuestionsQuestionIdPatch(
          questionId,
          questionPatch,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "QuestionsApi.patchQuestionQuestionsQuestionIdPatch"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Post Question
     * @param {QuestionCreate} questionCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postQuestionQuestionsPost(
      questionCreate: QuestionCreate,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Question>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postQuestionQuestionsPost(
          questionCreate,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["QuestionsApi.postQuestionQuestionsPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Post Questions List
     * @param {QuestionCreateCollection} questionCreateCollection
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postQuestionsListQuestionsListPost(
      questionCreateCollection: QuestionCreateCollection,
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<QuestionCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postQuestionsListQuestionsListPost(
          questionCreateCollection,
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["QuestionsApi.postQuestionsListQuestionsListPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * QuestionsApi - factory interface
 * @export
 */
export const QuestionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = QuestionsApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Question
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteQuestionQuestionsQuestionIdDelete(
      questionId: string,
      options?: any,
    ): AxiosPromise<DeleteQuestionResponse> {
      return localVarFp
        .deleteQuestionQuestionsQuestionIdDelete(questionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Question
     * @param {string} questionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionQuestionsQuestionIdGet(
      questionId: string,
      options?: any,
    ): AxiosPromise<Question> {
      return localVarFp
        .getQuestionQuestionsQuestionIdGet(questionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Question Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionTypesQuestionsTypesGet(
      options?: any,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .getQuestionTypesQuestionsTypesGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Questions
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionsQuestionsGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<QuestionCollection> {
      return localVarFp
        .getQuestionsQuestionsGet(pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Questions
     * @param {string} targetType
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestionsQuestionsTypeTargetTypeGet(
      targetType: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<QuestionCollection> {
      return localVarFp
        .getQuestionsQuestionsTypeTargetTypeGet(
          targetType,
          pageToken,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Patch Question
     * @param {string} questionId
     * @param {QuestionPatch} questionPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchQuestionQuestionsQuestionIdPatch(
      questionId: string,
      questionPatch: QuestionPatch,
      options?: any,
    ): AxiosPromise<Question> {
      return localVarFp
        .patchQuestionQuestionsQuestionIdPatch(
          questionId,
          questionPatch,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Post Question
     * @param {QuestionCreate} questionCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postQuestionQuestionsPost(
      questionCreate: QuestionCreate,
      options?: any,
    ): AxiosPromise<Question> {
      return localVarFp
        .postQuestionQuestionsPost(questionCreate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Post Questions List
     * @param {QuestionCreateCollection} questionCreateCollection
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postQuestionsListQuestionsListPost(
      questionCreateCollection: QuestionCreateCollection,
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<QuestionCollection> {
      return localVarFp
        .postQuestionsListQuestionsListPost(
          questionCreateCollection,
          pageToken,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * QuestionsApi - object-oriented interface
 * @export
 * @class QuestionsApi
 * @extends {BaseAPI}
 */
export class QuestionsApi extends BaseAPI {
  /**
   *
   * @summary Delete Question
   * @param {string} questionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionsApi
   */
  public deleteQuestionQuestionsQuestionIdDelete(
    questionId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return QuestionsApiFp(this.configuration)
      .deleteQuestionQuestionsQuestionIdDelete(questionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Question
   * @param {string} questionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionsApi
   */
  public getQuestionQuestionsQuestionIdGet(
    questionId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return QuestionsApiFp(this.configuration)
      .getQuestionQuestionsQuestionIdGet(questionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Question Types
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionsApi
   */
  public getQuestionTypesQuestionsTypesGet(options?: RawAxiosRequestConfig) {
    return QuestionsApiFp(this.configuration)
      .getQuestionTypesQuestionsTypesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Questions
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionsApi
   */
  public getQuestionsQuestionsGet(
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return QuestionsApiFp(this.configuration)
      .getQuestionsQuestionsGet(pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Questions
   * @param {string} targetType
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionsApi
   */
  public getQuestionsQuestionsTypeTargetTypeGet(
    targetType: string,
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return QuestionsApiFp(this.configuration)
      .getQuestionsQuestionsTypeTargetTypeGet(
        targetType,
        pageToken,
        pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch Question
   * @param {string} questionId
   * @param {QuestionPatch} questionPatch
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionsApi
   */
  public patchQuestionQuestionsQuestionIdPatch(
    questionId: string,
    questionPatch: QuestionPatch,
    options?: RawAxiosRequestConfig,
  ) {
    return QuestionsApiFp(this.configuration)
      .patchQuestionQuestionsQuestionIdPatch(questionId, questionPatch, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Post Question
   * @param {QuestionCreate} questionCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionsApi
   */
  public postQuestionQuestionsPost(
    questionCreate: QuestionCreate,
    options?: RawAxiosRequestConfig,
  ) {
    return QuestionsApiFp(this.configuration)
      .postQuestionQuestionsPost(questionCreate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Post Questions List
   * @param {QuestionCreateCollection} questionCreateCollection
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuestionsApi
   */
  public postQuestionsListQuestionsListPost(
    questionCreateCollection: QuestionCreateCollection,
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return QuestionsApiFp(this.configuration)
      .postQuestionsListQuestionsListPost(
        questionCreateCollection,
        pageToken,
        pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * RootApi - axios parameter creator
 * @export
 */
export const RootApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Post Register
     * @param {UserCreate} userCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postRegisterRegisterPost: async (
      userCreate: UserCreate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userCreate' is not null or undefined
      assertParamExists("postRegisterRegisterPost", "userCreate", userCreate);
      const localVarPath = `/register/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Post Token
     * @param {string} username
     * @param {string} password
     * @param {string | null} [grantType]
     * @param {string} [scope]
     * @param {string | null} [clientId]
     * @param {string | null} [clientSecret]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTokenTokenPost: async (
      username: string,
      password: string,
      grantType?: string | null,
      scope?: string,
      clientId?: string | null,
      clientSecret?: string | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists("postTokenTokenPost", "username", username);
      // verify required parameter 'password' is not null or undefined
      assertParamExists("postTokenTokenPost", "password", password);
      const localVarPath = `/token/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new URLSearchParams();

      if (grantType !== undefined) {
        localVarFormParams.set("grant_type", grantType as any);
      }

      if (username !== undefined) {
        localVarFormParams.set("username", username as any);
      }

      if (password !== undefined) {
        localVarFormParams.set("password", password as any);
      }

      if (scope !== undefined) {
        localVarFormParams.set("scope", scope as any);
      }

      if (clientId !== undefined) {
        localVarFormParams.set("client_id", clientId as any);
      }

      if (clientSecret !== undefined) {
        localVarFormParams.set("client_secret", clientSecret as any);
      }

      localVarHeaderParameter["Content-Type"] =
        "application/x-www-form-urlencoded";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams.toString();

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Read Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readRootGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RootApi - functional programming interface
 * @export
 */
export const RootApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RootApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Post Register
     * @param {UserCreate} userCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postRegisterRegisterPost(
      userCreate: UserCreate,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postRegisterRegisterPost(
          userCreate,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["RootApi.postRegisterRegisterPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Post Token
     * @param {string} username
     * @param {string} password
     * @param {string | null} [grantType]
     * @param {string} [scope]
     * @param {string | null} [clientId]
     * @param {string | null} [clientSecret]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTokenTokenPost(
      username: string,
      password: string,
      grantType?: string | null,
      scope?: string,
      clientId?: string | null,
      clientSecret?: string | null,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postTokenTokenPost(
          username,
          password,
          grantType,
          scope,
          clientId,
          clientSecret,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["RootApi.postTokenTokenPost"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Read Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readRootGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.readRootGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["RootApi.readRootGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * RootApi - factory interface
 * @export
 */
export const RootApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RootApiFp(configuration);
  return {
    /**
     *
     * @summary Post Register
     * @param {UserCreate} userCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postRegisterRegisterPost(
      userCreate: UserCreate,
      options?: any,
    ): AxiosPromise<User> {
      return localVarFp
        .postRegisterRegisterPost(userCreate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Post Token
     * @param {string} username
     * @param {string} password
     * @param {string | null} [grantType]
     * @param {string} [scope]
     * @param {string | null} [clientId]
     * @param {string | null} [clientSecret]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTokenTokenPost(
      username: string,
      password: string,
      grantType?: string | null,
      scope?: string,
      clientId?: string | null,
      clientSecret?: string | null,
      options?: any,
    ): AxiosPromise<LoginResponse> {
      return localVarFp
        .postTokenTokenPost(
          username,
          password,
          grantType,
          scope,
          clientId,
          clientSecret,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readRootGet(options?: any): AxiosPromise<any> {
      return localVarFp
        .readRootGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * RootApi - object-oriented interface
 * @export
 * @class RootApi
 * @extends {BaseAPI}
 */
export class RootApi extends BaseAPI {
  /**
   *
   * @summary Post Register
   * @param {UserCreate} userCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootApi
   */
  public postRegisterRegisterPost(
    userCreate: UserCreate,
    options?: RawAxiosRequestConfig,
  ) {
    return RootApiFp(this.configuration)
      .postRegisterRegisterPost(userCreate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Post Token
   * @param {string} username
   * @param {string} password
   * @param {string | null} [grantType]
   * @param {string} [scope]
   * @param {string | null} [clientId]
   * @param {string | null} [clientSecret]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootApi
   */
  public postTokenTokenPost(
    username: string,
    password: string,
    grantType?: string | null,
    scope?: string,
    clientId?: string | null,
    clientSecret?: string | null,
    options?: RawAxiosRequestConfig,
  ) {
    return RootApiFp(this.configuration)
      .postTokenTokenPost(
        username,
        password,
        grantType,
        scope,
        clientId,
        clientSecret,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read Root
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RootApi
   */
  public readRootGet(options?: RawAxiosRequestConfig) {
    return RootApiFp(this.configuration)
      .readRootGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * StatusesApi - axios parameter creator
 * @export
 */
export const StatusesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get Status Status Category
     * @param {string} targetCategory
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatusStatusCategoryStatusStatusCategoryTargetCategoryGet: async (
      targetCategory: string,
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'targetCategory' is not null or undefined
      assertParamExists(
        "getStatusStatusCategoryStatusStatusCategoryTargetCategoryGet",
        "targetCategory",
        targetCategory,
      );
      const localVarPath = `/status/status_category/{target_category}/`.replace(
        `{${"target_category"}}`,
        encodeURIComponent(String(targetCategory)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Status
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatusStatusGet: async (
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/status/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StatusesApi - functional programming interface
 * @export
 */
export const StatusesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = StatusesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Status Status Category
     * @param {string} targetCategory
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStatusStatusCategoryStatusStatusCategoryTargetCategoryGet(
      targetCategory: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StatusCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStatusStatusCategoryStatusStatusCategoryTargetCategoryGet(
          targetCategory,
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "StatusesApi.getStatusStatusCategoryStatusStatusCategoryTargetCategoryGet"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Status
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStatusStatusGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StatusCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStatusStatusGet(
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["StatusesApi.getStatusStatusGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * StatusesApi - factory interface
 * @export
 */
export const StatusesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = StatusesApiFp(configuration);
  return {
    /**
     *
     * @summary Get Status Status Category
     * @param {string} targetCategory
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatusStatusCategoryStatusStatusCategoryTargetCategoryGet(
      targetCategory: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<StatusCollection> {
      return localVarFp
        .getStatusStatusCategoryStatusStatusCategoryTargetCategoryGet(
          targetCategory,
          pageToken,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Status
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStatusStatusGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<StatusCollection> {
      return localVarFp
        .getStatusStatusGet(pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * StatusesApi - object-oriented interface
 * @export
 * @class StatusesApi
 * @extends {BaseAPI}
 */
export class StatusesApi extends BaseAPI {
  /**
   *
   * @summary Get Status Status Category
   * @param {string} targetCategory
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatusesApi
   */
  public getStatusStatusCategoryStatusStatusCategoryTargetCategoryGet(
    targetCategory: string,
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return StatusesApiFp(this.configuration)
      .getStatusStatusCategoryStatusStatusCategoryTargetCategoryGet(
        targetCategory,
        pageToken,
        pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Status
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatusesApi
   */
  public getStatusStatusGet(
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return StatusesApiFp(this.configuration)
      .getStatusStatusGet(pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserUserGet: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/user/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get User User Id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserUserIdUserUserIdGet: async (
      userId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("getUserUserIdUserUserIdGet", "userId", userId);
      const localVarPath = `/user/{user_id}/`.replace(
        `{${"user_id"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Users Role
     * @param {string} targetRole
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersRoleUsersRoleTargetRoleGet: async (
      targetRole: string,
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'targetRole' is not null or undefined
      assertParamExists(
        "getUsersRoleUsersRoleTargetRoleGet",
        "targetRole",
        targetRole,
      );
      const localVarPath = `/users/role/{target_role}/`.replace(
        `{${"target_role"}}`,
        encodeURIComponent(String(targetRole)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Users
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersUsersGet: async (
      pageToken?: string | null,
      pageSize?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/users/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      if (pageToken !== undefined) {
        localVarQueryParameter["page_token"] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["page_size"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch Reset Password
     * @param {ResetPasswordRequest} resetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchResetPasswordResetPasswordPatch: async (
      resetPasswordRequest: ResetPasswordRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'resetPasswordRequest' is not null or undefined
      assertParamExists(
        "patchResetPasswordResetPasswordPatch",
        "resetPasswordRequest",
        resetPasswordRequest,
      );
      const localVarPath = `/reset_password/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resetPasswordRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch Users User Id Reset Password
     * @param {string} userId
     * @param {ResetPasswordRequest} resetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchUsersUserIdResetPasswordUsersUserIdResetPasswordPatch: async (
      userId: string,
      resetPasswordRequest: ResetPasswordRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists(
        "patchUsersUserIdResetPasswordUsersUserIdResetPasswordPatch",
        "userId",
        userId,
      );
      // verify required parameter 'resetPasswordRequest' is not null or undefined
      assertParamExists(
        "patchUsersUserIdResetPasswordUsersUserIdResetPasswordPatch",
        "resetPasswordRequest",
        resetPasswordRequest,
      );
      const localVarPath = `/users/{user_id}/reset_password/`.replace(
        `{${"user_id"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resetPasswordRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch Users User Id
     * @param {string} userId
     * @param {UserPatch} userPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchUsersUserIdUsersUserIdPatch: async (
      userId: string,
      userPatch: UserPatch,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("patchUsersUserIdUsersUserIdPatch", "userId", userId);
      // verify required parameter 'userPatch' is not null or undefined
      assertParamExists(
        "patchUsersUserIdUsersUserIdPatch",
        "userPatch",
        userPatch,
      );
      const localVarPath = `/users/{user_id}/`.replace(
        `{${"user_id"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication _Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userPatch,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserUserGet(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserUserGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UsersApi.getUserUserGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get User User Id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserUserIdUserUserIdGet(
      userId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserUserIdUserUserIdGet(
          userId,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UsersApi.getUserUserIdUserUserIdGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Users Role
     * @param {string} targetRole
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsersRoleUsersRoleTargetRoleGet(
      targetRole: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserCollections>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUsersRoleUsersRoleTargetRoleGet(
          targetRole,
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UsersApi.getUsersRoleUsersRoleTargetRoleGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Users
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsersUsersGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserCollections>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUsersUsersGet(
          pageToken,
          pageSize,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UsersApi.getUsersUsersGet"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Patch Reset Password
     * @param {ResetPasswordRequest} resetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchResetPasswordResetPasswordPatch(
      resetPasswordRequest: ResetPasswordRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResetPasswordResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchResetPasswordResetPasswordPatch(
          resetPasswordRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UsersApi.patchResetPasswordResetPasswordPatch"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Patch Users User Id Reset Password
     * @param {string} userId
     * @param {ResetPasswordRequest} resetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchUsersUserIdResetPasswordUsersUserIdResetPasswordPatch(
      userId: string,
      resetPasswordRequest: ResetPasswordRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ResetPasswordResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchUsersUserIdResetPasswordUsersUserIdResetPasswordPatch(
          userId,
          resetPasswordRequest,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          "UsersApi.patchUsersUserIdResetPasswordUsersUserIdResetPasswordPatch"
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Patch Users User Id
     * @param {string} userId
     * @param {UserPatch} userPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchUsersUserIdUsersUserIdPatch(
      userId: string,
      userPatch: UserPatch,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patchUsersUserIdUsersUserIdPatch(
          userId,
          userPatch,
          options,
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["UsersApi.patchUsersUserIdUsersUserIdPatch"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     *
     * @summary Get User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserUserGet(options?: any): AxiosPromise<User> {
      return localVarFp
        .getUserUserGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get User User Id
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserUserIdUserUserIdGet(
      userId: string,
      options?: any,
    ): AxiosPromise<User> {
      return localVarFp
        .getUserUserIdUserUserIdGet(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Users Role
     * @param {string} targetRole
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersRoleUsersRoleTargetRoleGet(
      targetRole: string,
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<UserCollections> {
      return localVarFp
        .getUsersRoleUsersRoleTargetRoleGet(
          targetRole,
          pageToken,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Users
     * @param {string | null} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersUsersGet(
      pageToken?: string | null,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<UserCollections> {
      return localVarFp
        .getUsersUsersGet(pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Patch Reset Password
     * @param {ResetPasswordRequest} resetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchResetPasswordResetPasswordPatch(
      resetPasswordRequest: ResetPasswordRequest,
      options?: any,
    ): AxiosPromise<ResetPasswordResponse> {
      return localVarFp
        .patchResetPasswordResetPasswordPatch(resetPasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Patch Users User Id Reset Password
     * @param {string} userId
     * @param {ResetPasswordRequest} resetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchUsersUserIdResetPasswordUsersUserIdResetPasswordPatch(
      userId: string,
      resetPasswordRequest: ResetPasswordRequest,
      options?: any,
    ): AxiosPromise<ResetPasswordResponse> {
      return localVarFp
        .patchUsersUserIdResetPasswordUsersUserIdResetPasswordPatch(
          userId,
          resetPasswordRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Patch Users User Id
     * @param {string} userId
     * @param {UserPatch} userPatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchUsersUserIdUsersUserIdPatch(
      userId: string,
      userPatch: UserPatch,
      options?: any,
    ): AxiosPromise<User> {
      return localVarFp
        .patchUsersUserIdUsersUserIdPatch(userId, userPatch, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @summary Get User
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUserUserGet(options?: RawAxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .getUserUserGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get User User Id
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUserUserIdUserUserIdGet(
    userId: string,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .getUserUserIdUserUserIdGet(userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Users Role
   * @param {string} targetRole
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUsersRoleUsersRoleTargetRoleGet(
    targetRole: string,
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .getUsersRoleUsersRoleTargetRoleGet(
        targetRole,
        pageToken,
        pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Users
   * @param {string | null} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUsersUsersGet(
    pageToken?: string | null,
    pageSize?: number,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .getUsersUsersGet(pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch Reset Password
   * @param {ResetPasswordRequest} resetPasswordRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public patchResetPasswordResetPasswordPatch(
    resetPasswordRequest: ResetPasswordRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .patchResetPasswordResetPasswordPatch(resetPasswordRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch Users User Id Reset Password
   * @param {string} userId
   * @param {ResetPasswordRequest} resetPasswordRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public patchUsersUserIdResetPasswordUsersUserIdResetPasswordPatch(
    userId: string,
    resetPasswordRequest: ResetPasswordRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .patchUsersUserIdResetPasswordUsersUserIdResetPasswordPatch(
        userId,
        resetPasswordRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch Users User Id
   * @param {string} userId
   * @param {UserPatch} userPatch
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public patchUsersUserIdUsersUserIdPatch(
    userId: string,
    userPatch: UserPatch,
    options?: RawAxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .patchUsersUserIdUsersUserIdPatch(userId, userPatch, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * RequestsApi - axios parameter creator
 * @export
 */
export const RequestsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Post Note Request
     * @param {NoteRequestCreate} noteRequestCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postNoteRequest: async (
      noteRequestCreate: NoteRequestCreate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteRequestCreate' is not null or undefined
      assertParamExists(
        "postNoteRequest",
        "noteRequestCreate",
        noteRequestCreate,
      );
      const localVarPath = `/requests/notes/post_note_request`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        noteRequestCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },

    /**
     *
     * @summary Get Note Requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNoteRequests: async (
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/requests/notes/get_note_requests`;
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },

    /**
     *
     * @summary Approve Or Reject Note Request
     * @param {string} noteRequestId
     * @param {string} action Either "approve" or "reject"
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveOrRejectNoteRequest: async (
      noteRequestId: string,
      action: "approve" | "reject" | "pending",
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestId' is not null or undefined
      assertParamExists(
        "approveOrRejectNoteRequest",
        "noteRequestId",
        noteRequestId,
      );
      assertParamExists("approveOrRejectNoteRequest", "action", action);

      const localVarPath =
        `/requests/notes/approve_or_reject_note_request/{note_request_id}/`.replace(
          `{${"note_request_id"}}`,
          encodeURIComponent(String(noteRequestId)),
        );
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);

      const localVarQueryParameter = {
        action: action,
      };

      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;

      await setOAuthToObject(
        localVarHeaderParameter,
        "_Bearer",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        { note_request_id: noteRequestId },
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RequestsApi - functional programming interface
 * @export
 */
export const RequestsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RequestsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Post Note Request
     * @param {NoteRequestCreate} noteRequestCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNoteRequest(
      noteRequestCreate: NoteRequestCreate,
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteRequest>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postNoteRequest(
        noteRequestCreate,
        options,
      );
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, basePath);
    },

    /**
     *
     * @summary Get Note Requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNoteRequests(
      options?: RawAxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NoteRequestCollection>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNoteRequests(options);
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, basePath);
    },

    /**
     *
     * @summary Approve Or Reject Note Request
     * @param {string} requestId
     * @param {string} action Either "approve" or "reject"
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveOrRejectNoteRequest(
      requestId: string,
      action: "approve" | "reject" | "pending",
      options?: RawAxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteRequest>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.approveOrRejectNoteRequest(
          requestId,
          action,
          options,
        );
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, basePath);
    },
  };
};

/**
 * RequestsApi - factory interface
 * @export
 */
export const RequestsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RequestsApiFp(configuration);
  return {
    /**
     *
     * @summary Post Note Request
     * @param {NoteRequestCreate} noteRequestCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postNoteRequest(
      noteRequestCreate: NoteRequestCreate,
      options?: any,
    ): AxiosPromise<NoteRequest> {
      return localVarFp
        .postNoteRequest(noteRequestCreate, options)
        .then((request) => request(axios, basePath));
    },

    /**
     *
     * @summary Get Note Requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNoteRequests(options?: any): AxiosPromise<NoteRequestCollection> {
      return localVarFp
        .getNoteRequests(options)
        .then((request) => request(axios, basePath));
    },

    /**
     *
     * @summary Approve Or Reject Note Request
     * @param {string} requestId
     * @param {string} action Either "approve" or "reject"
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveOrRejectNoteRequest(
      requestId: string,
      action: "approve" | "reject" | "pending",
      options?: any,
    ): AxiosPromise<NoteRequest> {
      return localVarFp
        .approveOrRejectNoteRequest(requestId, action, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * RequestsApi - object-oriented interface
 * @export
 * @class RequestsApi
 * @extends {BaseAPI}
 */
export class RequestsApi extends BaseAPI {
  /**
   *
   * @summary Post Note Request
   * @param {NoteRequestCreate} noteRequestCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RequestsApi
   */
  public postNoteRequest(
    noteRequestCreate: NoteRequestCreate,
    options?: RawAxiosRequestConfig,
  ) {
    return RequestsApiFp(this.configuration)
      .postNoteRequest(noteRequestCreate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Note Requests
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RequestsApi
   */
  public getNoteRequests(options?: RawAxiosRequestConfig) {
    return RequestsApiFp(this.configuration)
      .getNoteRequests(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Approve Or Reject Note Request
   * @param {string} requestId
   * @param {string} action Either "approve" or "reject"
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RequestsApi
   */
  public approveOrRejectNoteRequest(
    requestId: string,
    action: "approve" | "reject" | "pending",
    options?: RawAxiosRequestConfig,
  ) {
    return RequestsApiFp(this.configuration)
      .approveOrRejectNoteRequest(requestId, action, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
