import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

interface ManagementHeaderProps {
  userType: "admin" | "client" | "staff";
  onCreateClick: () => void;
}

const ManagementHeader: React.FC<ManagementHeaderProps> = ({ userType, onCreateClick }) => {
  const getTitle = () => {
    switch (userType) {
      case "admin":
        return "Admin Management";
      case "client":
        return "Client Management";
      case "staff":
        return "Staff Management";
      default:
        return "Management";
    }
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: "1rem" }}>
      <Typography variant="h5">{getTitle()}</Typography>
      <IconButton color="primary" onClick={onCreateClick}>
        <PersonAddIcon />
      </IconButton>
    </Box>
  );
};

export default ManagementHeader; 