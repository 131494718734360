import * as React from "react";
import BaseFormDialog from "./BaseFormDialog";
import FormField from "./FormField";
import { User, UserCreate } from "../../api/api";
import api from "../api";

interface AddFormDialogProps {
  open: boolean;
  handleClose: () => void;
  userType: "client" | "staff" | "admin";
  addUser: (user: User) => void;
}

const AddFormDialog: React.FC<AddFormDialogProps> = ({
  open,
  handleClose,
  userType,
  addUser,
}) => {
  const [formData, setFormData] = React.useState<UserCreate>({
    first_name: "",
    last_name: "",
    role: userType,
    user_email: "",
    phone_number: "",
    password: "",
    wechat_account: "",
    extras: {
      address: "",
      profession: undefined,
    }
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const response = await api.root.postRegisterRegisterPost(formData);
    addUser({
      ...response.data,
      name: `${response.data.first_name} ${response.data.last_name}`,
    });
  };

  return (
    <BaseFormDialog
      open={open}
      title={`Add ${userType.charAt(0).toUpperCase() + userType.slice(1)}`}
      description={`Please enter the necessary information to add a ${userType}`}
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitButtonText="Add"
    >
      <FormField
        type="text"
        name="first_name"
        label="First Name"
        value={formData.first_name}
        onChange={(value) => setFormData({ ...formData, first_name: value })}
        required
      />
    </BaseFormDialog>
  );
};

export default AddFormDialog;
