import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import api from "../components/api";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate, useParams } from "react-router-dom";

interface StatusData {
  self_link: string;
  kind: string;
  status_id: string;
  status_rank: number;
  status_category: string;
  status: string;
  deadline?: number | string | null;
  receiver: any[];
}

const StatusCategories: React.FC = () => {
  const [rows, setRows] = useState<StatusData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [value, setValue] = useState("order");
  const [matchedRules, setMatchedRules] = useState<any[]>([]);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    getRows(newValue);
  };

  const getRows = async (target_category: string) => {
    try {
      setError(null);
      const response =
        await api.status.getStatusStatusCategoryStatusStatusCategoryTargetCategoryGet(
          target_category,
        );
      const statusContents = response.data.contents;

      const formattedData = statusContents.map((status: any) => ({
        self_link: status.self_link,
        kind: status.kind,
        status_id: status.status_id,
        status_rank: status.status_rank,
        status_category: status.status_category,
        status: status.status,
        deadline: status.deadline?.toString() || "N/A",
        receiver: status.receiver.join(", ") || "N/A",
      }));

      setRows(formattedData);
    } catch (err) {
      setError("Failed to fetch statuses");
    }
  };

  const handleRowClick = async (status_id: string) => {
    navigate(`/notification_rules/${status_id}`);
    const resp =
      await api.notificationRules.getNotificationRulesNotificationRulesGet();
    const matchedData = resp.data.contents.filter(
      (rule: any) => rule.status_id === status_id,
    );
    setMatchedRules(matchedData);
  };

  React.useEffect(() => {
    getRows(value);
  }, [value]);

  const columnConfig = [
    { id: "status_id", label: "Status ID", minWidth: 100 },
    { id: "status_rank", label: "Rank", minWidth: 50 },
    { id: "status_category", label: "Category", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "deadline", label: "Deadline", minWidth: 170 },
    { id: "receiver", label: "Receiver", minWidth: 100 },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "1rem" }}
      >
        <Typography variant="h5">Status Categories</Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="status categories tabs"
        >
          <Tab value="order" label="Order" />
          <Tab value="post_production" label="Post Production" />
          <Tab value="logistics" label="Logistics" />
          <Tab value="wedding_production" label="Wedding Production" />
        </Tabs>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {error && <Typography color="error">{error}</Typography>}

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {columnConfig.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length > 0 ? (
                  rows.map((row) => (
                    <TableRow
                      key={row.status_id}
                      hover
                      onClick={() => handleRowClick(row.status_id)}
                    >
                      <TableCell>{row.status_id}</TableCell>
                      <TableCell>{row.status_rank}</TableCell>
                      <TableCell>{row.status_category}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>{row.deadline}</TableCell>
                      <TableCell>{row.receiver}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No statuses available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatusCategories;
