import * as React from "react";
import BaseFormDialog from "./BaseFormDialog";
import { OrderItem } from "../../api/api";
import api from "../api";
import DeleteFormDialog from "./DeleteFormDialog";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import GenericTable from "../Table/Table";

interface OrderItemFormDialogProps {
  open: boolean;
  handleClose: () => void;
}

const OrderItemFormDialog: React.FC<OrderItemFormDialogProps> = ({
  open,
  handleClose,
}) => {
  const [orderItems, setOrderItems] = React.useState<OrderItem[]>([]);
  const [deleteDialog, setDeleteDialog] = React.useState({
    open: false,
    itemId: "",
  });

  React.useEffect(() => {
    fetchOrderItems();
  }, []);

  const fetchOrderItems = async () => {
    try {
      const response = await api.orderItems.getOrderItemsOrderItemsGet();
      const items = response.data.contents;
      setOrderItems(items);
      return items;
    } catch (error) {
      console.error("Error fetching order items:", error);
      return [];
    }
  };

  const handleDeleteClick = (itemId: string) => {
    setDeleteDialog({
      open: true,
      itemId,
    });
  };

  const handleDeleteComplete = (deletedId: string) => {
    setOrderItems(prevItems => 
      prevItems.filter(item => item.order_item_id !== deletedId)
    );
    setDeleteDialog({ open: false, itemId: "" });
  };

  const columnConfig = [
    { id: "description", label: "Description", minWidth: 100 },
    { id: "price", label: "Price", minWidth: 100 },
    { id: "actions", label: "Actions", minWidth: 100, align: "center" },
  ];

  const getActionsButton = (row: OrderItem) => (
    <IconButton
      aria-label="delete"
      size="small"
      onClick={() => handleDeleteClick(row.order_item_id)}
    >
      <DeleteIcon />
    </IconButton>
  );

  return (
    <>
      <BaseFormDialog
        open={open}
        title="Order Items"
        description="Please select the order items to add:"
        onClose={handleClose}
        onSubmit={async () => {}}
        submitButtonText="Close"
        maxWidth="md"
      >
        <GenericTable
          columnsConfig={columnConfig}
          tableType="order_item"
          rows={orderItems}
          getRows={fetchOrderItems}
          setRows={setOrderItems}
          getActionsButton={getActionsButton}
        />
      </BaseFormDialog>

      <DeleteFormDialog
        open={deleteDialog.open}
        handleClose={() => setDeleteDialog({ open: false, itemId: "" })}
        itemType="order_item"
        itemId={deleteDialog.itemId}
        onDelete={handleDeleteComplete}
      />
    </>
  );
};

export default OrderItemFormDialog;
