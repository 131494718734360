import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { AxiosResponse } from "axios";
import {
  Order,
  OrderPatch,
  Status,
  OrderType,
  OrderOrderItem,
  OrderStatusPatch,
} from "../../api/api";
import api from "../api";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import moment from "moment/moment";
import { config } from "../../components/api";

interface EditOrderFormDialogProps {
  open: boolean;
  handleClose: () => void;
  row: any;
  onSave: (order: any) => void;
  onRefresh: () => void;
  onDateChange: (newDate: Dayjs) => void;
}

const EditOrderFormDialog: React.FC<EditOrderFormDialogProps> = ({
  open,
  handleClose,
  row,
  onSave,
  onRefresh,
  onDateChange,
}) => {
  const [orderPatch, setOrderPatch] = React.useState<OrderPatch>({
    type: row.type,
    date: row.date ? dayjs(row.date).format("YYYY-MM-DD") : null,
    public_note: row.public_note,
    private_note: row.private_note,
    extras: row.extras,
  });
  const [createdBy, setCreatedBy] = React.useState<string>("");

  React.useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await api.users.getUserUserGet();
        setCreatedBy(response.data.user_id);
      } catch (error) {
        console.error("Error fetching user ID:", error);
      }
    };

    fetchUserId();
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setOrderPatch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setOrderPatch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (date: Dayjs | null) => {
    setOrderPatch((prevState) => ({
      ...prevState,
      date: date ? date.format("YYYY-MM-DD") : null,
    }));
  };

  const handleExtrasChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setOrderPatch((prevState) => ({
      ...prevState,
      extras: {
        ...prevState.extras,
        [name]: value,
      },
    }));
  };

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      if (config.role === "staff" || config.role === "client") {
        const response: AxiosResponse =
          await api.orders.getOrderOrdersOrderIdGet(row.order_id);
        const previousNote = response.data.public_note;

        await api.requests.postNoteRequest({
          order_id: row.order_id,
          previous_note: previousNote,
          note: orderPatch.public_note,
          status: "pending",
          created_by: createdBy,
        });
      } else {
        const response: AxiosResponse =
          await api.orders.patchOrderOrderIdOrdersOrderIdPatch(
            row.order_id,
            orderPatch,
          );

        const orderDate = moment(response.data.date).format(
          "YYYY/MM/DD HH:mm:ss",
        );

        const users = response.data.users || [];
        let userNames: string;

        if (users.length === 1) {
          const user = users[0];
          userNames = `${user.first_name} ${user.last_name}`;
        } else {
          userNames = users
            .map((user: { first_name: string }) => user.first_name)
            .join(" & ");
        }

        const data = {
          ...response.data,
          name: userNames,
          date: orderDate,
          order_order_items: row.order_order_items || [],
        };

        onSave(data);

        onDateChange(dayjs(response.data.date));
      }

      handleClose();
      setTimeout(() => {
        onRefresh();
      }, 0);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmitForm,
      }}
    >
      <DialogTitle>Edit Order</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please update the necessary information:
        </DialogContentText>

        {(config.role === "admin" || config.role === "manager") && (
          <>
            <FormControl fullWidth margin="dense">
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                labelId="type-label"
                id="type"
                name="type"
                value={orderPatch.type || ""}
                onChange={handleSelectChange}
                label="Type"
              >
                {Object.values(OrderType).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type.replace(/_/g, " ").toLowerCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box mt={1}>
                <DatePicker
                  label="Order Date"
                  value={orderPatch.date ? dayjs(orderPatch.date) : null}
                  onChange={handleDateChange}
                  format="YYYY/MM/DD"
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </Box>
            </LocalizationProvider>
          </>
        )}

        <TextField
          margin="dense"
          id="public_note"
          name="public_note"
          label="Public Note"
          type="text"
          fullWidth
          value={orderPatch.public_note || ""}
          onChange={handleInputChange}
        />

        {(config.role === "admin" || config.role === "manager") && (
          <>
            <TextField
              margin="dense"
              id="private_note"
              name="private_note"
              label="Private Note"
              type="text"
              fullWidth
              value={orderPatch.private_note || ""}
              onChange={handleInputChange}
            />

            <TextField
              margin="dense"
              id="hotel"
              name="hotel"
              label="Hotel"
              type="text"
              fullWidth
              value={orderPatch.extras?.hotel || ""}
              onChange={handleExtrasChange}
            />

            <TextField
              margin="dense"
              id="required_staff_count"
              name="required_staff_count"
              label="Required Staff Count"
              type="number"
              fullWidth
              value={orderPatch.extras?.required_staff_count || ""}
              onChange={handleExtrasChange}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          type="submit"
          sx={{
            backgroundColor: "#2196f3",
            color: "white",
            "&:hover": { backgroundColor: "#64b5f6" },
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditOrderFormDialog;
