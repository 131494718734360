import * as React from "react";
import BaseFormDialog from "./BaseFormDialog";
import api from "../api";

interface DeleteFormDialogProps {
  open: boolean;
  handleClose: () => void;
  itemType: 'order' | 'order_item' | 'order_order_item' | 'question' | 'order_staff';
  itemId: string;
  onDelete: (id: string) => void;
  onEdit?: (data: any) => void;
}

const DeleteFormDialog: React.FC<DeleteFormDialogProps> = ({
  open,
  handleClose,
  itemType,
  itemId,
  onDelete,
  onEdit,
}) => {
  const handleSubmit = async () => {
    switch (itemType) {
      case 'order':
        await api.orders.deleteOrderOrdersOrderIdDelete(itemId);
        break;
      case 'order_item':
        await api.orderItems.deleteOrderItemOrderItemsOrderItemIdDelete(itemId);
        break;
      case 'order_order_item':
        await api.orderOrderItems.deleteOrderOrderItemOrderOrderItemsOrderOrderItemIdDelete(itemId);
        break;
      case 'question':
        await api.questions.deleteQuestionQuestionsQuestionIdDelete(itemId);
        break;
      case 'order_staff':
        await api.orderStaffs.deleteOrderStaffOrderStaffsOrderStaffIdDelete(itemId);
        break;
    }
    onDelete(itemId);
  };

  return (
    <BaseFormDialog
      open={open}
      title="Confirm Delete"
      description="Are you sure you want to delete this item?"
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitButtonText="Delete"
      cancelButtonText="Cancel"
    >
      {null}
    </BaseFormDialog>
  );
};

export default DeleteFormDialog;
