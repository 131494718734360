import * as React from "react";
import AddFormDialog from "../../components/FormDialog/AddFormDialog";
import EditFormDialog from "../../components/FormDialog/EditFormDialog";
import { User } from "../../api/api";

interface DialogsContainerProps {
  userType: "admin" | "client" | "staff";
  openCreateDialog: boolean;
  openEditDialog: boolean;
  userToEdit: User | null;
  onCloseCreate: () => void;
  onCloseEdit: () => void;
  onSave: () => void;
}

export const DialogsContainer: React.FC<DialogsContainerProps> = ({
  userType,
  openCreateDialog,
  openEditDialog,
  userToEdit,
  onCloseCreate,
  onCloseEdit,
  onSave,
}) => {
  return (
    <>
      <AddFormDialog
        open={openCreateDialog}
        handleClose={onCloseCreate}
        userType={userType}
        addUser={onSave}
      />
      {userToEdit && (
        <EditFormDialog
          open={openEditDialog}
          handleClose={onCloseEdit}
          user={userToEdit}
          onSave={onSave}
        />
      )}
    </>
  );
}; 