import * as React from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/material";

interface FormFieldProps {
  type: 'text' | 'number' | 'select' | 'date' | 'autocomplete';
  name: string;
  label: string;
  value: any;
  onChange: (value: any) => void;
  required?: boolean;
  options?: Array<{ value: string; label: string }>;
  multiple?: boolean;
  fullWidth?: boolean;
  variant?: "standard" | "filled" | "outlined";
  error?: boolean;
  helperText?: string;
}

const FormField: React.FC<FormFieldProps> = ({
  type,
  name,
  label,
  value,
  onChange,
  required = false,
  options = [],
  multiple = false,
  fullWidth = true,
  variant = "standard",
  error = false,
  helperText,
}) => {
  switch (type) {
    case 'select':
      return (
        <FormControl fullWidth={fullWidth} margin="dense" required={required} error={error}>
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <Select
            labelId={`${name}-label`}
            id={name}
            name={name}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            label={label}
            multiple={multiple}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );

    case 'date':
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ mt: 1 }}>
            <DatePicker
              label={label}
              value={value}
              onChange={onChange}
              slotProps={{ textField: { fullWidth, required, error, helperText } }}
            />
          </Box>
        </LocalizationProvider>
      );

    case 'autocomplete':
      return (
        <Autocomplete
          value={value}
          onChange={(_, newValue) => onChange(newValue)}
          options={options.map(opt => opt.value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              required={required}
              error={error}
              helperText={helperText}
              variant={variant}
              fullWidth={fullWidth}
            />
          )}
        />
      );

    default:
      return (
        <TextField
          id={name}
          name={name}
          label={label}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          type={type}
          required={required}
          fullWidth={fullWidth}
          variant={variant}
          error={error}
          helperText={helperText}
        />
      );
  }
};

export default FormField;