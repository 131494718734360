import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../api/api';

interface ManagementState {
  users: User[];
  filteredUsers: User[];
  searchKeyword: string;
  selectedUser: User | null;
  userToEdit: User | null;
  openCreateDialog: boolean;
  openEditDialog: boolean;
  calendarOpen: boolean;
}

const initialState: ManagementState = {
  users: [],
  filteredUsers: [],
  searchKeyword: '',
  selectedUser: null,
  userToEdit: null,
  openCreateDialog: false,
  openEditDialog: false,
  calendarOpen: false
};

const managementSlice = createSlice({
  name: 'management',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload;
      state.filteredUsers = action.payload;
    },
    setFilteredUsers: (state, action: PayloadAction<User[]>) => {
      state.filteredUsers = action.payload;
    },
    setSearchKeyword: (state, action: PayloadAction<string>) => {
      state.searchKeyword = action.payload;
    },
    setSelectedUser: (state, action: PayloadAction<User | null>) => {
      state.selectedUser = action.payload;
    },
    setUserToEdit: (state, action: PayloadAction<User | null>) => {
      state.userToEdit = action.payload;
    },
    setOpenCreateDialog: (state, action: PayloadAction<boolean>) => {
      state.openCreateDialog = action.payload;
    },
    setOpenEditDialog: (state, action: PayloadAction<boolean>) => {
      state.openEditDialog = action.payload;
    },
    setCalendarOpen: (state, action: PayloadAction<boolean>) => {
      state.calendarOpen = action.payload;
    }
  }
});

export const {
  setUsers,
  setFilteredUsers,
  setSearchKeyword,
  setSelectedUser,
  setUserToEdit,
  setOpenCreateDialog,
  setOpenEditDialog,
  setCalendarOpen
} = managementSlice.actions;

export default managementSlice.reducer;