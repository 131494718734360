import React, { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import api from "../components/api";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios, { AxiosError } from "axios";
import Typography from "@mui/material/Typography";

const ChangePasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const handlePasswordChange = async () => {
    if (newPassword !== confirmNewPassword) {
      alert("New password does not match");
      return;
    }

    try {
      await api.users.patchResetPasswordResetPasswordPatch({
        old_password: oldPassword,
        new_password: newPassword,
      });
      alert("Password changed successfully");
      navigate("/");
    } catch (error) {
      console.error("Password change error:", error);

      if (axios.isAxiosError(error)) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          alert(error.response.data.detail);
        } else {
          alert("An error occurred");
        }
      } else {
        alert("An unexpected error occurred");
      }
    }
  };

  return (
    <div>
      <Typography variant="h5">Change Password</Typography>
      <TextField
        margin="dense"
        id="old_password"
        name="old_password"
        label="Old Password"
        type="password"
        fullWidth
        variant="standard"
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
      />
      <TextField
        margin="dense"
        id="new_password"
        name="new_password"
        label="New Password"
        type="password"
        fullWidth
        variant="standard"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <TextField
        margin="dense"
        id="confirm_new_password"
        name="confirm_new_password"
        label="Confirm New Password"
        type="password"
        fullWidth
        variant="standard"
        value={confirmNewPassword}
        onChange={(e) => setConfirmNewPassword(e.target.value)}
      />
      <Button
        onClick={() => navigate("/")}
        sx={{
          backgroundColor: "#f44336",
          color: "white",
          "&:hover": { backgroundColor: "#e57373" },
          marginRight: "10px",
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={handlePasswordChange}
        sx={{
          backgroundColor: "#2196f3",
          color: "white",
          "&:hover": { backgroundColor: "#64b5f6" },
        }}
      >
        Change Password
      </Button>
    </div>
  );
};

export default ChangePasswordPage;
