import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Calendar from "../../components/Calendar/Calendar";
import { User } from "../../api/api";
import { Dayjs } from "dayjs";
import api from "../../components/api";

interface ManagementCalendarProps {
  user: User;
  open: boolean;
  selectedDate: Dayjs | null;
  highlightedDays: Dayjs[];
  orderStaffs: Map<string, any>;
  onClose: () => void;
  onDateChange: (date: Dayjs | null) => void;
  onHighlightedDaysChange: (days: Dayjs[]) => void;
  onOrderStaffsChange: (orderStaffs: Map<string, any>) => void;
}

const DrawerWidth = 400;

const ManagementCalendar: React.FC<ManagementCalendarProps> = ({
  user,
  open,
  selectedDate,
  highlightedDays,
  orderStaffs,
  onClose,
  onDateChange,
  onHighlightedDaysChange,
  onOrderStaffsChange,
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [orderStaffToDelete, setOrderStaffToDelete] = React.useState<any>(null);

  const handleUnavailableClick = async () => {
    if (!selectedDate) return;

    try {
      const response = await api.orderStaffs.postOrderStaffOrderStaffsPost({
        date: selectedDate.format("YYYY-MM-DD"),
        order_id: null,
        user_id: user.user_id,
      });

      onHighlightedDaysChange([...highlightedDays, selectedDate]);
      onOrderStaffsChange(new Map(orderStaffs.set(
        selectedDate.format("YYYY-MM-DD"),
        response.data
      )));
      onDateChange(null);
    } catch (error) {
      console.error("Error creating OrderStaff:", error);
    }
  };

  const handleAvailableClick = async () => {
    if (!selectedDate) return;

    const orderStaff = orderStaffs.get(selectedDate.format("YYYY-MM-DD"));
    if (!orderStaff) return;

    if (Object.keys(orderStaff.order).length !== 0) {
      setOrderStaffToDelete(orderStaff);
      setOpenConfirmDialog(true);
    } else {
      await deleteOrderStaff(orderStaff);
    }
  };

  const deleteOrderStaff = async (orderStaff: any) => {
    try {
      await api.orderStaffs.deleteOrderStaffOrderStaffsOrderStaffIdDelete(
        orderStaff.order_staff_id
      );
      onHighlightedDaysChange(
        highlightedDays.filter(day => !day.isSame(selectedDate, "day"))
      );
      const newOrderStaffs = new Map(orderStaffs);
      if (selectedDate) {
        newOrderStaffs.delete(selectedDate.format("YYYY-MM-DD"));
      }
      onOrderStaffsChange(newOrderStaffs);
    } catch (error) {
      console.error("Error removing OrderStaff:", error);
    }
  };

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={open}
      PaperProps={{ sx: { width: DrawerWidth, padding: 2 } }}
    >
      <Box sx={{ paddingTop: 8 }}>
        <Box display="flex" alignItems="center">
          <IconButton onClick={onClose} color="default">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ marginLeft: 2 }}>
            Schedule of {user.first_name} {user.last_name}
          </Typography>
        </Box>
        <Box>
          <Calendar
            user={user}
            handleDateChange={onDateChange}
            highlightedDays={highlightedDays}
            setHighlightedDays={onHighlightedDaysChange as any}
            orderStaffToDelete={orderStaffToDelete}
            setOrderStaffToDelete={setOrderStaffToDelete}
            orderStaffs={orderStaffs}
            setOrderStaffs={onOrderStaffsChange as any}
          />
          <Box display="flex" justifyContent="center" sx={{ marginTop: 2 }}>
            <Button
              variant="contained"
              color="error"
              onClick={handleUnavailableClick}
              disabled={!selectedDate || highlightedDays.some(day => day.isSame(selectedDate, "day"))}
              sx={{ marginRight: 1 }}
            >
              Unavailable
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={handleAvailableClick}
              disabled={!selectedDate || !highlightedDays.some(day => day.isSame(selectedDate, "day"))}
            >
              Available
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default ManagementCalendar; 