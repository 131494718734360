import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { jwtDecode } from "jwt-decode";
import { config } from '../../components/api';

interface DecodedJwt {
  role: string;
  user_id: string;
  exp: number;
}

interface AuthState {
  token: string | null;
  role: string | null;
  isLoggedIn: boolean;
}

const initialState: AuthState = {
  token: null,
  role: null,
  isLoggedIn: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<string>) => {
      const accessToken = action.payload;
      const decoded_jwt = jwtDecode<DecodedJwt>(accessToken);
      
      state.token = accessToken;
      state.role = decoded_jwt.role;
      state.isLoggedIn = true;
      
      window.localStorage.setItem("access_token", accessToken);
      config.accessToken = accessToken;
      config.role = decoded_jwt.role;
    },
    logout: (state) => {
      state.token = null;
      state.role = null;
      state.isLoggedIn = false;
      
      window.localStorage.removeItem("access_token");
      config.accessToken = undefined;
      config.role = undefined;
    },
    initializeAuth: (state) => {
      const access_token = window.localStorage.getItem("access_token");
      if (access_token) {
        const decoded_jwt = jwtDecode<DecodedJwt>(access_token);
        state.token = access_token;
        state.role = decoded_jwt.role;
        state.isLoggedIn = true;
        config.accessToken = access_token;
        config.role = decoded_jwt.role;
      }
    }
  },
});

export const { login, logout, initializeAuth } = authSlice.actions;
export default authSlice.reducer;