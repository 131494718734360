import * as React from "react";
import { useAppSelector } from "../../hooks/redux";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import DescriptionIcon from "@mui/icons-material/Description";
import QuizIcon from "@mui/icons-material/Quiz";
import SidebarItem from "./SideBarItem/SideBarItem";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Collapse from "@mui/material/Collapse";

interface SideBarProps {
  drawerWidth: number;
  open?: boolean;
  toggleDrawer?: () => void;
}

interface DrawerProps {
  drawerWidth: number;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})<DrawerProps>(({ theme, open, drawerWidth }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Sidebar = ({ open, drawerWidth, toggleDrawer }: SideBarProps) => {
  const { role, isLoggedIn } = useAppSelector((state) => state.auth);
  const [openMenu, setOpenMenu] = React.useState(false);

  const handleMenuClick = () => {
    setOpenMenu((prev) => !prev);
  };

  const renderRoleSpecificItems = () => {
    switch (role) {
      case "admin":
        return (
          <>
            <SidebarItem
              to="/admin-management"
              icon={<AdminPanelSettingsRoundedIcon />}
              primary="Admin Management"
            />
            <SidebarItem
              to="/staff-management"
              icon={<Diversity3Icon />}
              primary="Staff Management"
            />
            <SidebarItem
              to="/question"
              icon={<QuizIcon />}
              primary="Questions"
            />
            <SidebarItem
              to="/notification_rules"
              icon={<EditNotificationsIcon />}
              primary="Notification Rules"
            />
            <SidebarItem
              to="/status_categories"
              icon={<BorderAllIcon />}
              primary="Status Categories"
            />
            <SidebarItem
              to="/requests"
              icon={<AssignmentLateIcon />}
              primary="Requests"
            />
            <SidebarItem
              to="/change-password"
              icon={<EditIcon />}
              primary="Change Password"
            />
          </>
        );
      case "manager":
        return (
          <>
            <SidebarItem
              to="/staff-management"
              icon={<Diversity3Icon />}
              primary="Staff Management"
            />
            <SidebarItem
              to="/question"
              icon={<QuizIcon />}
              primary="Questions"
            />
            <SidebarItem
              to="/notification_rules"
              icon={<EditNotificationsIcon />}
              primary="Notification Rules"
            />
            <SidebarItem
              to="/status_categories"
              icon={<BorderAllIcon />}
              primary="Status Categories"
            />
            <SidebarItem
              to="/requests"
              icon={<AssignmentLateIcon />}
              primary="Requests"
            />
            <SidebarItem
              to="/change-password"
              icon={<EditIcon />}
              primary="Change Password"
            />
          </>
        );
      case "staff":
        return (
          <>
            <SidebarItem
              to="/change-password"
              icon={<EditIcon />}
              primary="Change Password"
            />
          </>
        );
      case "client":
        return (
          <>
            <SidebarItem
              to="/requests"
              icon={<AssignmentLateIcon />}
              primary="Requests"
            />
            <SidebarItem
              to="/change-password"
              icon={<EditIcon />}
              primary="Change Password"
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      drawerWidth={drawerWidth}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List>
        {isLoggedIn && (
          <SidebarItem to="/" icon={<DashboardIcon />} primary="Dashboard" />
        )}

        {isLoggedIn && (
          <>
            <SidebarItem
              to="/order"
              icon={<DescriptionIcon />}
              primary="Order"
            />
            {(role === "admin" || role === "manager") && (
              <SidebarItem
                to="/client-management"
                icon={<PeopleIcon />}
                primary="Client Management"
              />
            )}
          </>
        )}

        {isLoggedIn && (
          <>
            <IconButton onClick={handleMenuClick}>
              {openMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Collapse in={openMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderRoleSpecificItems()}
              </List>
            </Collapse>
          </>
        )}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <List>
        {isLoggedIn ? (
          <SidebarItem to="/login" icon={<LogoutIcon />} primary="Logout" />
        ) : (
          <SidebarItem to="/login" icon={<LoginIcon />} primary="Login" />
        )}
      </List>
    </Drawer>
  );
};

export default Sidebar;
