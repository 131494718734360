import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useAppSelector, useAppDispatch } from '../../../hooks/redux';
import { logout } from '../../../store/slices/authSlice';

interface NavItemProps {
  to: string;
  icon: React.ReactNode;
  primary: string;
  onClick?: () => void;
}

const SidebarItem: React.FC<NavItemProps> = ({ to, icon, primary }) => {
  const { isLoggedIn } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (to === "/login" && isLoggedIn) {
      dispatch(logout());
    }
  };

  return (
    <ListItem button component={RouterLink} to={to} onClick={handleClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  );
};

export default SidebarItem;
