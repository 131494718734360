import * as React from "react";
import BaseFormDialog from "./BaseFormDialog";
import FormField from "./FormField";
import { NotificationRuleCreate, UserRole, UserProfession } from "../../api/api";
import api from "../api";

interface AddNotificationRuleFormDialogProps {
  open: boolean;
  handleClose: () => void;
  setNotificationRules: React.Dispatch<React.SetStateAction<any[]>>;
  refreshData: () => void;
}

const AddNotificationRuleFormDialog: React.FC<AddNotificationRuleFormDialogProps> = ({
  open,
  handleClose,
  setNotificationRules,
  refreshData,
}) => {
  const [formData, setFormData] = React.useState<NotificationRuleCreate>({
    user_role: "admin",
    user_professions: [],
    status_id: "",
    inform_day: 0,
  });
  const [statuses, setStatuses] = React.useState<Array<{ value: string; label: string }>>([]);

  React.useEffect(() => {
    api.status.getStatusStatusGet().then((res) => {
      setStatuses(res.data.contents.map(status => ({
        value: status.status_id,
        label: status.status
      })));
    });
  }, []);

  const handleSubmit = async () => {
    const response = await api.notificationRules.postNotificationRulesNotificationRulesPost(formData);
    setNotificationRules(prev => [...prev, response.data]);
    refreshData();
  };

  return (
    <BaseFormDialog
      open={open}
      title="Add Notification Rule"
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitButtonText="Add"
    >
      <FormField
        type="select"
        name="user_role"
        label="User Role"
        value={formData.user_role}
        onChange={(value) => setFormData({ ...formData, user_role: value })}
        options={Object.values(UserRole).map(role => ({ value: role, label: role }))}
        required
      />
      <FormField
        type="select"
        name="user_professions"
        label="User Professions"
        value={formData.user_professions}
        onChange={(value) => setFormData({ ...formData, user_professions: value })}
        options={Object.values(UserProfession).map(prof => ({ value: prof, label: prof }))}
        multiple
      />
      <FormField
        type="select"
        name="status"
        label="Status"
        value={formData.status_id}
        onChange={(value) => setFormData({ ...formData, status_id: value })}
        options={statuses}
        required
      />
      <FormField
        type="number"
        name="inform_day"
        label="Inform Day (Days Before)"
        value={formData.inform_day}
        onChange={(value) => setFormData({ ...formData, inform_day: Number(value) })}
        required
      />
    </BaseFormDialog>
  );
};

export default AddNotificationRuleFormDialog;
