import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import api, { config } from "../components/api";
import { NoteRequest, OrderOrderItem } from "../api";
import ApproveIcon from "@mui/icons-material/CheckCircle";
import RejectIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation, useSearchParams } from "react-router-dom";

interface ExtendedNoteRequest extends NoteRequest {
  inserted_at_local: string;
  requester_name: string;
  order_type: string;
}

interface ExtendedOrderOrderItem extends OrderOrderItem {
  order_type: string;
  order_date: string;
  customer_names: string;
}

const noteColumnConfig = [
  { id: "inserted_at_local", label: "Request Time", minWidth: 170 },
  { id: "requester_name", label: "Requester", minWidth: 100 },
  { id: "order_type", label: "Order Type", minWidth: 100 },
  { id: "previous_note", label: "Previous Note", minWidth: 170 },
  { id: "note", label: "Current Note", minWidth: 170 },
  { id: "actions", label: "Actions", minWidth: 100 },
];

const itemColumnConfig = [
  { id: "order_type", label: "Order Type", minWidth: 100 },
  { id: "order_date", label: "Order Date", minWidth: 100 },
  { id: "customer_names", label: "Customer(s)", minWidth: 170 },
  { id: "description", label: "Description", minWidth: 170 },
  { id: "price", label: "Price", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "actions", label: "Actions", minWidth: 100 },
];

const RequestsPage: React.FC = () => {
  const [noteRequests, setNoteRequests] = useState<ExtendedNoteRequest[]>([]);
  const [itemRequests, setItemRequests] = useState<ExtendedOrderOrderItem[]>(
    [],
  );

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const orderOrderItemId = searchParams.get("order_order_item_id");

  const [tabValue, setTabValue] = useState<string>(
    orderOrderItemId ? "item" : config.role === "client" ? "item" : "note",
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const fetchNoteRequests = async () => {
      const response = await api.requests.getNoteRequests();
      const requests = response.data.contents;

      const extendedRequests = await Promise.all(
        requests.map(async (request: NoteRequest) => {
          const userResponse = await api.users.getUserUserIdUserUserIdGet(
            request.created_by,
          );
          const orderResponse = await api.orders.getOrderOrdersOrderIdGet(
            request.order_id,
          );

          return {
            ...request,
            inserted_at_local: moment
              .utc(request.inserted_at)
              .local()
              .format("DD/MM/YYYY HH:mm:ss"),
            requester_name: `${userResponse.data.first_name} ${userResponse.data.last_name}`,
            order_type: orderResponse.data.type || "Unknown",
          };
        }),
      );

      setNoteRequests(extendedRequests);
    };

    const fetchItemRequests = async () => {
      if (config.role === "client") {
        const userResponse = await api.users.getUserUserGet();
        const userId = userResponse.data.user_id;

        if (userId) {
          const ordersResponse =
            await api.orders.getUserUserIdOrdersUsersUserIdOrdersGet(userId);
          const orders = ordersResponse.data.contents;

          const pendingItems: ExtendedOrderOrderItem[] = [];
          for (const order of orders) {
            const itemsResponse =
              await api.orderOrderItems.getOrdersOrderIdOrderOrderItemsOrdersOrderIdOrderOrderItemsGet(
                order.order_id,
              );
            const items = itemsResponse.data.contents.filter(
              (item: OrderOrderItem) => item.item_status === "pending",
            );

            const extendedItems = items.map((item: OrderOrderItem) => ({
              ...item,
              order_type: order.type || "Unknown",
              order_date: moment.utc(order.date).local().format("YYYY/MM/DD"),
              customer_names: "",
            }));

            pendingItems.push(...extendedItems);
          }

          for (const item of pendingItems) {
            const orderResponse = await api.orders.getOrderOrdersOrderIdGet(
              item.order_id,
            );
            if (Array.isArray(orderResponse.data.users)) {
              item.customer_names =
                orderResponse.data.users.length === 1
                  ? `${orderResponse.data.users[0].first_name} ${orderResponse.data.users[0].last_name}`
                  : orderResponse.data.users
                      .map((user) => user.first_name)
                      .join(" & ");
            } else {
              item.customer_names = "Unknown";
            }
          }

          const filteredItems = orderOrderItemId
            ? pendingItems.filter(
                (item) => item.order_order_item_id === orderOrderItemId,
              )
            : pendingItems;

          setItemRequests(filteredItems);
        }
      } else {
        const response =
          await api.orderOrderItems.getOrderOrderItemsOrderOrderItemsGet();
        const requests = response.data.contents.filter(
          (item: OrderOrderItem) => item.item_status === "pending",
        );
        const extendedRequests = await Promise.all(
          requests.map(async (item: OrderOrderItem) => {
            const orderResponse = await api.orders.getOrderOrdersOrderIdGet(
              item.order_id,
            );
            let customer_names = "Unknown";
            if (Array.isArray(orderResponse.data.users)) {
              customer_names =
                orderResponse.data.users.length === 1
                  ? `${orderResponse.data.users[0].first_name} ${orderResponse.data.users[0].last_name}`
                  : orderResponse.data.users
                      .map((user) => user.first_name)
                      .join(" & ");
            }
            return {
              ...item,
              order_type: orderResponse.data.type || "Unknown",
              order_date: moment
                .utc(orderResponse.data.date)
                .local()
                .format("YYYY/MM/DD"),
              customer_names,
            };
          }),
        );
        const filteredItems = orderOrderItemId
          ? extendedRequests.filter(
              (item) => item.order_order_item_id === orderOrderItemId,
            )
          : extendedRequests;

        setItemRequests(filteredItems);
      }
    };

    if (tabValue === "note") {
      fetchNoteRequests();
    } else if (tabValue === "item") {
      fetchItemRequests();
    }
  }, [tabValue, orderOrderItemId]);

  const handleNoteAction = async (
    requestId: string,
    action: "approve" | "reject",
  ) => {
    try {
      await api.requests.approveOrRejectNoteRequest(requestId, action);
      setNoteRequests((prev) =>
        prev.filter((req) => req.note_request_id !== requestId),
      );
    } catch (error) {
      console.error(`Error ${action}ing note request:`, error);
    }
  };

  const handleItemAction = async (
    itemId: string,
    action: "approve" | "reject",
  ) => {
    try {
      await api.orderOrderItems.approveOrRejectItemRequest(itemId, action);
      setItemRequests((prev) =>
        prev.filter((item) => item.order_order_item_id !== itemId),
      );
    } catch (error) {
      console.error(`Error ${action}ing item request:`, error);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "1rem" }}
      >
        <Typography variant="h5">Requests</Typography>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="status categories tabs"
        >
          {(config.role === "admin" || config.role === "manager") && (
            <Tab value="note" label="Note" />
          )}
          <Tab value="item" label="Item" />
        </Tabs>
      </Box>

      {tabValue === "note" && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {noteColumnConfig.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {noteRequests.length > 0 ? (
                noteRequests.map((request) => (
                  <TableRow key={request.note_request_id}>
                    <TableCell>{request.inserted_at_local}</TableCell>
                    <TableCell>{request.requester_name}</TableCell>
                    <TableCell>{request.order_type}</TableCell>
                    <TableCell>{request.previous_note}</TableCell>
                    <TableCell>{request.note}</TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleNoteAction(request.note_request_id, "approve")
                        }
                      >
                        <ApproveIcon />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleNoteAction(request.note_request_id, "reject")
                        }
                      >
                        <RejectIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No note requests available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {tabValue === "item" && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {itemColumnConfig.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {itemRequests.length > 0 ? (
                itemRequests.map((item) => (
                  <TableRow key={item.order_order_item_id}>
                    <TableCell>{item.order_type}</TableCell>
                    <TableCell>{item.order_date}</TableCell>
                    <TableCell>{item.customer_names}</TableCell>
                    <TableCell>{item.order_item.description}</TableCell>
                    <TableCell>{item.order_item.price}</TableCell>
                    <TableCell>{item.item_status}</TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleItemAction(item.order_order_item_id, "approve")
                        }
                      >
                        <ApproveIcon />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleItemAction(item.order_order_item_id, "reject")
                        }
                      >
                        <RejectIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No item requests available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default RequestsPage;
