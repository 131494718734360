import * as React from "react";
import BaseFormDialog from "./BaseFormDialog";
import FormField from "./FormField";
import { QuestionPatch } from "../../api/api";
import api from "../api";

interface EditQuestionFormDialogProps {
  open: boolean;
  handleClose: () => void;
  row: any;
  onSave: (question: any) => void;
}

const EditQuestionFormDialog: React.FC<EditQuestionFormDialogProps> = ({
  open,
  handleClose,
  row,
  onSave,
}) => {
  const [types, setTypes] = React.useState<string[]>([]);
  const [questionPatch, setQuestionPatch] = React.useState<QuestionPatch>({
    text: row.text,
    type: row.type,
    rank: row.rank,
  });

  React.useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await api.questions.getQuestionTypesQuestionsTypesGet();
        setTypes(response.data);
      } catch (error) {
        console.error("Error fetching question types:", error);
      }
    };
    fetchTypes();
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await api.questions.patchQuestionQuestionsQuestionIdPatch(
        row.question_id,
        questionPatch,
      );
      onSave(response.data);
      handleClose();
    } catch (error) {
      console.error("Error submitting question:", error);
    }
  };

  return (
    <BaseFormDialog
      open={open}
      title="Edit Question"
      description="Please enter the necessary information to edit"
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitButtonText="Edit"
    >
      <FormField
        type="text"
        name="text"
        label="Text"
        value={questionPatch.text}
        onChange={(value) => setQuestionPatch(prev => ({ ...prev, text: value }))}
        required
      />

      <FormField
        type="autocomplete"
        name="type"
        label="Type"
        value={questionPatch.type}
        onChange={(value) => setQuestionPatch(prev => ({ ...prev, type: value }))}
        options={types.map(type => ({ value: type, label: type }))}
        required
      />

      <FormField
        type="number"
        name="rank"
        label="Rank"
        value={questionPatch.rank}
        onChange={(value) => setQuestionPatch(prev => ({ ...prev, rank: Number(value) }))}
        required
      />
    </BaseFormDialog>
  );
};

export default EditQuestionFormDialog;
